import React, { useState } from "react";
import { connect } from "react-redux";
import "./MenuBar.css";
import MenuBarItem from "./MenubarItem";
import RSideBar from "../../components/RSideBar/RSideBar";
import Dropdown from "../../components/DropDown/Dropdown";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";

const MenuBar = ({ allAnnotations, annotationPlugin }) => {
  const [collapse, setCollapse] = useState(false);

  const [options, setOptions] = useState({
    stampOption: ["OME", "1AR"],
    statusOption: ["Solved", "Closed", "InProgress", "Open"],
    typeOption: ["Standard Issue", "Other Issue"],
    assigneeOption: [
      { name: "Alexander Salna", company: "Northrop Consulting Engineers" },
      { name: "Adrian Wilkins", company: "Dina Group" },
    ],
  });
  const [stamp, setStamp] = useState({
    value: options.stampOption[0],
    toggle: false,
  });
  const [status, setStatus] = useState({
    value: options.statusOption[0],
    toggle: false,
  });
  const [type, setType] = useState({
    value: options.typeOption[0],
    toggle: false,
  });

  const menubarList = [
    "Annotation Tracker",
    "Annotation Setting",
    "Selection Sets",
    "Appearance Templates",
    "Export Data",
    "Clash Tests",
    "Properties",
    "Object Tree",
    "Storey View",
    "Saved Views",
  ];

  const handleClick = () => {
    collapse ? setCollapse(false) : setCollapse(true);
  };

  const handleAnnotationToggle = (kind) => {
    switch (kind) {
      case "stamp":
        const stampAnnotations = allAnnotations.filter(
          (item) => item.stamp === stamp.value
        );
        stampAnnotations?.map((annotation) => {
          annotationPlugin.annotations[annotation.id].setMarkerShown(
            stamp.toggle
          );
        });
        setStamp({ ...stamp, toggle: !stamp.toggle });
        break;
      case "status":
        const statusAnnotations = allAnnotations.filter(
          (item) => item.status === status.value
        );
        statusAnnotations?.map((annotation) => {
          annotationPlugin.annotations[annotation.id].setMarkerShown(
            status.toggle
          );
        });
        setStatus({ ...status, toggle: !status.toggle });
        break;
      case "type":
        console.log("heree???");
        const typeAnnotations = allAnnotations.filter(
          (item) => item.type === type.value
        );
        typeAnnotations?.map((annotation) => {
          annotationPlugin.annotations[annotation.id].setMarkerShown(
            type.toggle
          );
        });
        setType({ ...type, toggle: !type.toggle });
        break;
      default:
        break;
    }
  };

  const [isMainMenubarOpen, setIsMainMenubarOpen] = useState(false);
  return (
    <>
      {" "}
      <div className="menubar">
        <div className="menubar-btn-container">
          <div className="menubar-btn">
            <div className="menubar-btn-line"></div>
            <div className="menubar-btn-line"></div>
            <div className="menubar-btn-line"></div>
          </div>
          <div
            className="leftPush-btn"
            onClick={() => setIsMainMenubarOpen(true)}
          >
            <div className="dropdown-icon"></div>
            <div className="dropdown-icon"></div>
          </div>
        </div>
      </div>
      <RSideBar
        isToggle={isMainMenubarOpen}
        handleToggle={() => setIsMainMenubarOpen(false)}
        header={"Main Menu"}
      >
        <div>
          {menubarList.map((element, i) => (
            <MenuBarItem listTitle={element} key={i} />
          ))}
        </div>
        <div className="main-menu-annotation">
          <div>Annotation Display Setting</div>
          <div className="main-menu-annotation-stamp">
            <div className="main-menu-annotation-label">
              <span>Stamp</span>
            </div>
            <div className="main-menu-annotation-dropdown">
              <Dropdown
                // value={issueModal.stamp}
                optionArray={options.stampOption}
                onChange={(event) =>
                  setStamp({ ...stamp, value: event.target.value })
                }
                onClick={() => {}}
              />
            </div>
            <div className="main-menu-annotation-toggle">
              <ToggleSwitch
                label={"stamp"}
                switchStatus={stamp.toggle}
                onChange={() => handleAnnotationToggle("stamp")}
              />
            </div>
          </div>

          <div className="main-menu-annotation-stamp">
            <div className="main-menu-annotation-label">
              <span>Status</span>
            </div>
            <div className="main-menu-annotation-dropdown">
              <Dropdown
                // value={issueModal.stamp}
                optionArray={options.statusOption}
                onChange={(event) =>
                  setStatus({ ...status, value: event.target.value })
                }
                onClick={() => {}}
              />
            </div>
            <div className="main-menu-annotation-toggle">
              <ToggleSwitch
                label={"status"}
                switchStatus={status.toggle}
                onChange={() => handleAnnotationToggle("status")}
              />
            </div>
          </div>
          <div className="main-menu-annotation-stamp">
            <div className="main-menu-annotation-label">
              <span>Type</span>
            </div>
            <div className="main-menu-annotation-dropdown">
              <Dropdown
                // value={issueModal.stamp}
                optionArray={options.typeOption}
                onChange={(event) =>
                  setType({ ...type, value: event.target.value })
                }
                onClick={() => {}}
              />
            </div>
            <div className="main-menu-annotation-toggle">
              <ToggleSwitch
                label={"type"}
                switchStatus={type.toggle}
                onChange={() => handleAnnotationToggle("type")}
              />
            </div>
          </div>
        </div>
      </RSideBar>
      {/* <div className="main-menubar">
      <div className="menubar-btn" onClick={() => handleClick()}>
        <div
          className={
            collapse ? "menubar-btn-vertical" : "menubar-btn-horizonal"
          }
        >
          <div className="menubar-btn-line"></div>
          <div className="menubar-btn-line"></div>
          <div className="menubar-btn-line"></div>
        </div>
      </div>
      <div
        className={
          collapse ? "menubar-dropdown-show" : "menubar-dropdown-hidden"
        }
      >
        {menubarList.map((element, i) => (
          <MenuBarItem listTitle={element} key={i} />
        ))}
      </div>
    </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allAnnotations: state.AnnotationReducer.allAnnotations,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
