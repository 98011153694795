import { combineReducers } from "redux";

import { AuthReducer } from "./AuthReducer";
import { ProjectReducer } from "./ProjectReducer";
import { xeokitReducer } from "./xeokitReducer";
import { AnnotationReducer } from "./AnnotationReducer";
import { AppearenceProfileReducer } from "./AppearenceProfileReducer";
import { AppearenceTemplateReducer } from "./AppearenceTemplateReducer";
import { ClashDetectionReducer } from "./ClashDetectionReducer";
import { PropertyReducer } from "./PropertyReducer";
import { SearchSetsReducer } from "./SearchSetsReducer";
import { EditSearchReducer } from "./EditSearchReducer";
import { SearchReducer } from "./SearchReducer";
import { ActionBufferReducer } from "./ActionBufferReducer";
import { IssueTrackReducer } from "./IssueTrackReducer";
import { TreeViewReducer } from "./TreeViewReducer";
import { ImagePlaneReducer } from "./ImagePlaneReducer";
import { SpinnerReducer } from "./SpinnerReducer";
import { ViewerReducer } from "./ViewerReducer";

const rootReducer = combineReducers({
  AuthReducer,
  ProjectReducer,
  AnnotationReducer,
  AppearenceProfileReducer,
  AppearenceTemplateReducer,
  ClashDetectionReducer,
  PropertyReducer,
  SearchSetsReducer,
  EditSearchReducer,
  SearchReducer,
  xeokitReducer,
  ActionBufferReducer,
  IssueTrackReducer,
  TreeViewReducer,
  ImagePlaneReducer,
  SpinnerReducer,
  ViewerReducer,
});

export default rootReducer;
