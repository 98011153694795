import React from "react";

import Edit from "../../asset/edit.svg";
import Add from "../../asset/add.svg";
import Search from "../../asset/search.svg";
import "./Button.css";

export const Button = ({ buttonName, onClick }) => {
  return (
    <button className="mycustom-button" onClick={onClick}>
      {buttonName}
    </button>
  );
};

export const MiniButton = ({ buttonName, onClick }) => {
  return (
    <button className="mini-button" onClick={onClick}>
      {buttonName}
    </button>
  );
};

export const IconButton = ({ buttonType, buttonName, onClick }) => {
  switch (buttonType) {
    case "Save":
      return (
        <button className="mycustom-button" onClick={onClick}>
          <img src={Edit} alt="edit" />
          {buttonName}
        </button>
      );
    case "Edit":
      return (
        <button className="mycustom-button" onClick={onClick}>
          <img src={Edit} alt="edit" />
          {buttonName}
        </button>
      );
    case "Add":
      return (
        <button className="mycustom-button" onClick={onClick}>
          <img src={Add} alt="add" />
          {buttonName}
        </button>
      );
    case "Search":
      return (
        <button className="mycustom-button" onClick={onClick}>
          <img src={Search} alt="search" />
          {buttonName}
        </button>
      );
    default:
      break;
  }
};

export const CheckBox = ({ onChange, checked }) => {
  return (
    <label className="custom-checkbox">
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className="checkmark"></span>
    </label>
  );
};

export const CustomDate = ({
  date,
  handleDateChange,
  style,
  disabled = false,
}) => {
  return (
    <input
      type="date"
      value={date}
      onChange={handleDateChange}
      required
      style={style}
      disabled={disabled}
    />
  );
};
