import React from "react";
import "./Dropdown.css";

const Dropdown = React.memo(
  ({ optionArray, onChange, onClick, value, defaultValue }) => {
    return (
      <>
        <select
          className="select"
          onChange={(e) => onChange(e)}
          onClick={(e) => onClick(e)}
          value={value}
        >
          {optionArray?.map((element, i) => (
            <option value={element} key={i}>
              {element}
            </option>
          ))}
        </select>
      </>
    );
  }
);

export default Dropdown;
