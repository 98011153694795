import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Dropdown from "../../components/DropDown/Dropdown";
import Modal from "../../components/Modal/Modal";
import CustomColorPicker from "../../components/ColorPicker/ColorPicker";
import LSideBar from "../../components/LSideBar/LSideBar";
import { CustomToast } from "../../utlity/toastify";
import { IconButton, Button, CheckBox } from "../../components/Button/Button";

import "./AppearenceProfile.css";

import {
  handleAppearanceProfileAction,
  saveAppearenceProfileListAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const AppearenceProfileTab = (props) => {
  const {
    projectId,
    projectName,
    token,
    isAppearanceProfileOpen,
    handleLoadingAction,
    selectedAppearenceTemplateList,
    saveAppearenceProfileListAction,
    handleAppearanceProfileAction,
  } = props;

  const [searchSetNames, setSearchSetNames] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [searchSetsList, setSearchSetsList] = useState([]);
  const settingArray = ["Transparency", "Color"];
  const [transModal, setTransModal] = useState({
    isOpen: false,
    transparency: null,
    inputError: null,
    selectedSearchSets: null,
  });

  const [colorModal, setColorModal] = useState({
    isOpen: false,
    hexColor: null,
    rgbColor: null,
    selectedSearchSets: null,
  });
  const [saveModal, setSaveModal] = useState({
    isOpen: false,
    inputError: "",
    saveName: "",
    height: "200px",
  });
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    inputError: "",
    saveName: "",
    height: "200px",
  });

  const [appearenceTemplate, setAppearenceTemplate] = useState();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${projectId}?page=${pg}&limit=${100}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("response", response);
      setSearchSetsList(
        response?.data.map((ele, i) => ({
          name: ele.name,
          property: ele.property,
          value: ele.value,
          color: ele.color,
          transparency: ele.transparency,
          hide: false,
        }))
      );
      setTotalPages(response?.data?.totalResults);
      const temp = response?.data?.map((ele) => ele.name) || [];
      setSearchSetNames(temp);
    }
    handleLoadingAction(false);

    // if (searchSetNames.length > 0) return;
    // else
    //   setSearchSetNames((prev) =>
    //     response?.data.map((ele, i) => [...prev, ele.name])
    //   );
  };

  useEffect(() => {
    if (!isAppearanceProfileOpen) return;

    getAllListing();
    if (selectedAppearenceTemplateList)
      setProfileList(selectedAppearenceTemplateList.template);
  }, [isAppearanceProfileOpen]);

  const hexToRgb = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse r, g, b values
    let r = parseInt(hex.slice(0, 2), 16) / 255;
    let g = parseInt(hex.slice(2, 4), 16) / 255;
    let b = parseInt(hex.slice(4, 6), 16) / 255;

    return [r, g, b];
  };

  //save the color value
  const onSaveColorChange = (color) => {
    setColorModal({
      ...colorModal,
      hexColor: color.hex,
      rgbColor: hexToRgb(color.hex),
    });
  };

  const onSaveColor = () => {
    setProfileList((prevList) =>
      prevList.map((item, i) =>
        i === colorModal.selectedSearchSets
          ? { ...item, color: colorModal.rgbColor, transparency: null }
          : item
      )
    );

    setColorModal({
      ...colorModal,
      isOpen: false,
    });
  };

  //save the transparency value
  const onSaveTransparency = () => {
    if (!transModal.transparency) {
      setTransModal({ ...transModal, inputError: "Please Enter value" });
      return;
    }
    setProfileList((prevList) =>
      prevList.map((item, i) =>
        i === transModal.selectedSearchSets
          ? {
              ...item,
              transparency: transModal.transparency / 100,
              color: null,
            }
          : item
      )
    );

    setTransModal({
      ...transModal,
      isOpen: false,
    });
  };

  //Add a Appearance Profile
  const onAddRule = () => {
    setProfileList([...profileList, {}]);
  };

  //handle searchSets in the list
  const onChangeSearchSets = (event, index) => {
    const selectedSearchSets = searchSetsList.find(
      (item) => item.name === event.target.value
    );

    if (selectedSearchSets)
      setProfileList((prevList) =>
        prevList.map((item, i) =>
          i === index ? { ...item, ...selectedSearchSets } : item
        )
      );
  };

  //handle profile in the list
  const onChangeProfiles = (event, index) => {
    console.log("onChange", event.target.value);
    switch (event.target.value) {
      case "Transparency":
        setTransModal({
          ...transModal,
          isOpen: true,
          selectedSearchSets: index,
        });
        break;
      case "Color":
        setColorModal({
          ...colorModal,
          isOpen: true,
          selectedSearchSets: index,
        });
        break;
      default:
        break;
    }
    // if (dropdownFlag) {

    //   setDropdownFlag(false);
    // } else setDropdownFlag(true);
  };

  //handle the visibility in the list
  const onChangeHide = (event, index) => {
    setProfileList((prevList) =>
      prevList.map((item, i) =>
        i === index ? { ...item, hide: !item.hide } : item
      )
    );
  };

  //save the Appearance Profile
  const onSaveAppearanceProfiles = async () => {
    if (!saveModal.saveName) {
      setSaveModal({ ...saveModal, inputError: "Please Input the Name" });
      return;
    }

    // const unhideProfileList = profileList.filter((item) => !item.hide);

    const url = BaseURL(`appearances`);
    const params = {
      name: saveModal.saveName,
      template: profileList,
      // template: unhideProfileList,
      projects: [`${projectName}`],
    };

    handleLoadingAction(true);

    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Appearance saved successfully", "affirmative");
    }

    handleLoadingAction(false);

    setSaveModal({
      ...saveModal,
      isOpen: false,
    });
  };

  const updateExisting = async () => {
    if (!selectedAppearenceTemplateList) return;
    const url = BaseURL(`appearances/${selectedAppearenceTemplateList.id}`);
    const params = {
      name: selectedAppearenceTemplateList.name,
      template: profileList,
      projects: selectedAppearenceTemplateList.projects,
    };

    handleLoadingAction(true);

    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      console.log("response", response);
      CustomToast("Appearance Updated successfully", "affirmative");
    }

    handleLoadingAction(false);
  };

  //Search the Appearance Profiles in the view
  const handleApply = () => {
    const unhideProfileList = profileList.filter((item) => !item.hide);
    saveAppearenceProfileListAction([...unhideProfileList]);
    console.log("fwef", profileList);
  };

  return (
    <>
      <LSideBar
        isToggle={isAppearanceProfileOpen}
        handleToggle={() => handleAppearanceProfileAction(false)}
        header={"Appearance Profile"}
      >
        <div className="appearance-profile-container">
          <ul className="appearance-profile-ul">
            {profileList.map((item, i) => (
              <li className="appearance-profile-li" key={i} value={item.name}>
                <div className="appearance-profile-checkbox">
                  <CheckBox
                    onChange={(event) => onChangeHide(event, i)}
                    checked={!item.hide}
                  />
                </div>
                <div className="appearance-profile-dropdown">
                  <Dropdown
                    optionArray={searchSetNames}
                    value={item.name}
                    onChange={(event) => onChangeSearchSets(event, i)}
                    onClick={() => {}}
                  />
                  <Dropdown
                    optionArray={settingArray}
                    value={
                      item.transparencey
                        ? settingArray[0]
                        : item.color
                        ? settingArray[1]
                        : settingArray[0]
                    }
                    onChange={(event) => onChangeProfiles(event, i)}
                    onClick={(event) => {}}
                  />
                </div>
                <div className="appearance-profile-preview">
                  {item.transparency ? (
                    <div>{item.transparency * 100}%</div>
                  ) : null}
                  {item.color ? (
                    <div
                      style={{
                        backgroundColor: `RGB(${item.color[0] * 255}, ${
                          item.color[1] * 255
                        }, ${item.color[2] * 255})`,
                        width: "20px",
                        height: "20px",
                        borderRadius: "15px",
                      }}
                    ></div>
                  ) : null}
                </div>
              </li>
            ))}
            <div className="appearance-profile-add-btn">
              <IconButton
                onClick={onAddRule}
                buttonType={"Add"}
                buttonName={"Add Rule"}
              />
            </div>
          </ul>
          <div className="appearance-profile-btn-container">
            <IconButton
              onClick={() => setSaveModal({ ...saveModal, isOpen: true })}
              buttonType={"Save"}
              buttonName={"Save as new"}
            />
            <IconButton
              onClick={() => updateExisting()}
              buttonType={"Add"}
              buttonName={"Update existing"}
            />
            <Button onClick={handleApply} buttonName={"Apply"} />
          </div>
        </div>
      </LSideBar>
      <CustomColorPicker
        isToggle={colorModal.isOpen}
        handleToggle={() => setColorModal({ ...colorModal, isOpen: false })}
        hexCode={colorModal.hexColor}
        onChange={onSaveColorChange}
        onSave={() => onSaveColor()}
      />
      <Modal
        isToggle={transModal.isOpen}
        handleToggle={() => setTransModal({ ...transModal, isOpen: false })}
        header={"Set Transparency"}
        height="230px"
      >
        <div className="transparency-btn-container">
          <div className="transparency-btn-input">
            <input
              className="mycustom-button"
              onChange={(e) => {
                setTransModal({
                  ...transModal,
                  transparency: e.target.value,
                  inputError: null,
                });
              }}
              type="number"
              min="0"
              max="100"
              placeholder="Please input between 0 and 100"
            />
            {transModal.inputError && (
              <p className="input-error">{transModal.inputError}</p>
            )}
          </div>
          <div className="transparency-btn-save">
            <IconButton
              buttonName={"Save"}
              buttonType={"Save"}
              onClick={() => onSaveTransparency()}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isToggle={saveModal.isOpen}
        handleToggle={() => setSaveModal({ ...saveModal, isOpen: false })}
        header={"Save Appearance Profiles"}
        height={saveModal.height}
      >
        <div className="ap-save-btn-container">
          <div className="ap-save-btn-input">
            <input
              className="mycustom-button"
              onChange={(e) => {
                setSaveModal({
                  ...saveModal,
                  saveName: e.target.value,
                  inputError: null,
                });
              }}
              placeholder="Appearance Template Name"
            />
            {saveModal.inputError && (
              <p className="input-error">{transModal.inputError}</p>
            )}
          </div>
          <div className="ap-save-btn-save">
            <IconButton
              buttonName={"Save"}
              buttonType={"Save"}
              onClick={() => onSaveAppearanceProfiles()}
            />
          </div>
        </div>
      </Modal>

      {/* <Modal isOpen={openSaveModal}>
        <div>
          <div className="modal-header">
            <label>Save Modal</label>
            <span className="modal-close" onClick={handleClose}>
              X
            </span>
          </div>
          <div className="modal-body">
            <div>
              <h5>AppearenceProfile Template Name</h5>
              <input
                onChange={(e) => onChangeAppearenceTemplate(e)}
                placeholder="Please AppearenceProfile Template Name"
              ></input>
              <button
                className="custom-button"
                type="button"
                onClick={saveAppearenceTemplate}
              >
                save
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAppearanceProfileOpen:
      state.AppearenceProfileReducer.isAppearanceProfileOpen,
    appearenceProfileList: state.AppearenceProfileReducer.appearenceProfileList,
    selectedAppearenceTemplateList:
      state.AppearenceTemplateReducer.selectedAppearenceTemplateList,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  handleAppearanceProfileAction,
  saveAppearenceProfileListAction,
  handleLoadingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppearenceProfileTab);
