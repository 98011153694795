import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Dropdown from "../../components/DropDown/Dropdown";
import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";

import "./SearchTab.css";

import {
  setSearchTabStatusAction,
  handleSearchAction,
  searchElementAction,
  saveSearchList,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import PNG_SAVE from "../../asset/save.png";

const SearchTab = (props) => {
  const [searchSetName, setSearchSetName] = useState();
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    props.handleSearchAction(true);
  };

  const [modelProperties, setModelProperties] = useState([]);
  const [modelProperty, setModelProperty] = useState(null);

  const [modelPropertyValues, setModelPropertyValues] = useState([]);
  const [modelPropertyValue, setModelPropertyValue] = useState();

  useEffect(() => {
    if (props.selectedData === null) return;

    setModelProperty(props.selectedData.selectedKey);
    setModelPropertyValue(props.selectedData.selectedValue);

    let tempkey = [];
    let tempValue = [];
    Object.entries(props.properties).forEach(([key, value]) => {
      tempkey.push(key);
      tempValue.push(value);
    });
    setModelProperties(tempkey);
    setModelPropertyValues(tempValue);
  }, [props]);

  const modelPropertyOnChange = (e) => {
    setModelProperty(e.target.value);
    Object.entries(props.properties).forEach(([key, value]) => {
      if (e.target.value === key) setModelPropertyValue(value);
    });
  };
  const modelPropertyValueOnChange = (e) => {
    setModelPropertyValue(e.target.value);
  };

  const searchElementAction = () => {
    console.log("modelProperty", modelProperty);
    console.log("modelPropertyValue", modelPropertyValue);
    const name = modelProperty;
    const value = modelPropertyValue;
    const color = "";
    const transparency = "";
    props.searchElementAction({ name, value, color, transparency });
  };

  const saveSearchSet = () => {
    const data = {
      searchSetName: searchSetName,
      modelProperty: modelProperty,
      modelPropertyValue: modelPropertyValue,
      modelColor: null,
      modelTransparency: null,
    };
    const temp = props.searchSaveList;

    temp.push(data);
    props.saveSearchList(temp);

    setOpen(false);
  };

  const onChangeInputValue = (e) => {
    setSearchSetName(e.target.value);
  };

  const closeTab = () => {
    props.setSearchTabStatusAction(false);
  };

  async function addListing() {
    const url = BaseURL(`searches`);
    const params = {
      name: searchSetName,
      property: modelProperty,
      value: modelPropertyValue,
      color: null,
      transparency: null,
      projects: [`${props.projectName}`],
    };

    setIsLoading(true);

    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      toast.success("SearchSets added successfully");
    }

    setIsLoading(false);
    props.setSearchSetDataFlag(true);
    setOpen(false);
  }

  return (
    <div className="search-tab">
      <Tab
        isOpen={props.setSearchTabStatus}
        onClose={closeTab}
        tabName={"Search Tab"}
      >
        <div className="searchtab-toolbar">
          <div>
            <img
              className="save-btn"
              src={PNG_SAVE}
              alt="Save"
              onClick={handleOpen}
            />
          </div>
          <Modal isOpen={open}>
            <div>
              <div className="modal-header">
                <label>New Search Set</label>
                <span className="modal-close" onClick={handleClose}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <div>
                  <h4>SearchSet Name</h4>
                </div>
                <div>
                  <input onChange={(e) => onChangeInputValue(e)} />
                </div>
                <div>
                  <button
                    className="custom-button"
                    type="button"
                    onClick={() => addListing()}
                  >
                    save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="searchtab-body">
          <label>ModelProperty</label>
          <Dropdown
            optionArray={modelProperties}
            onChangeOption={modelPropertyOnChange}
            defaultValue={modelProperty}
          />
          <label>ModelPropertyValue</label>
          <Dropdown
            optionArray={modelPropertyValues}
            onChangeOption={modelPropertyValueOnChange}
            defaultValue={modelPropertyValue}
          />
          <button
            className="custom-button"
            onClick={() => searchElementAction()}
          >
            Search
          </button>
        </div>
      </Tab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    setSearchTabStatus: state.SearchReducer.setSearchTabStatus,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    properties: state.PropertyReducer.properties,
    selectedData: state.PropertyReducer.selectedData,
    projectName: state.ProjectReducer.projectName,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setSearchTabStatusAction,
  handleSearchAction,
  searchElementAction,
  saveSearchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTab);
