import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, CustomDate } from "../../components/Button/Button";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { markupUrl, apiHeader } from "../../config/apiUrl";
import "./LogTab.css";

import {
  setIssueTrackStatusAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

const LogTab = ({
  logTabData,
  issueId,
  saveUpdatedIssue,
  activeTab,
  annotationId,
  handleLoadingAction,
  token,
}) => {
  const [commandData, setCommandData] = useState(null);
  const [logs, setLogs] = useState([]);

  const getAllSearches = async () => {
    if (activeTab !== 1 || annotationId === undefined) return;
    const url = BaseURL(`activityLogs/annotations/${annotationId}`);
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("issue-response", response);
      setLogs(response?.data);
    }
    handleLoadingAction(false);
  };

  useEffect(() => {
    console.log("active", activeTab);
    console.log("active", annotationId);
    if (activeTab !== 1 || annotationId === undefined) return;
    getAllSearches();
  }, [activeTab]);

  const handleCommandChange = (e) => setCommandData(e.target.value);

  const handleCommandSubmit = async () => {
    if (!commandData) return;
    const params = {
      comment: commandData,
    };
    const url = BaseURL(`activityLogs/comment/${annotationId}`);
    handleLoadingAction(true);
    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      console.log("issue-response", response);
      getAllSearches();
    }
    handleLoadingAction(false);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      style: stringToColor(name),
      name: `${name.split(" ")[0][0].toUpperCase()}${name
        .split(" ")[1][0]
        .toUpperCase()}`,
    };
  };

  const capitalizeName = (userName) => {
    return userName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const formatDate = (issueDate) => {
    const date = new Date(issueDate);

    // Get day, month, and year
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "short" }); // "Dec"
    const year = date.getUTCFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  return (
    <div className="log-tab">
      <div className="log-tab-logs-container">
        {/* {logTabData.map((element, i) => (
          <div key={i}>
            {Object.entries(element).map(([objectKey, objectValue]) => {
              return objectKey === "markUp" ? (
                <div className="log-tab-row" key={objectKey}>
                  <div className="log-tab-row-title">MarkUp</div>
                  <div>
                    <img
                      src={objectValue}
                      alt="Mark up"
                      width="100%"
                      height={250}
                      className="log-tab-markup"
                    />
                  </div>
                </div>
              ) : (
                <div className="log-tab-row" key={objectKey}>
                  {objectKey === "createdDate" ? (
                    <div className="log-tab-row-created">
                      updated at {objectValue}
                    </div>
                  ) : (
                    <>
                      <div className="log-tab-row-title">
                        {objectKey.toUpperCase()} was changed
                      </div>
                      <div className="log-tab-row-content">{objectValue}</div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ))} */}
        <div className="log-tab-logs">
          {logs.map((log, index) => {
            return (
              <div style={{ width: "100%" }} key={index}>
                <div className="log-tab-logs-date">
                  {formatDate(log.createdAt)}
                </div>
                <div className="log-tab-logs-user">
                  <div
                    className="log-tab-logs-avatar"
                    style={{
                      backgroundColor: stringAvatar(log.userName).style,
                    }}
                  >
                    {stringAvatar(log.userName).name}
                  </div>
                  <div className="log-tab-logs-name">
                    {capitalizeName(log.userName)}
                  </div>
                </div>
                {log.markup !== "" && (
                  <div
                    className="detail-tab-list"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="detail-tab-list-content">
                      <div className="detail-tab-list-markup">
                        <div style={{ width: "80%", height: "auto" }}>
                          <img
                            src={markupUrl(log.markup)}
                            width="80%"
                            height="80%"
                            alt="markup"
                            crossOrigin="anonymous"
                            className="detail-markup-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {log.comment !== "" && (
                  <div className="log-tab-logs-comment-container">
                    <div className="log-tab-logs-comment">
                      {log.comment}
                      <div className="chat-tail"></div>
                    </div>
                  </div>
                )}
                {log.changedData.map((item, index) => {
                  return (
                    <div className="log-tab-logs-content" key={index}>
                      <div>
                        changed: {item.field} {"-->"} {item.oldValue} {"-->"}{" "}
                        {item.newValue}{" "}
                      </div>
                    </div>
                  );
                })}
                {log.assignee?.length > 0 && (
                  <div className="log-tab-logs-content" key={index}>
                    <div>
                      added: assignee to {"-->"}
                      {log.assignee.map((item, index) => {
                        return (
                          <div key={index}>
                            {item.name}
                            {","}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="log-tab-comment-container">
        <div className="log-tab-comment-title">
          <div>Comments</div>
        </div>
        <div className="log-tab-comment-content">
          <textarea
            className="log-tab-comment-textarea"
            onChange={handleCommandChange}
          />
          <div>
            <Button
              buttonName={"Leave Comment"}
              onClick={handleCommandSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IssuesArray: state.IssueTrackReducer.IssuesArray,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogTab);
