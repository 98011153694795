import React from "react";

import "./LSideBar.css";

const LSideBar = ({
  isToggle,
  handleToggle,
  header,
  children,
  onContextMenu,
}) => {
  return (
    <div
      className={isToggle ? "l-sidebar toggle" : "l-sidebar"}
      onContextMenu={onContextMenu}
    >
      <div className="sidebar-header">
        <div className="sidebar-header-name">
          <span>{header}</span>
        </div>
        <div className="sidebar-header-cancel-btn" onClick={handleToggle}>
          X
        </div>
      </div>
      <div className="seperate-line"></div>
      <div className="sidebar-body">{children}</div>
    </div>
  );
};

export default LSideBar;
