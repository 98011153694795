import { Constants } from "../constant/constants";

export default function getModelPropertyAction(data) {
  return (dispatch) => {
    console.log("data", data);
    dispatch({ type: Constants.GET_MODEL_PROPERTIES, payload: data });
  };
}

export function handleLoadingAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.IS_LOADING, payload: data });
  };
}

export function handleViewerAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.HANDLE_VIEWER, payload: data });
  };
}

export function getModelEntityIdAction(data) {
  console.log(data);
  return (dispatch) => {
    dispatch({ type: Constants.GET_MODEL_ENTITY_ID, payload: data });
  };
}

export function getModelEntityNameAction(data) {
  console.log("getmodelname", data);
  return (dispatch) => {
    dispatch({ type: Constants.GET_MODEL_ENTITY_NAME, payload: data });
  };
}

export function getModelEntityTypeAction(data) {
  console.log(data);
  return (dispatch) => {
    dispatch({ type: Constants.GET_MODEL_ENTITY_TYPE, payload: data });
  };
}

export function saveViewerAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.SAVE_VIEWER, payload: data });
  };
}

export function handlePropertyAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.HANDLE_PROPERTY, payload: data });
  };
}

export function setSearchTabStatusAction(data) {
  console.log("setsearchstatus");
  return (dispatch) => {
    dispatch({ type: Constants.SET_SEARCHTAB_STATUS, payload: data });
  };
}

export function setEditSearchTabStatusAction(data) {
  console.log("setsearchstatus");
  return (dispatch) => {
    dispatch({ type: Constants.SET_EDIT_SEARCH_TAB_STATUS, payload: data });
  };
}

export function handleSearchAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.HANDLE_SEARCH, payload: data });
  };
}
export function setClashDetectionTabStatusAction(data) {
  console.log("action???");
  return (dispatch) => {
    dispatch({ type: Constants.SET_CLASHDETECTIONTAB_STATUS, payload: data });
  };
}

export function setClashTestTabStatusAction(data) {
  console.log("action???");
  return (dispatch) => {
    dispatch({ type: Constants.SET_CLASH_TEST_TAB_STATUS, payload: data });
  };
}

export function getViewerInputOnClickInstantAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.GET_VIEWER_INPUT_ONCLICK_INSTANT,
      payload: data,
    });
  };
}
export function setSectionCutBtnStatusAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.SET_SECTIONCUT_BTN_STATUS, payload: data });
  };
}
export function setMeasurementBtnStatusAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.SET_MEASUREMENT_BTN_STATUS, payload: data });
  };
}
export function seSelectEntityStatusAction(data) {
  return (dispatch) => {
    dispatch({ type: Constants.SET_SELECTENTITY_STATUS, payload: data });
  };
}
export function setResetSectionPlaneBtnStatusAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_RESET_SECTIONPLANE_BTN_STATUS,
      payload: data,
    });
  };
}
export function getMeasurementMouseControlInstantAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.GET_MEASUREMENT_MOUSE_CONTROL_INSTANT,
      payload: data,
    });
  };
}

export function searchElementAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SEARCH_ELEMENT,
      payload: data,
    });
  };
}

export function saveSearchList(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_SEARCH_LIST,
      payload: data,
    });
  };
}

export function detectCollisionAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.DETECT_COLLISION,
      payload: data,
    });
  };
}

export function clashDetectionListAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_CLASH_DETECTION_LIST,
      payload: data,
    });
  };
}

export function saveClashDetectionArrayAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_CLASHDETECTION_ARRAY,
      payload: data,
    });
  };
}

export function handleAppearanceProfileAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.HANDLE_APPEARANCEPROFILE,
      payload: data,
    });
  };
}
export function saveAppearenceProfileListAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_APPEARENCEPROFILE_LIST,
      payload: data,
    });
  };
}
export function setAnnotationTabStatusAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_ANNOTATIONTAB_STATUS,
      payload: data,
    });
  };
}
export function getAnnotationTabMouseInputAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.GET_ANNOTATIONTAB_MOUSE_INPUT,
      payload: data,
    });
  };
}

export function handleAppearanceTemplateAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.HANDLE_APPEARANCETEMPLATE,
      payload: data,
    });
  };
}

export function saveAppearenceTemplateListAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_APPEARENCETEMPLATE_LIST,
      payload: data,
    });
  };
}

export function searchAppearenceTemplateListAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SEARCH_APPEARENCETEMPLATE_LIST,
      payload: data,
    });
  };
}
export function addToClashDetectionListFromView(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.ADD_TO_CLASHDETECTION_LIST_FROM_VIEW,
      payload: data,
    });
  };
}
export function addToClashDetectionListNormalClick(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.ADD_TO_CLASHDETECTION_LIST_NORMAL_CLICK,
      payload: data,
    });
  };
}
export function saveDistanceMeasurementPluginAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_DISTANCEMEASUREMENT_PLUGIN,
      payload: data,
    });
  };
}
export function saveDistanceMeasurementArrayAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_DISTANCEMEASUREMENT_ARRAY,
      payload: data,
    });
  };
}
export function saveSectionPlanePluginAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_SECTIONPLANE_PLUGIN,
      payload: data,
    });
  };
}
export function saveCurrentViewerAction(data) {
  return (dispatch) => {
    console.log("searchaction", data);
    dispatch({
      type: Constants.SAVE_CURRENT_VIEWER,
      payload: data,
    });
  };
}

export function setThumbnailOpacityAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_THUMBNAIL_OPACITY,
      payload: data,
    });
  };
}

export function saveActionBufferAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_ACTION_BUFFER,
      payload: data,
    });
  };
}

export function saveImageDataAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_SCREENSHOT_DATA,
      payload: data,
    });
  };
}

export function setIssueTrackStatusAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_ISSUE_TRACK_STATUS,
      payload: data,
    });
  };
}

export function saveIssueArrayAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_ISSUE_ARRAY,
      payload: data,
    });
  };
}

export function saveLogArrayAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_LOG_ARRAY,
      payload: data,
    });
  };
}

export function setUpdateScreenIssueIdAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_UPDATE_SCREEN_ISSUE_ID,
      payload: data,
    });
  };
}

export function setAnnotationOptionTabAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_ANNOTATION_OPTION_TAB,
      payload: data,
    });
  };
}

export function saveMetaData(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_METADATA,
      payload: data,
    });
  };
}

export function setMultiSelectEntityAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_MULTI_SELECT_ENTITY,
      payload: data,
    });
  };
}

export function setEditSearchDataAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_EDIT_SEARCH_DATA,
      payload: data,
    });
  };
}

export function setCommonPropertyDataAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_COMMON_PROPERTY_DATA,
      payload: data,
    });
  };
}

export function setTreeViewStatusAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_TREE_VIEW_STATUS,
      payload: data,
    });
  };
}

export function setImagePlaneStatusAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_IMAGEPLANE_TAB_STATUS,
      payload: data,
    });
  };
}

export function saveBCFViewPoint(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_BCFVIEWPOINT,
      payload: data,
    });
  };
}
export function saveAnnotationPlugin(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_ANNOTATIONPLUGIN,
      payload: data,
    });
  };
}
export function setMeasurementPluginAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_MEASUREMENTPLUGIN,
      payload: data,
    });
  };
}
export function saveSectionPlanePlugin(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_SECTIONPLANEPLUGIN,
      payload: data,
    });
  };
}

export function saveSectionPlanceCanvas(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_SECTIONPLANECANVAS,
      payload: data,
    });
  };
}

export function userLogin(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.USER_LOGIN,
      payload: data,
    });
  };
}

export function userLogout(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.USER_LOGIN,
      payload: data,
    });
  };
}

export function saveProject(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SAVE_PROJECT,
      payload: data,
    });
  };
}

export function handleIssueUpdate(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.HANDLE_ISSUE_UPDATE,
      payload: data,
    });
  };
}

export function handleMarkupUpdate(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.HANDLE_MARKUP_UPDATE,
      payload: data,
    });
  };
}

export function setMarkupAnnotationId(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_MARKUP_ANNOTATIONID,
      payload: data,
    });
  };
}

export function setSelectedEntities(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_SELECTED_ENTITIES,
      payload: data,
    });
  };
}

export function setSelectedEntitiesFalse(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_SELECTED_ENTITIES_FALSE,
      payload: data,
    });
  };
}

export function setDefaultViewAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_DEFAULTVIEW,
      payload: data,
    });
  };
}

export function setAnnotationsAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_ANNOTATIONS,
      payload: data,
    });
  };
}

export function setSelectedEntityIdAction(data) {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_SELECTED_ENTITY_ID,
      payload: data,
    });
  };
}
