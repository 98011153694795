import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Modal from "../../components/Modal/Modal";
import TabPanel from "./TabPanel";
import { CheckBox } from "../../components/Button/Button";

import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get } from "../../axios/axios";

import "./IssueTrack.css";

import {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
} from "../../action/xeokitAction";

const IssueTrack = ({
  isIssueTrackOpen,
  imageData,
  IssuesArray,
  logsArray,
  handleIssueUpdate,
  updateScreenIssueId,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  projectId,
  token,
}) => {
  const [detailTabData, setDetailTabData] = useState({});
  const [logTabData, setLogTabData] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllSearches = async (pg = page) => {
    if (!isIssueTrackOpen) return;
    const url = BaseURL(
      `annotations/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("issue-response", response);
      setIssueList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isIssueTrackOpen) return;
    getAllSearches();
  }, [isIssueTrackOpen]);
  useEffect(() => {
    if (!isIssueTrackOpen) return;
    getAllSearches();
  }, [handleIssueUpdate]);

  useEffect(() => {
    setLogTabData(logsArray[updateScreenIssueId] || []);
  }, [logsArray]);

  const closeModal = () => setIssueTrackStatusAction(false);

  const handleRowClick = (rowData, index) => {
    setDetailTabData(rowData);
    // setLogTabData(structuredClone(logsArray[index]));
  };

  const updateDetailTabData = (newData, type) => {
    if (type === "markUp")
      setDetailTabData({ ...detailTabData, markUp: newData });
    else setDetailTabData({ ...detailTabData, ...newData });
  };

  const saveUpdatedIssue = (objectId, objectKey, objectValue) => {
    const updatedIssues = structuredClone(IssuesArray);
    const issueIndex = updatedIssues.findIndex((item) => item.id === objectId);

    if (issueIndex !== -1) {
      updatedIssues[issueIndex][objectKey] = objectValue;
      setDetailTabData(updatedIssues[issueIndex]);

      const updatedLogs = structuredClone(logsArray);
      updatedLogs[issueIndex].push({
        [objectKey]: objectValue,
        createdDate: formatDate(),
      });

      setLogTabData(updatedLogs[issueIndex]);
      saveLogArrayAction(updatedLogs);
      saveIssueArrayAction(updatedIssues);
    }
  };

  // const formatDate = () => {
  //   const now = new Date();

  //   // Get the components of the date
  //   const day = String(now.getDate()).padStart(2, "0");
  //   const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const year = now.getFullYear();
  //   const hours = now.getHours();
  //   const minutes = String(now.getMinutes()).padStart(2, "0");

  //   // Determine AM or PM
  //   const ampm = hours >= 12 ? "pm" : "am";

  //   // Format the hours in 12-hour format
  //   const formattedHours = hours % 12 || 12;

  //   // Format the date and time as desired
  //   const formattedDate = `${day}/${month}/${year} at ${formattedHours}:${minutes} ${ampm}`;

  //   return formattedDate;
  // };

  const formatDate = (issueDate) => {
    const date = new Date(issueDate);

    // Get day, month, and year
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "short" }); // "Dec"
    const year = date.getUTCFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  return (
    isIssueTrackOpen && (
      <div className="issue-track-container">
        <div className="issue-track">
          <div className="issue-track-header">
            <div className="issue-track-header-name">
              <span>Issue Tracker</span>
            </div>
            <div className="issue-track-cancel-btn" onClick={closeModal}>
              X
            </div>
          </div>
          <div className="seperate-line"></div>
          <div className="issue-track-body">
            <div className="issue-track-list">
              <table className="custom-table issue-track-table-width">
                <thead>
                  <tr>
                    <th>
                      <CheckBox />
                    </th>
                    <th>ID</th>
                    <th>New</th>
                    <th>Issue Title</th>
                    <th>Stamp</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Assigned to</th>
                    <th>Due date</th>
                    <th>Start date</th>
                  </tr>
                </thead>
                <tbody>
                  {issueList.map((issue, i) => (
                    <tr
                      className="issue-track-table-row"
                      key={i}
                      onClick={() => handleRowClick(issue, i)}
                    >
                      <td className="table-col-checkbox">
                        <CheckBox />
                      </td>
                      <td className="table-col-id">{i + 1}</td>
                      <td className="table-col-new">
                        <div className={issue.new ? "new-issue" : null}></div>
                      </td>
                      <td className="table-col-title">{issue.title}</td>
                      <td className="table-col-stamp">
                        <div className={`issue-track-stamp-${issue.stamp}`}>
                          {issue.stamp}
                        </div>
                      </td>
                      <td className="table-col-status">
                        <div className="issue-track-status-container">
                          <div
                            className={`issue-track-status-${issue.status}`}
                          ></div>
                          <div className="issue-track-status-content">
                            {issue.status}
                          </div>
                        </div>
                      </td>
                      <td className="table-col-type">{issue.type}</td>
                      <td className="table-col-assignee">
                        {issue.assignee.length > 1
                          ? `${issue.assignee.length} people`
                          : issue.assignee[0].name}
                      </td>
                      <td className="table-col-duedate">{issue.dueDate}</td>
                      <td className="table-col-startdate">{issue.startDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="vertical-seperate-line"></div>
            <div className="issue-track-tab">
              <TabPanel
                imageData={imageData}
                detailTabData={detailTabData}
                saveUpdatedIssue={saveUpdatedIssue}
                logTabData={logTabData}
                updateDetailTabData={updateDetailTabData}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  isIssueTrackOpen: state.IssueTrackReducer.isIssueTrackOpen,
  imageData: state.AnnotationReducer.imageData,
  IssuesArray: state.IssueTrackReducer.IssuesArray,
  logsArray: state.IssueTrackReducer.logsArray,
  handleIssueUpdate: state.IssueTrackReducer.handleIssueUpdate,
  updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
  projectId: state.ProjectReducer.projectId,
  token: state.AuthReducer.token,
});

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueTrack);
