// Modal.js

import React from "react";

import "./Modal.css";

const Modal = ({
  isToggle,
  handleToggle,
  header,
  width = "400px",
  height = "300px",
  children,
}) => {
  return (
    isToggle && (
      <div className="modal-container">
        <div className="modal" style={{ width: width, height: height }}>
          <div className="modal-header">
            <div className="modal-header-name">
              <span>{header}</span>
            </div>
            <div className="modal-header-cancel-btn" onClick={handleToggle}>
              X
            </div>
          </div>
          <div className="modal-line"></div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    )
  );
};

export default Modal;
