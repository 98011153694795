import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  setEditSearchTabStatusAction,
  handlePropertyAction,
  handleSearchAction,
  setEditSearchDataAction,
  searchElementAction,
} from "../../action/xeokitAction";

import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get } from "../../axios/axios";

import RSideBar from "../../components/RSideBar/RSideBar";
import { CheckBox, IconButton } from "../../components/Button/Button";

import "./SearchSetsTab.css";

const SearchSetsTab = (props) => {
  const { isSearchOpen, projectId, token, handleSearchAction } = props;
  const [searchList, setSearchList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllSearches = async (pg = page) => {
    if (!isSearchOpen) return;
    const url = BaseURL(
      `searches/projects/${projectId}?page=${pg}&limit=${100}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      setSearchList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isSearchOpen) return;
    getAllSearches();
  }, [isSearchOpen]);

  const handleSelectedList = (list) => {
    const index = selectedList.findIndex(
      (item) => item?.name === list?.property && item?.value === list?.value
    );

    if (index !== -1) {
      // If the parameter exists, remove it
      setSelectedList(selectedList.filter((_, i) => i !== index));
    } else {
      // If the parameter does not exist, add it
      setSelectedList([
        ...selectedList,
        {
          name: list.property,
          value: list.value,
          color: "",
          transparency: "",
        },
      ]);
    }
  };

  const handleBar = () => {
    handleSearchAction(false);
  };

  const handleSearch = () => {
    console.log(selectedList);

    props.searchElementAction(selectedList);
  };

  return (
    <>
      <RSideBar
        isToggle={props.isSearchOpen}
        handleToggle={handleBar}
        header={"SearchSets Tab"}
      >
        <div className="search-button">
          <IconButton
            buttonName={"Run Selection"}
            buttonType={"Search"}
            onClick={() => handleSearch()}
          />
        </div>
        <ul className="search-ul">
          {!isLoading
            ? searchList.map((list, i) => (
                <li className="search-li" key={i} value={list.name}>
                  <div>{list.name}</div>
                  <CheckBox onChange={() => handleSelectedList(list)} />
                </li>
              ))
            : null}
        </ul>
      </RSideBar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isSearchOpen: state.SearchSetsReducer.isSearchOpen,
    setEditSearchTabStatus: state.EditSearchReducer.setEditSearchTabStatus,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setEditSearchTabStatusAction,
  handlePropertyAction,
  handleSearchAction,
  setEditSearchDataAction,
  searchElementAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSetsTab);
