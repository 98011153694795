import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { CustomToast } from "../../utlity/toastify";

//import Actions
import {
  searchElementAction,
  handleLoadingAction,
  handleViewerAction,
  setDefaultViewAction,
} from "../../action/xeokitAction";

//import api
import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";

//import custom components
import RSideBar from "../../components/RSideBar/RSideBar";
import Box from "../../components/Box/Box";
import Dropdown from "../../components/DropDown/Dropdown";
import { Button, IconButton } from "../../components/Button/Button";
import DefaultView from "../../asset/defaultView.svg";
import DefaultView_Variant from "../../asset/defaultView_variant.svg";
import ContextMenu from "../../components/ContextMenu/ContextMenu";

//import css file
import "./ViewerTab.css";

const ViewerTab = (props) => {
  const {
    viewer,
    isViewerOpen,
    projectId,
    projectName,
    token,
    handleLoadingAction,
    handleViewerAction,
    bcfViewPoint,
    annotationPlugin,
    distanceMeasurementPlugin,
    sectionPlanePlugin,
    setDefaultViewAction,
  } = props;

  const [viewerList, setViewerList] = useState([]);
  const [optionClickHandler, setOptionClickHandler] = useState({
    listItem: "",
    defaultView: "",
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();
  const boxRef = useRef(null); //box ref
  const [boxData, setBoxData] = useState({
    isOpen: false,
    top: 0,
    left: 0,
    rowData: null,
    width: 200,
    height: 100,
    offset: 40,
    zIndex: 21,
  });

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `viewers/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("response", response);
      setViewerList(response?.data);
      response.data.map((item) => {
        console.log("defaultView????????", item.defaultView);
        if (item.defaultView) {
          setOptionClickHandler({
            ...optionClickHandler,
            defaultView: item.id,
          });
          setDefaultViewAction(item.bcf);
        }
      });

      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  useEffect(() => {
    if (!isViewerOpen) return;
    console.log("Viewer", isViewerOpen);
    getAllListing();
  }, [isViewerOpen]);

  const loadViewer = (e, list) => {
    console.log("load", distanceMeasurementPlugin);
    console.log("load", viewer);
    // annotationPlugin.clear();
    // measurementPlugin.clear();
    setOptionClickHandler({
      ...optionClickHandler,
      listItem: list.name,
    });
    viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
    viewer.scene.setObjectsXRayed(viewer.scene.xrayedObjectIds, false);
    viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);
    viewer.scene.setObjectsColorized(viewer.scene.colorizedObjectIds, false);
    viewer.scene.setObjectsOpacity(viewer.scene.opacityObjectIds, true);
    viewer._plugins.forEach((plugin) => {
      if (plugin.id === "SectionPlanes") {
        plugin.clear();
      } else if (plugin.id === "Annotations") {
        // plugin.clear();
      } else if (plugin.id === "DistanceMeasurements") {
        // plugin.clear();
      }
    });

    const bcfViewPoints = JSON.parse(list.bcf.viewPoints);
    bcfViewPoint.setViewpoint(bcfViewPoints);

    console.log("VIeweer", bcfViewPoints);
    console.log("sectionPlanePlugin", sectionPlanePlugin);
    console.log("sectionPlanePlugin", sectionPlanePlugin);
    console.log("sectionPlanePlugin", list?.bcf?.annotations);

    // list?.bcf?.annotations?.map((annotation, i) => {
    //   annotationPlugin.createAnnotation({
    //     id: annotation[0]?.id,
    //     entity: viewer.scene.objects[annotation[0]?.entity],
    //     worldPos: [
    //       annotation[0]?._worldPos[0],
    //       annotation[0]?._worldPos[1],
    //       annotation[0]?._worldPos[2],
    //     ],
    //     occludable: annotation[0]?.occludable,
    //     markerShown: annotation[0]?.markerShown,
    //     labelShown: annotation[0]?.labelShown,

    //     values: annotation[0]?._values,
    //   });
    // });

    // list?.bcf?.measurements?.map((measurement, i) =>
    //   measurement.map((ele, j) =>
    //     distanceMeasurementPlugin.createMeasurement({
    //       id: ele?.id,
    //       origin: {
    //         entity: viewer.scene.objects[ele?.origin.entity],
    //         worldPos: [
    //           ele?.origin.worldPos[0],
    //           ele?.origin.worldPos[1],
    //           ele?.origin.worldPos[2],
    //         ],
    //       },
    //       target: {
    //         entity: viewer.scene.objects[ele?.target.entity],
    //         worldPos: [
    //           ele?.target.worldPos[0],
    //           ele?.target.worldPos[1],
    //           ele?.target.worldPos[2],
    //         ],
    //       },
    //     })
    //   )
    // );

    // let sectionPlane = [];
    // props.saveViewerArray.map((element, i) => {
    //   if (element.viewerName === e.target.textContent) {
    //     const bcfViewpoints = new BCFViewpointsPlugin(props.viewer);
    //     bcfViewpoints.setViewpoint(element.viewer);
    //     console.log(props.viewer);
    //     // element.viewer.restoreObjects(props.viewer.scene);
    //     // sectionPlane = element.sectionPlane;
    //   }
    // });

    // console.log("sectionPlanes", sectionPlane);

    // sectionPlane.map((element, i) => {
    //   Object.values(props.sectionPlanePlugin.sectionPlanes).map((item, i) => {
    //     console.log("item", item);
    //     if (element.id === item.id) {
    //       console.log("????", item);
    //       item.dir = element.dir;
    //       item.pos = element.pos;
    //     }
    //   });
    // });
  };

  //handle the mouse event. When users click outside box, close the box
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setBoxData({ ...boxData, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  //handle table row click
  const handleRowClick = (event, id, bcf) => {
    const rowRect = event.currentTarget.getBoundingClientRect();
    const modalHeight = boxRef.current
      ? boxRef.current.offsetHeight
      : boxData.height;
    const viewportHeight = window.innerHeight;

    const adjustedTop =
      rowRect.top - boxData.offset + modalHeight + 5 > viewportHeight
        ? rowRect.top - boxData.offset - modalHeight + 5
        : rowRect.top - boxData.offset;

    console.log("box", adjustedTop);
    setBoxData({
      ...boxData,
      isOpen: true,
      top: adjustedTop,
      right: 40,
      rowData: id,
      bcf: bcf,
    });
    document.addEventListener("mousedown", handleClickOutside);
  };

  const handleDefaultView = async (type) => {
    const url = BaseURL(`viewers/${boxData.rowData}`);
    const params = {
      defaultView: type === "default" ? true : false,
      project: projectName,
    };

    console.log("url", url);
    handleLoadingAction(true);
    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Updated Succeessfully!", "affirmative");

      if (type === "default") {
        setOptionClickHandler({
          ...optionClickHandler,
          defaultView: boxData.rowData,
        });
        setDefaultViewAction(boxData.bcf);
      } else {
        setOptionClickHandler({
          ...optionClickHandler,
          defaultView: null,
        });
        setDefaultViewAction({});
      }
    }
    handleLoadingAction(false);
  };

  return (
    <>
      <RSideBar
        isToggle={isViewerOpen}
        handleToggle={() => handleViewerAction(false)}
        header={"Save Points"}
      >
        <ul className="appearence-template-ul">
          {viewerList.map((item, i) => (
            <li
              className={
                optionClickHandler.listItem === item.name
                  ? "appearance-template-li clicked"
                  : "appearance-template-li"
              }
              key={i}
              onClick={(e) => loadViewer(e, item)}
            >
              <div className="appearence-template-name">{item.name}</div>
              <div
                onClick={(event) => handleRowClick(event, item.id, item.bcf)}
              >
                <img
                  src={
                    optionClickHandler.defaultView === item.id
                      ? DefaultView_Variant
                      : DefaultView
                  }
                  className="viewer-default"
                  alt="default view"
                  width={25}
                  height={25}
                />
              </div>
            </li>
          ))}
        </ul>
      </RSideBar>
      {
        <Box
          isToggle={boxData.isOpen}
          style={{
            width: boxData.width,
            height: boxData.height,
            position: "absolute",
            top: boxData.top,
            right: boxData.right,
            zIndex: boxData.zIndex,
            border: "1px solid #FF00BA",
            borderRadius: "5px",
          }}
          ref={boxRef}
        >
          <div className="box-property-container">
            <div onClick={() => handleDefaultView("default")}>
              Set As Default View
            </div>
            <div onClick={() => handleDefaultView("clear")}>
              Clear Default View
            </div>
          </div>
        </Box>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isViewerOpen: state.ViewerReducer.isViewerOpen,
    properties: state.PropertyReducer.properties,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
    bcfViewPoint: state.xeokitReducer.bcfViewPoint,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
    distanceMeasurementPlugin: state.xeokitReducer.distanceMeasurementPlugin,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    viewer: state.xeokitReducer.viewer,
  };
};

const mapDispatchToProps = {
  searchElementAction,
  handleLoadingAction,
  handleViewerAction,
  setDefaultViewAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerTab);
