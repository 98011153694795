import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { CustomToast } from "../../utlity/toastify";

//import Actions
import {
  handlePropertyAction,
  searchElementAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

//import api
import { apiHeader, BaseURL } from "../../config/apiUrl";
import { Post } from "../../axios/axios";

//import custom components
import LSideBar from "../../components/LSideBar/LSideBar";
import Box from "../../components/Box/Box";
import Dropdown from "../../components/DropDown/Dropdown";
import { Button, IconButton } from "../../components/Button/Button";

//import css file
import "./PropertyTab.css";

const PropertyTab = (props) => {
  const {
    isPropertyOpen,
    properties,
    token,
    handlePropertyAction,
    searchElementAction,
    handleLoadingAction,
  } = props;
  const [propertyNames, setPropertyNames] = useState([]);
  const [propertyValues, setPropertyValues] = useState([]);
  const [searchSetName, setSearchSetName] = useState("");
  const [selectedPropertyName, setSelectedPropertyName] = useState("");
  const [selectedPropertyValue, setSelectedPropertyValue] = useState("");
  const [inputError, setInputError] = useState("");
  const boxRef = useRef(null); //box ref
  const saveBoxRef = useRef(null); //savebox ref

  const [boxData, setBoxData] = useState({
    isOpen: false,
    top: 0,
    left: 0,
    rowData: null,
    width: 400,
    height: 250,
    offset: 40,
  });

  const [saveBoxData, setSaveBoxData] = useState({
    isOpen: false,
    top: 0,
    left: 0,
    width: 400,
    height: 152,
    offset: 250,
  });

  //set the property names and values
  useEffect(() => {
    if (!isPropertyOpen) return;

    const names = Object.keys(properties);
    const values = Object.values(properties);

    setPropertyNames(names);
    setPropertyValues(values);
  }, [isPropertyOpen]);

  //send request to save search sets
  const saveSearchSet = async () => {
    if (!searchSetName) {
      setInputError("Please Enter Name");
      return;
    }
    const url = BaseURL("searches");
    const params = {
      name: searchSetName,
      property: selectedPropertyName,
      value: selectedPropertyValue,
      color: null,
      transparency: null,
      projects: [`${props.projectName}`],
    };

    console.log("url", url);
    handleLoadingAction(true);
    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Saved Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
  };

  //handle property bar
  const handleBar = (event) => {
    event.preventDefault();
    handlePropertyAction();
  };

  //handle the mouse event. When users click outside box, close the box
  const handleClickOutside = (event) => {
    if (
      boxRef.current &&
      !boxRef.current.contains(event.target) &&
      !saveBoxRef.current
    ) {
      setBoxData({ ...boxData, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutside);
    }

    if (saveBoxRef.current && !saveBoxRef.current.contains(event.target)) {
      setSaveBoxData({ ...saveBoxData, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  //handle table row click
  const handleRowClick = (event, row) => {
    const rowRect = event.currentTarget.getBoundingClientRect();
    const modalHeight = boxRef.current
      ? boxRef.current.offsetHeight
      : boxData.height;
    const viewportHeight = window.innerHeight;

    const adjustedTop =
      rowRect.top - boxData.offset + modalHeight + 5 > viewportHeight
        ? rowRect.top - boxData.offset - modalHeight + 5
        : rowRect.top - boxData.offset;

    setSelectedPropertyName(row[0]);
    setSelectedPropertyValue(row[1]);
    setBoxData({
      ...boxData,
      isOpen: true,
      top: adjustedTop,
      left: 462,
      rowData: { name: row[0], value: row[1] },
    });
    document.addEventListener("mousedown", handleClickOutside);
  };

  const handleAdd = (event) => {
    const box = event.currentTarget.getBoundingClientRect();
    const saveBoxHeight = saveBoxRef.current
      ? saveBoxRef.current.offsetHeight
      : saveBoxData.height;
    const viewportHeight = window.innerHeight;

    const adjustedTop =
      box.bottom + saveBoxHeight > viewportHeight
        ? box.top - saveBoxHeight - saveBoxData.offset
        : box.bottom;

    setSaveBoxData({
      ...saveBoxData,
      isOpen: true,
      top: adjustedTop,
      left: 462,
    });
    document.addEventListener("mousedown", handleClickOutside);
  };

  //search elements in the viewer
  const handleSearch = (propertyName, propertyValue) => {
    searchElementAction({
      name: propertyName,
      value: propertyValue,
      color: "",
      transparency: "",
    });
  };
  return (
    <>
      <LSideBar
        isToggle={isPropertyOpen}
        handleToggle={handleBar}
        header={"Properties"}
      >
        <table className="custom-table">
          <tbody>
            <tr>
              <th>Property Name</th>
              <th>Value</th>
            </tr>
            {Object.entries(properties).map(([key, value], index) => (
              <tr
                className="table-row"
                onClick={(event) => handleRowClick(event, [key, value])}
                key={index}
              >
                <td className="table-col1 email-text" title={key}>
                  {key}
                </td>
                <td className="table-col2">
                  <div className="table-col2-container">
                    <div className="table-col2-row1 email-text" title={value}>
                      {value}
                    </div>
                    <div className="table-col2-row2 ">
                      <div className="expand-icon"></div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </LSideBar>
      {
        <Box
          isToggle={boxData.isOpen}
          style={{
            width: boxData.width,
            height: boxData.height,
            position: "absolute",
            top: boxData.top,
            left: boxData.left,
          }}
          ref={boxRef}
        >
          <div className="box-property-container">
            <div>
              <label>Name</label>
            </div>
            <div>
              <Dropdown
                optionArray={propertyNames}
                value={selectedPropertyName}
                onChange={(e) => setSelectedPropertyName(e.target.value)}
                onClick={() => {}}
              />
            </div>
          </div>
          <div className="box-property-container">
            <div>
              <label>Value</label>
            </div>
            <div>
              <Dropdown
                optionArray={propertyValues}
                value={selectedPropertyValue}
                onChange={(e) => setSelectedPropertyValue(e.target.value)}
                onClick={() => {}}
              />
            </div>
          </div>
          <div className="box-btn-container">
            <IconButton
              buttonType={"Add"}
              buttonName={"Add Search Set"}
              onClick={handleAdd}
            />
            <IconButton
              buttonType={"Search"}
              buttonName={"Search"}
              onClick={() =>
                handleSearch(boxData.rowData?.name, boxData.rowData?.value)
              }
            />
          </div>
        </Box>
      }
      {
        <Box
          isToggle={saveBoxData.isOpen}
          style={{
            width: saveBoxData.width,
            height: saveBoxData.height,
            position: "absolute",
            top: saveBoxData.top,
            left: saveBoxData.left,
          }}
          ref={saveBoxRef}
        >
          <div className="box-property-container">
            <div>
              <label>Search Set Name</label>
            </div>
            <div>
              <input
                className="mycustom-button"
                value={searchSetName}
                onChange={(e) => {
                  setInputError(null);
                  setSearchSetName(e.target.value);
                }}
              />
              {inputError && <p className="input-error">{inputError}</p>}
            </div>
          </div>
          <div className="saveBox-btn-container">
            <Button buttonName={"Save"} onClick={() => saveSearchSet()} />
          </div>
        </Box>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isPropertyOpen: state.PropertyReducer.isPropertyOpen,
    properties: state.PropertyReducer.properties,
    projectName: state.ProjectReducer.projectName,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  handlePropertyAction,
  searchElementAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTab);
