import React from 'react';
import { calculateControlPoints } from '../utils/annotationUtils';

const TextBox = ({ 
  annotation, 
  isSelected, 
  isDragging,
  isEditing,
  text,
  onSelect,
  onDragStart,
  onControlPointDrag,
  onDoubleClick,
  onTextChange,
  onTextBlur,
  onTextKeyDown
}) => {
  const controlPoints = calculateControlPoints(annotation);

  const getControlPointCursor = (cpIndex) => {
    switch(cpIndex) {
      case 0: // Top-left
      case 7: // Bottom-right
        return 'nw-resize';
      case 2: // Top-right
      case 5: // Bottom-left
        return 'ne-resize';
      case 1: // Top-middle
      case 6: // Bottom-middle
        return 'n-resize';
      case 3: // Middle-left
      case 4: // Middle-right
        return 'e-resize';
      default:
        return 'pointer';
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: annotation.x,
        top: annotation.y,
        transform: 'translate(0, 0)',
        pointerEvents: 'all',
        cursor: isSelected ? (isDragging ? 'grabbing' : 'grab') : 'pointer',
        padding: '20px',
        margin: '-20px',
        backgroundColor: 'transparent',
        userSelect: 'none'
      }}
      onClick={onSelect}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isSelected) {
          onDragStart(e);
        } else {
          onSelect(e);
        }
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onDoubleClick();
      }}
    >
      <div
        style={{
          padding: '8px',
          width: annotation.width,
          height: annotation.height,
          backgroundColor: isSelected ? '#e3f2fd' : '#fff',
          border: isSelected ? '2px solid #1976d2' : '1px solid #ddd',
          position: 'relative',
          boxSizing: 'border-box',
          userSelect: 'none',
          pointerEvents: 'all'
        }}
      >
        {isEditing ? (
          <textarea
            value={text}
            onChange={onTextChange}
            onBlur={onTextBlur}
            onKeyDown={onTextKeyDown}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              padding: '0',
              margin: '0',
              backgroundColor: 'transparent',
              resize: 'none',
              outline: 'none',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              overflow: 'hidden'
            }}
            autoFocus
          />
        ) : (
          annotation.text
        )}
        {isSelected && !isEditing && (
          <svg
            style={{
              position: 'absolute',
              left: -4,
              top: -4,
              width: annotation.width + 8,
              height: annotation.height + 8,
              pointerEvents: 'none',
              overflow: 'visible'
            }}
          >
            {controlPoints.map((point, cpIndex) => (
              <g key={cpIndex} style={{ pointerEvents: 'all' }}>
                <circle
                  cx={point.x + 4}
                  cy={point.y + 4}
                  r={4}
                  fill="white"
                  stroke="#1976d2"
                  strokeWidth={2}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    onControlPointDrag(e, cpIndex);
                  }}
                  style={{
                    cursor: getControlPointCursor(cpIndex),
                    pointerEvents: 'all'
                  }}
                />
              </g>
            ))}
          </svg>
        )}
      </div>
    </div>
  );
};

export default TextBox; 