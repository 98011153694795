import React, { useState, useEffect } from "react";
import { CustomPicker } from "react-color";
import {
  Saturation,
  EditableInput,
  Hue,
} from "react-color/lib/components/common";

import tinycolor from "tinycolor2";
import "./ColorPicker.css";
import { IconButton } from "../Button/Button";

const onPointerMouseDown = (event) => {
  const pointer = document.querySelector(".custom-pointer");
  const pointerContainer = pointer?.parentElement;
  if (pointerContainer) {
    pointerContainer.style.top = event.clientY + "px";
    pointerContainer.style.left = event.clientX + "px";
  }
};

const CustomSlider = () => {
  return <div className="custom-slider" />;
};

const CustomPointer = () => {
  return <div className="custom-pointer" />;
};

const CustomColorPicker = ({
  isToggle,
  handleToggle,
  hexCode,
  onChange,
  onSave,
}) => {
  const [redValue, setRedValue] = useState(0);
  const [greenValue, setGreenValue] = useState(0);
  const [blueValue, setBlueValue] = useState(0);
  //   const [hexColor, setHexColor] = useState("#000000");

  const handleSliderChange = (event, color) => {
    const value = event.target.value;
    if (color === "red") setRedValue(value);
    if (color === "green") setGreenValue(value);
    if (color === "blue") setBlueValue(value);
  };

  useEffect(() => {
    generateColor();
  }, [redValue, greenValue, blueValue]);
  const [hsl, setHsl] = useState({ h: 0, s: 0, l: 0 });
  const [hsv, setHsv] = useState({ h: 0, s: 0, v: 0 });
  const [hex, setHex] = useState("797C7D");
  const [rgb, setRgb] = useState({ r: 1, g: 1, b: 1 });

  const generateColor = () => {
    const finalColor = `rgb(${redValue}, ${greenValue}, ${blueValue})`;
    setRgb(finalColor);
    updateHexColor(finalColor);
    // setResultColor(finalColor);
    // updateHexColor(finalColor);
    //   document.body.style.backgroundColor = finalColor;
    console.log("final", finalColor);
  };

  const updateHexColor = (rgbColor) => {
    const hexColor = rgbToHex(rgbColor);
    setHex(hexColor);
    onChange(hexColor);
  };

  const rgbToHex = (rgb) => {
    const rgbValues = rgb.match(/\d+/g);
    const hexColor = `#${Number(rgbValues[0])
      .toString(16)
      .padStart(2, "0")}${Number(rgbValues[1])
      .toString(16)
      .padStart(2, "0")}${Number(rgbValues[2]).toString(16).padStart(2, "0")}`;
    return hexColor;
  };

  useEffect(() => {
    const color = tinycolor(hexCode);
    setHsl(color.toHsl());
    setHsv(color.toHsv());
    setHex(color.toHex());
  }, [hexCode]);

  const handleHueChange = (hue) => {
    setHsl(hue);
  };

  const handleSaturationChange = (hsv) => {
    const color = tinycolor(hsv);
    onChange(hsv);
    const input = document.body.getElementsByTagName("input");
    if (input[5]) {
      input[5].value = color.toHex();
    }
    HexToRgb(color.toHex());
    setHsl(color.toHsl());
  };

  const HexToRgb = (hexValue) => {
    if (/^#?[0-9A-Fa-f]{6}$/.test(hexValue)) {
      // Remove the hash at the start if it's there
      hexValue = hexValue.replace(/^#/, "");

      // Parse r, g, b values
      let r = parseInt(hexValue.slice(0, 2), 16);
      let g = parseInt(hexValue.slice(2, 4), 16);
      let b = parseInt(hexValue.slice(4, 6), 16);
      console.log("r", r);
      console.log("g", g);
      console.log("b", b);
      setRedValue(r);
      setGreenValue(g);
      setBlueValue(b);
    }
  };

  return (
    isToggle && (
      <div className="color-picker-container">
        <div className="color-picker">
          <div className="color-picker-header">
            <div className="color-picker-header-name">
              <span>Set Color</span>
            </div>
            <div
              className="color-picker-header-cancel-btn"
              onClick={handleToggle}
            >
              X
            </div>
          </div>
          <div className="saturation" onMouseDown={onPointerMouseDown}>
            <Saturation
              hsl={hsl}
              hsv={hsv}
              pointer={CustomPointer}
              onChange={handleSaturationChange}
            />
          </div>
          <div className="hue">
            <Hue
              hsl={hsl}
              pointer={CustomSlider}
              onChange={handleHueChange}
              direction={"horizontal"}
            />
          </div>
          <div className="wrapper">
            <input
              className="range-input red-gradient"
              type="range"
              min="0"
              max="255"
              value={redValue}
              id="red"
              onChange={(e) => handleSliderChange(e, "red")}
            />
          </div>
          <div className="wrapper">
            <input
              className="range-input green-gradient"
              type="range"
              min="0"
              max="255"
              value={greenValue}
              id="green"
              onChange={(e) => handleSliderChange(e, "green")}
            />
          </div>
          <div className="wrapper">
            <input
              className="range-input blue-gradient"
              type="range"
              min="0"
              max="255"
              value={blueValue}
              id="blue"
              onChange={(e) => handleSliderChange(e, "blue")}
            />
          </div>
          <div
            className="color-swatches"
            style={{ backgroundColor: `#${hex}` }}
          ></div>
          <div className="color-controller">
            <div className="hex-input">
              <span>Hex</span>
              <EditableInput
                style={{
                  input: {
                    border: "1px solid #616161",
                    color: "#BFBFBF",
                    backgroundColor: "#232323",
                    fontSize: "15px",
                    width: "60px",
                    height: "28px",
                  },
                }}
                value={hex}
                onChange={HexToRgb}
              />
            </div>
            <div className="color-save-btn">
              <IconButton
                buttonName={"Save"}
                buttonType={"Save"}
                onClick={onSave}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomPicker(CustomColorPicker);
