import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import RSideBar from "../../components/RSideBar/RSideBar";
import "./AppearenceTemplateTab.css";

import {
  handleAppearanceTemplateAction,
  searchAppearenceTemplateListAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const AppearenceTemplateTab = (props) => {
  const {
    viewer,
    isAppearanceTemplateOpen,
    projectId,
    token,
    handleLoadingAction,
    handleAppearanceTemplateAction,
    searchAppearenceTemplateListAction,
  } = props;

  const [templateList, setTemplateList] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `appearances/projects/${projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      console.log("response", response);
      setTemplateList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  useEffect(() => {
    if (!isAppearanceTemplateOpen) return;
    getAllListing();
  }, [isAppearanceTemplateOpen]);

  const closeTab = () => {
    handleAppearanceTemplateAction(false);
  };

  const searchAppearenceTemplate = (e, item) => {
    viewer.scene.setObjectsColorized(viewer.scene.colorizedObjectIds, false);
    searchAppearenceTemplateListAction(item);
  };

  return (
    <RSideBar
      isToggle={isAppearanceTemplateOpen}
      handleToggle={() => handleAppearanceTemplateAction(false)}
      header={"Appearance Templates"}
    >
      <ul className="appearence-template-ul">
        {templateList.map((item, i) => (
          <li
            onClick={(e) => searchAppearenceTemplate(e, item)}
            className="appearance-template-li"
            key={i}
            value={item.name}
          >
            <div className="appearence-template-name">{item.name}</div>
          </li>
        ))}
      </ul>
    </RSideBar>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    isAppearanceTemplateOpen:
      state.AppearenceTemplateReducer.isAppearanceTemplateOpen,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  handleAppearanceTemplateAction,
  searchAppearenceTemplateListAction,
  handleLoadingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppearenceTemplateTab);
