import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  DistanceMeasurementsPlugin,
  DistanceMeasurementsMouseControl,
  ContextMenu,
  AnnotationsPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import {
  getMeasurementMouseControlInstantAction,
  saveDistanceMeasurementArrayAction,
  saveDistanceMeasurementPluginAction,
  seSelectEntityStatusAction,
  setMeasurementBtnStatusAction,
} from "../../action/xeokitAction";

const Measurement = (props) => {
  const {
    isOpen,
    isTwoPoints,
    isTwoObjects,
    isElevation,
    viewer,
    seSelectEntityStatusAction,
    distanceMeasurementPlugin,
  } = props;

  //distanceMeasurementsPlugin variable
  const [
    distanceMeasurementsMouseControl,
    setDistanceMeasurementsMouseControl,
  ] = useState(null);
  const [annotationPlugin, setAnnotationPlugin] = useState(null);

  //array to get entities of model
  const [twoObjects, setTwoObjects] = useState([]);
  const [twoObjectsInput, setTwoObjectsInput] = useState(null); //save twoObject input event
  const [elevationInput, setElevationInput] = useState(null); //save evevation annotation input event
  const [elevationAnnotation, setElevationAnnotation] = useState(null); //save elevation annotation
  const [savedDistanceMeasurement, setSavedDistanceMeasurement] = useState([]);
  //measurement button click
  useEffect(() => {
    //when the measurement button is clicked
    if (isOpen) {
      seSelectEntityStatusAction(false);

      //create distanceMeasurementsPlugin and distanceMeasurementsMouseControl
      // const distanceMeasurementsPlugin = new DistanceMeasurementsPlugin(viewer);
      const distanceMeasurementsMouseControl =
        new DistanceMeasurementsMouseControl(distanceMeasurementPlugin, {
          snapping: true,
        });

      //create context menu for distanceMeasurement
      const distanceMeasurementsContextMenu = new ContextMenu({
        items: [
          [
            {
              title: "Save this Measurement",
              doAction: function (context) {
                console.log(">>>>>", context.distanceMeasurement.id);
                setSavedDistanceMeasurement([
                  ...savedDistanceMeasurement,
                  context.distanceMeasurement.id,
                ]);
              },
            },
            {
              title: "Clear",
              doAction: function (context) {
                context.distanceMeasurement.destroy();
              },
            },
            {
              title: "Origin Visible",
              doAction: function (context) {
                context.distanceMeasurement.visible =
                  !context.distanceMeasurement.visible;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.axisVisible
                  ? "Hide Axis"
                  : "Show Axis";
              },
              doAction: function (context) {
                context.distanceMeasurement.axisVisible =
                  !context.distanceMeasurement.axisVisible;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.xLabelEnabled &&
                  context.distanceMeasurement.labelsVisible
                  ? "Disable X Label"
                  : "Enable X Label";
              },
              doAction: function (context) {
                context.distanceMeasurement.xLabelEnabled =
                  !context.distanceMeasurement.xLabelEnabled;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.yLabelEnabled &&
                  context.distanceMeasurement.labelsVisible
                  ? "Disable Y Label"
                  : "Enable Y Label";
              },
              doAction: function (context) {
                context.distanceMeasurement.yLabelEnabled =
                  !context.distanceMeasurement.yLabelEnabled;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.zLabelEnabled &&
                  context.distanceMeasurement.labelsVisible
                  ? "Disable Z Label"
                  : "Enable Z Label";
              },
              doAction: function (context) {
                context.distanceMeasurement.zLabelEnabled =
                  !context.distanceMeasurement.zLabelEnabled;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.lengthLabelEnabled &&
                  context.distanceMeasurement.labelsVisible
                  ? "Disable Length Label"
                  : "Enable Length Label";
              },
              doAction: function (context) {
                context.distanceMeasurement.lengthLabelEnabled =
                  !context.distanceMeasurement.lengthLabelEnabled;
              },
            },
            {
              getTitle: (context) => {
                return context.distanceMeasurement.labelsVisible
                  ? "Hide All Labels"
                  : "Show All Labels";
              },
              doAction: function (context) {
                context.distanceMeasurement.labelsVisible =
                  !context.distanceMeasurement.labelsVisible;
              },
            },
          ],
          [
            {
              title: "Clear All",
              getEnabled: function (context) {
                return (
                  Object.keys(context.distanceMeasurementsPlugin.measurements)
                    .length > 0
                );
              },
              doAction: function (context) {
                context.distanceMeasurementsPlugin.clear();
              },
            },
            {
              getTitle: () => {
                return "Cancel Measurement";
              },
              doAction: function () {
                distanceMeasurementsMouseControl.reset();
              },
            },
          ],
        ],
      });

      distanceMeasurementsContextMenu.on("hidden", () => {
        if (distanceMeasurementsContextMenu.context.distanceMeasurement) {
          distanceMeasurementsContextMenu.context.distanceMeasurement.setHighlighted(
            false
          );
        }
      });

      distanceMeasurementPlugin.on("mouseOver", (e) => {
        e.distanceMeasurement.setHighlighted(true);
      });

      distanceMeasurementPlugin.on("mouseLeave", (e) => {
        if (
          distanceMeasurementsContextMenu.shown &&
          distanceMeasurementsContextMenu.context.distanceMeasurement.id ===
            e.distanceMeasurement.id
        ) {
          return;
        }
        e.distanceMeasurement.setHighlighted(false);
      });

      distanceMeasurementPlugin.on("contextMenu", (e) => {
        // Context menu logic here
        distanceMeasurementsContextMenu.context = {
          // Must set context before showing menu
          viewer: props.viewer,
          distanceMeasurementsPlugin: distanceMeasurementPlugin,
          distanceMeasurement: e.distanceMeasurement,
        };
        distanceMeasurementsContextMenu.show(e.event.clientX, e.event.clientY);
        e.event.preventDefault();
      });

      //save the setDistanceMeasurementsPlugin to use as a global variable
      setDistanceMeasurementsMouseControl(distanceMeasurementsMouseControl);
    } else if (isOpen === false) {
      //when the measurement buttons are closed
      seSelectEntityStatusAction(true); //active the mouse input in the viewer
      console.log("distancemeasurement", distanceMeasurementPlugin);
      Object.entries(distanceMeasurementPlugin._measurements).map(
        (measurement) => {
          console.log("measurement", measurement);
          console.log(
            "measurement",
            savedDistanceMeasurement.includes(measurement[0])
          );
          if (!savedDistanceMeasurement.includes(measurement[0]))
            distanceMeasurementPlugin.destroyMeasurement(measurement[0]);
        }
      );
      distanceMeasurementsMouseControl?.destroy();
    }
  }, [isOpen]);

  //handle two points measurement
  useEffect(() => {
    if (isOpen && isTwoPoints) distanceMeasurementsMouseControl.activate();
    else if (isOpen && !isTwoPoints)
      distanceMeasurementsMouseControl?.deactivate();
  }, [isTwoPoints]);

  //handle two objects measurement
  useEffect(() => {
    if (isOpen && isTwoObjects) {
      //occur mouse input event and track
      const mouseInput = viewer.scene.input.on(
        "mouseclicked",
        async (coords) => {
          setTwoObjectsInput(mouseInput);
          const pickResult = viewer.scene.pick({
            canvasPos: coords,
            pickSurface: true,
          });
          if (pickResult) {
            console.log("pickResult??");

            //get the entity id and aabb
            const { id, aabb } = pickResult.entity;

            //when one object is selected in the viewer
            if (twoObjects.length === 0) {
              console.log("00", twoObjects.length);
              //   setTwoObjects([...twoObjects, { id, aabb }]);
              twoObjects.push({ id, aabb });
              pickResult.entity.selected = true;
            } else if (twoObjects.length === 1) {
              //when two objects are selected
              console.log("22");
              //   setTwoObjects([...twoObjects, { id, aabb }]);
              twoObjects.push({ id, aabb });
              pickResult.entity.selected = true;
              createDistanceMeasurement(twoObjects[0].aabb, twoObjects[1].aabb);
            } else {
              //when more two objects are selected, reset objects to start again
              //   setTwoObjects([]);
              const entity1 = props.viewer.scene.objects[twoObjects[0].id];
              const entity2 = props.viewer.scene.objects[twoObjects[1].id];
              entity1.selected = false;
              entity2.selected = false;
              twoObjects.pop();
              twoObjects.pop();
            }
          }
        }
      );
    } else if (
      (isOpen && !isTwoObjects) ||
      (isOpen === false && isTwoObjects === false)
    ) {
      viewer.scene.input.off(twoObjectsInput); //kill the mouse input event
    }
  }, [isTwoObjects]);

  //when elevation button is clicked
  useEffect(() => {
    if (isOpen && isElevation) {
      const annotations = new AnnotationsPlugin(props.viewer, {
        container: document.getElementById("annotationsContainer"),
        markerHTML:
          "<div class='elevation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
        values: {
          markerBGColor: "white",
          labelBGColor: "red",
          glyph: "X",
        },
      });
      setAnnotationPlugin(annotations);
      annotations.on("markerClicked", (annotation) => {
        annotation.labelShown = !annotation.labelShown;
      });
      const mouseInput = viewer.scene.input.on(
        "mouseclicked",
        async (coords) => {
          const pickResult = props.viewer.scene.pick({
            canvasPos: coords,
            pickSurface: true,
          });
          if (pickResult) {
            const aabb = props.viewer.scene.models.myModel.aabb;
            const minY = aabb[1];

            console.log("pickResult", pickResult.worldPos[1]);
            console.log("pickResult", pickResult.worldPos[1] - 1);
            console.log("minY", minY);
            annotations.clear();
            // const position = bottomOffset + pickResult.worldPos[1];
            const position = (pickResult.worldPos[1] - minY) * 0.3061224489;

            annotations.createAnnotation({
              id: "myAnnotation",
              worldPos: [
                pickResult.worldPos[0],
                pickResult.worldPos[1],
                pickResult.worldPos[2],
              ],
              // worldPos: pickResult.worldPos,
              occludable: true,
              markerShown: true,
              labelShown: true,
              values: { glyph: `${position.toFixed(2)}m` },
            });

            setElevationAnnotation(annotations);
          }
        }
      );
      setElevationInput(mouseInput);
    } else if (
      (isOpen && !isElevation) ||
      (isOpen === false && isElevation === false)
    ) {
      viewer.scene.input.off(elevationInput);
      elevationAnnotation.destroy();
      annotationPlugin.destroy();
    }
  }, [isElevation]);

  const createDistanceMeasurement = (aabb1, aabb2) => {
    console.log("create", twoObjects);
    const closestPoint1 = [
      Math.max(aabb1[0], Math.min(aabb2[0], aabb1[3])),
      Math.max(aabb1[1], Math.min(aabb2[1], aabb1[4])),
      Math.max(aabb1[2], Math.min(aabb2[2], aabb1[5])),
    ];
    const closestPoint2 = [
      Math.max(aabb2[0], Math.min(aabb1[0], aabb2[3])),
      Math.max(aabb2[1], Math.min(aabb1[1], aabb2[4])),
      Math.max(aabb2[2], Math.min(aabb1[2], aabb2[5])),
    ];

    distanceMeasurementPlugin.createMeasurement({
      id: "distanceMeasurement1",
      origin: {
        entity: viewer.scene.objects[twoObjects[0].id],
        worldPos: closestPoint1,
      },
      target: {
        entity: viewer.scene.objects[twoObjects[1].id],
        worldPos: closestPoint2,
      },
      visible: true,
      wireVisible: true,
    });
  };
  return <></>;
};

const mapStateToProps = (state) => ({
  viewer: state.xeokitReducer.viewer,
  distanceMeasurementsPlugin: state.xeokitReducer.distanceMeasurementsPlugin,
  distanceMeasurementsMouseControl:
    state.xeokitReducer.distanceMeasurementsMouseControl,
  measureBtn: state.xeokitReducer.measureBtn,
  distanceMeasurementsMouse: state.xeokitReducer.distanceMeasurementsMouse,
  distanceMeasurementArray: state.xeokitReducer.distanceMeasurementArray,
  distanceMeasurementPlugin: state.xeokitReducer.distanceMeasurementPlugin,
});

const mapDispatchToProps = {
  getMeasurementMouseControlInstantAction,
  saveDistanceMeasurementArrayAction,
  saveDistanceMeasurementPluginAction,
  seSelectEntityStatusAction,
  setMeasurementBtnStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Measurement);
