import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { AnnotationsPlugin } from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import LSideBar from "../../components/LSideBar/LSideBar";
import Modal from "../../components/Modal/Modal";
import { CustomToast } from "../../utlity/toastify";
import Canvas from "../../components/Canvas/CustomCanvas";

import "./AnnotationTab.css";

import {
  setAnnotationTabStatusAction,
  getAnnotationTabMouseInputAction,
  seSelectEntityStatusAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setAnnotationOptionTabAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";
import Dropdown from "../../components/DropDown/Dropdown";
import { IconButton, CustomDate } from "../../components/Button/Button";

const AnnotationTab = (props) => {
  const [createAnnotation, setCreateAnnotation] = useState(null);
  const [pickResult, setPickResult] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [markupImage, setMarkupImage] = useState(null);
  const defaultIssue = {
    id: 0,
    title: "",
    stamp: "",
    status: "",
    type: "",
    assignee: "",
    dueDate: "",
    startDate: "",
  };

  const [options, setOptions] = useState({
    stampOption: ["OME", "1AR"],
    statusOption: ["Solved", "Closed", "InProgress", "Open"],
    typeOption: ["Standard Issue", "Other Issue"],
    assigneeOption: [
      { name: "Alexander Salna", company: "Northrop Consulting Engineers" },
      { name: "Adrian Wilkins", company: "Dina Group" },
    ],
  });

  const [issueModal, setIssueModal] = useState({
    isOpen: false,
    height: `calc(80vh)`,
    title: "",
    stamp: options.stampOption[0],
    status: options.statusOption[0],
    type: options.typeOption[0],
    assignee: [options.assigneeOption[0]],
    dueDate: "",
    startDate: new Date().toLocaleDateString("en-CA"),
    // startDate: "2025-01-06",
    comment: "",
    inputTitleError: null,
    inputDueDateError: null,
  });

  const [issueType, setIssueType] = useState("");
  const [isIssueTypeClicked, setIsIssueTypeClicked] = useState(false);
  const [clickedIssueType, setClickedIssueType] = useState(null);

  const [isAnnotationOptionTabOpen, setIsAnnotationOptionTabOpen] =
    useState(false);

  const [annotationTypes, setAnnotationTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `annotationTypes?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setAnnotationTypes(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, [props.isAnnotationTabOpen]);

  const handleAnnotationTab = () => {
    props.seSelectEntityStatusAction(true);
    props.setAnnotationTabStatusAction(false);
    setIsIssueTypeClicked(false);
    setIssueType(null);
  };

  const handleAnnotationOptionTab = () => {
    // setIsAnnotationOptionTabOpen(false);
    setAnnotationOptionTabAction(false);
  };
  const handleIssueTypeSelection = (data) => {
    setClickedIssueType(data.abbreviation);
    setIssueType(data);
    setIsIssueTypeClicked(true);
  };

  const captureScreenshot = async () => {
    // const img = new Image();
    // const retVal = await props.viewer.getSnapshotWithPlugins({
    //   format: "png",
    //   width: img.width * 3, // Upscale snapshot resolution 2x
    //   height: img.height * 3,
    // });
    // return retVal;
    console.log("captureViewer", props.viewer);
    const screenshot = await props.viewer.getSnapshotWithPlugins({
      format: "png",
      width: window.innerWidth * 3, // Upscale snapshot resolution by 3x
      height: window.innerHeight * 3,
    });
    setMarkupImage(screenshot);
    return screenshot;
  };

  const updateScreenshotForIssue = async () => {
    const updateScreenshot = async () => {
      const newScreenshot = await captureScreenshot();
      const updatedIssues = props.issues;
      updatedIssues[props.issueToUpdateId].markUp = newScreenshot;
      props.saveIssues(updatedIssues);
    };
  };

  useEffect(() => {
    const { viewer, setAnnotationOptionTabAction } = props;
    if (!viewer || !isIssueTypeClicked) return;

    let annotationCount = 1;

    const handleMouseClick = viewer.scene.input.on(
      "mouseclicked",
      async (coords) => {
        const pickResult = viewer.scene.pick({
          canvasPos: coords,
          pickSurface: true,
        });

        if (pickResult) {
          console.log("IssueType", issueType);
          setPickResult(pickResult);
          setIssueModal({ ...issueModal, isOpen: true });

          const annotation = props.annotationPlugin.createAnnotation({
            pickResult: pickResult,
            occludable: true,
            markerShown: true,
            labelShown: false,
            values: {
              glyph: issueType.abbreviation,
              fontColor: issueType.color,
              borderColor: issueType.color,
            },
          });

          setCreateAnnotation(annotation);
          // console.log("Test", test);
          // console.log("Test", props.viewer);
          annotationCount++;
          // setTimeout(handleIssueTracking, 1000);

          if (annotationCount === 2) {
            viewer.scene.input.off(handleMouseClick);
            // setAnnotationOptionTabAction(true);
            // handleIssueTracking();
          }
        }
      }
    );
  }, [issueType]);

  const uploadMarkup = async (annotationId, markupImage) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    // Convert the base64 image to a Blob
    const base64Data = markupImage.replace(/^data:image\/png;base64,/, ""); // Remove base64 header
    const blob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      {
        type: "image/png",
      }
    );

    console.log("formatted", typeof formattedDate);
    // console.log("fileUploader", fileUploader);
    const url = BaseURL("annotations/markup");

    let formData = new FormData();

    formData.append("markup", blob, `issue-${formattedDate}.png`);
    formData.append("annotation", annotationId);

    const res = await Post(url, formData, apiHeader(props.token, true));
    return res;
  };

  const handleIssueTracking = async () => {
    console.log("annotation", createAnnotation);
    let screenshot;
    if (markupImage === null) {
      screenshot = await captureScreenshot();
    }

    // console.log("screenshot", screenshot);

    // // props.setIssueTrackStatusAction(true);

    // const issues = structuredClone(props.IssuesArray);
    handleLoadingAction(true);
    const url = BaseURL("annotations");
    const params = {
      entity: createAnnotation?.entity.id,
      values: createAnnotation?._values,
      new: true,
      worldPos: createAnnotation?.worldPos,
      viewPos: createAnnotation?.viewPos,
      canvasPos: createAnnotation?.canvasPos,
      camera: {
        eye: props.viewer.camera.eye,
        look: props.viewer.camera.look,
        up: props.viewer.camera.up,
      },
      title: issueModal.title,
      stamp: issueModal.stamp,
      status: issueModal.status,
      type: issueModal.type,
      assignee: issueModal.assignee,
      dueDate: issueModal.dueDate,
      startDate: issueModal.startDate,
      comment: issueModal.comment,
      projects: [`${props.projectName}`],
    };
    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      if (markupImage === null)
        await uploadMarkup(response?.data?.id, screenshot);
      else await uploadMarkup(response?.data?.id, markupImage);
      CustomToast("Saved Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    setIssueModal({ ...issueModal, isOpen: false });
    setMarkupImage(null);
    // issues.push(newIssue);
    // props.saveIssueArrayAction(structuredClone(issues));

    // const logs = structuredClone(props.logsArray);
    // logs.push([{ markUp: screenshot }]);
    // props.saveLogArrayAction(structuredClone(logs));
  };

  const onCreateIssue = () => {
    console.log("StartDate", issueModal.startDate);
    if (!issueModal.title) {
      setIssueModal({ ...issueModal, inputTitleError: "Please Input Title" });
      return;
    }
    if (!issueModal.dueDate) {
      setIssueModal({
        ...issueModal,
        inputDueDateError: "Please Input DueDate",
      });
      return;
    }
    handleIssueTracking();
    console.log("IssueModal", issueModal);
  };

  const onCreateMarkUp = async () => {
    if (markupImage === null) await captureScreenshot();
    setIsImageEditorOpen(true);
  };

  const updateMarkup = (updatedMarkup) => {
    setMarkupImage(updatedMarkup);
    setIsImageEditorOpen(false);
  };
  return (
    <>
      <div id="annotationsContainer"></div>
      <LSideBar
        isToggle={props.isAnnotationTabOpen}
        handleToggle={handleAnnotationTab}
        header={"Annotation Tab"}
      >
        <div className="annotation-tab-body">
          {annotationTypes.map((annotationType, index) => (
            <div
              onClick={() => handleIssueTypeSelection(annotationType)}
              className={
                clickedIssueType === annotationType.abbreviation
                  ? "issue-type-clicked"
                  : "issue-type"
              }
              style={{
                color: annotationType.color,
                borderColor: annotationType.color,
              }}
            >
              {annotationType.abbreviation}
            </div>
          ))}
        </div>
      </LSideBar>
      <Modal
        isToggle={issueModal.isOpen}
        handleToggle={() => {
          props.annotationPlugin.destroyAnnotation(createAnnotation.id);
          setIssueModal({ ...issueModal, isOpen: false });
        }}
        header={"Issue Modal"}
        height={issueModal.height}
      >
        <div className="annotation-issue-container">
          <div className="annotation-issue-modal">
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Issue Title</div>
              <div className="issue-modal-title-input">
                <input
                  className="mycustom-button"
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      title: event.target.value,
                      inputTitleError: null,
                    })
                  }
                  style={{ width: "98%" }}
                />
              </div>
              {issueModal.inputTitleError && (
                <p className="input-error">{issueModal.inputTitleError}</p>
              )}
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Stamp</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                  // value={issueModal.stamp}
                  optionArray={options.stampOption}
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      stamp: event.target.value,
                    })
                  }
                  onClick={() => {}}
                /> */}
                <input
                  className="mycustom-button"
                  value={issueModal.stamp}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Status</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                  // value={issueModal.stamp}
                  optionArray={options.statusOption}
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      status: event.target.value,
                    })
                  }
                  onClick={() => {}}
                /> */}
                <input
                  className="mycustom-button"
                  value={options.statusOption[0]}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Type</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                  // value={issueModal.stamp}
                  optionArray={options.typeOption}
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      type: event.target.value,
                    })
                  }
                  onClick={() => {}}
                /> */}
                <input
                  className="mycustom-button"
                  value={options.typeOption[0]}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Assignee To</div>
              <div className="issue-modal-title-input">
                {/* <Dropdown
                  // value={issueModal.stamp}
                  optionArray={options.assigneeOption}
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      assignee: event.target.value,
                    })
                  }
                  onClick={() => {}}
                /> */}
                <input
                  className="mycustom-button"
                  value={options.assigneeOption[0].name}
                  style={{
                    width: "98%",
                    backgroundColor: "#303030",
                    borderBlockColor: "#303030",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Due To</div>
              <div className="issue-modal-title-input">
                <CustomDate
                  date={issueModal.dueDate}
                  handleDateChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      dueDate: event.target.value,
                      inputDueDateError: null,
                    })
                  }
                  style={{ width: "96%" }}
                />
              </div>
              {issueModal.inputDueDateError && (
                <p className="input-error">{issueModal.inputDueDateError}</p>
              )}
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Start Date</div>
              <div className="issue-modal-title-input">
                <CustomDate
                  date={new Date().toLocaleDateString("en-CA")}
                  // date={"2025-01-06"}
                  handleDateChange={() => {}}
                  style={{ width: "96%" }}
                  disabled={true}
                />
              </div>
            </div>
            <div className="issue-modal-title">
              <div className="issue-modal-title-label">Comment</div>
              <div className="issue-modal-title-input">
                <textarea
                  className="issue-modal-textarea"
                  onChange={(event) =>
                    setIssueModal({
                      ...issueModal,
                      comment: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <IconButton
              buttonName={"Create"}
              buttonType={"Save"}
              onClick={onCreateIssue}
            />
            <IconButton
              buttonName={"Add MarkUp"}
              buttonType={"Save"}
              onClick={onCreateMarkUp}
            />
          </div>
        </div>
      </Modal>
      {isImageEditorOpen && (
        <div className="image-editor">
          <Canvas
            backgroundImage={markupImage}
            cancel={setIsImageEditorOpen}
            updateMarkup={updateMarkup}
          />
        </div>
      )}
      {/* <div className="annotation-option-tab">
        <Tab2
          isOpen={props.setAnnotationOptionTabStatus}
          onClose={handleAnnotationOptionTab}
          tabName={"AnnotationOption Tab"}
        >
          <button
            className="custom-button"
            onClick={() => updateScreenshotForIssue()}
          >
            scrrenshot
          </button>
          <button
            className="custom-button"
            onClick={() => handleIssueTracking()}
          >
            Issue Track
          </button>
        </Tab2>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    isAnnotationTabOpen: state.AnnotationReducer.isAnnotationTabOpen,
    annotationTabMouseInput: state.AnnotationReducer.annotationTabMouseInput,
    IssuesArray: state.IssueTrackReducer.IssuesArray,
    logsArray: state.IssueTrackReducer.logsArray,
    updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
    setAnnotationOptionTabStatus:
      state.IssueTrackReducer.setAnnotationOptionTabStatus,
    projectName: state.ProjectReducer.projectName,
    token: state.AuthReducer.token,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
  };
};

const mapDispatchToProps = {
  setAnnotationTabStatusAction,
  getAnnotationTabMouseInputAction,
  seSelectEntityStatusAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setAnnotationOptionTabAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationTab);
