import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Tab from "../../components/Tab/Tab";
import LSideBar from "../../components/LSideBar/LSideBar";

import {
  ImagePlane,
  Mesh,
  PhongMaterial,
  ReadableGeometry,
  buildBoxGeometry,
  buildVectorTextGeometry,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import "./ImagePlane.css";

import {
  setImagePlaneStatusAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import {
  apiHeader,
  imagePlaneUrl,
  BaseURL,
  recordsLimit,
} from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";
import { Button, CheckBox } from "../../components/Button/Button";
import Dropdown from "../../components/DropDown/Dropdown";
// import IMAGEFILE from "../../asset/default.png";
import Grid from "../../asset/grid.png";
import { click } from "@testing-library/user-event/dist/click";
const ImagePlaneTab = (props) => {
  const [showList, setShowList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [originalNames, setOriginalNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [imagePlaneIds, setImagePlaneIds] = useState([]);
  const [activeViews, setActiveViews] = useState([]);
  const [clickedImagePlane, setClickedImagePlane] = useState({});
  const [clickedLevelPlane, setClickedLevelPlane] = useState({});
  const [clickedGridPlane, setClickedGridPlane] = useState({});
  const [offset, setOffset] = useState(null);
  const [gridEntries, setGridEntries] = useState([]);
  const [levelEntries, setLevelEntries] = useState([]);
  const [activeGrids, setActiveGrids] = useState([]);
  const [activeLevels, setActiveLevels] = useState([]);
  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `imagePlanes/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    props.handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      setShowList(response.data?.imagePlanes);
      setTotalPages(response?.data?.totalResults);
      const newOriginalNames =
        response?.data?.imagePlanes.map((ele) => ele.originalname) || [];
      const newFileNames =
        response?.data?.imagePlanes.map((ele) => ele.filename) || [];
      const newIds = response?.data?.imagePlanes.map((ele) => ele._id) || [];
      setOriginalNames(newOriginalNames);
      setFileNames(newFileNames);
      setImagePlaneIds(newIds);
      setSelectedImagePlane(newOriginalNames[0]);
    }

    props.handleLoadingAction(false);
  };

  useEffect(() => {
    if (imagePlaneIds.length === 0) return;
    activeViews.map((item, index) =>
      item.id === props.selectedEntityId ? setClickedImagePlane(item) : item
    );

    console.log("activelevels", activeLevels);
    activeLevels.map((activeLevel, index) =>
      activeLevel.id === props.selectedEntityId
        ? setClickedLevelPlane(activeLevel)
        : activeLevel
    );

    activeGrids.map((activeGrid, index) =>
      activeGrid.id === props.selectedEntityId
        ? setClickedGridPlane(activeGrid)
        : activeGrid
    );
  }, [props.selectedEntityId]);

  const getGridsLevels = () => {
    if (props.metaData?.ProjectInfo?.Main?.Grids) {
      const grids = props.metaData.ProjectInfo.Main.Grids;
      const sortedGridEntries = Object.entries(grids).sort(
        ([idA, dataA], [idB, dataB]) => {
          const nameA = dataA.Name || `Grid ${idA}`;
          const nameB = dataB.Name || `Grid ${idB}`;
          return nameA.localeCompare(nameB);
        }
      );

      console.log("sortedGrid", sortedGridEntries);
      setGridEntries(sortedGridEntries);
    }

    if (props.metaData?.ProjectInfo?.Main?.Levels) {
      const levels = props.metaData.ProjectInfo.Main.Levels;

      // Sort levels by elevation
      const sortedLevelEntries = Object.entries(levels).sort(
        ([idA, dataA], [idB, dataB]) => {
          // Sort by elevation value
          const elevationA = parseFloat(dataA.Elevation || 0);
          const elevationB = parseFloat(dataB.Elevation || 0);
          return elevationA - elevationB; // Sort from lowest to highest
        }
      );

      setLevelEntries(sortedLevelEntries);
    }
  };

  useEffect(() => {
    if (!props.imagePlaneTabStatus) return;
    getAllListing();
    getGridsLevels();
  }, [props.imagePlaneTabStatus]);

  const closeTab = () => {
    props.setImagePlaneStatusAction(false);
  };
  const [imagePlane, setImagePlane] = useState();

  // Add this function to parse image metadata
  async function getImageMetadata(imageURL) {
    try {
      const response = await fetch(imageURL);
      const arrayBuffer = await response.arrayBuffer();
      const bytes = new Uint8Array(arrayBuffer);

      // Skip PNG signature (8 bytes)
      let offset = 8;

      while (offset < bytes.length) {
        // Read chunk length (4 bytes)
        const length =
          (bytes[offset] << 24) |
          (bytes[offset + 1] << 16) |
          (bytes[offset + 2] << 8) |
          bytes[offset + 3];
        offset += 4;

        // Read chunk type (4 bytes)
        const type = String.fromCharCode(
          bytes[offset],
          bytes[offset + 1],
          bytes[offset + 2],
          bytes[offset + 3]
        );
        offset += 4;

        // If this is a tEXt chunk
        if (type === "tEXt") {
          // Read the keyword until null byte
          let keywordEnd = offset;
          while (bytes[keywordEnd] !== 0 && keywordEnd < offset + length) {
            keywordEnd++;
          }

          // Skip the null separator
          keywordEnd++;

          // Read the text content
          const textContent = new TextDecoder().decode(
            bytes.slice(keywordEnd, offset + length)
          );

          try {
            const metadataJson = JSON.parse(textContent);
            console.log("Found metadata:", metadataJson);
            return metadataJson;
          } catch (e) {
            console.log("Found text but not valid JSON:", textContent);
          }
        }

        // Skip chunk data and CRC
        offset += length + 4;
      }

      throw new Error("No metadata found in image");
    } catch (error) {
      console.error("Error in getImageMetadata:", error);
      throw error;
    }
  }

  // Add this helper function at the top level to calculate cross product
  function crossProduct(a, b) {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ];
  }

  const onChangeAxisZ = () => {
    // setPosition(e.target.value);
    // if (!imagePlane) return;
    // const oldPosition = imagePlane.position;
    // const dir = imagePlane.dir;

    // imagePlane.position = [
    //   e.target.value > position
    //     ? oldPosition[0] + 0.5 * dir[0]
    //     : oldPosition[0] - 0.5 * dir[0],
    //   e.target.value > position
    //     ? oldPosition[1] + 0.5 * dir[1]
    //     : oldPosition[1] - 0.5 * dir[1],
    //   e.target.value > position
    //     ? oldPosition[2] + 0.5 * dir[2]
    //     : oldPosition[2] - 0.5 * dir[2],
    // ];

    if (offset === null || Object.keys(clickedImagePlane).length === 0) return;
    // if (Object.keys(clickedImagePlane).length === 0) return;
    // clickedImagePlane.imagePlane.opacity = e.target.value;

    console.log("clicked", clickedImagePlane);
    const oldPosition = clickedImagePlane.originalPosition;
    const dir = clickedImagePlane.imagePlane.dir;

    // const offset = Math.abs(clickedImagePlane.position - e.target.value);
    // console.log("offset", offset);
    // console.log("clickedImagePlane", clickedImagePlane);

    clickedImagePlane.imagePlane.position = [
      offset < 0
        ? oldPosition[0] + (Math.abs(offset) / 0.3061224489) * dir[0]
        : oldPosition[0] - (Math.abs(offset) / 0.3061224489) * dir[0],
      offset < 0
        ? oldPosition[1] + (Math.abs(offset) / 0.3061224489) * dir[1]
        : oldPosition[1] - (Math.abs(offset) / 0.3061224489) * dir[1],
      offset < 0
        ? oldPosition[2] + (Math.abs(offset) / 0.3061224489) * dir[2]
        : oldPosition[2] - (Math.abs(offset) / 0.3061224489) * dir[2],
    ];

    console.log(
      "clickedImagePlane.imagePlane.position",
      clickedImagePlane.imagePlane.position
    );
    setClickedImagePlane({ ...clickedImagePlane, offset: offset });
  };

  const onChangeOpacity = (e) => {
    if (Object.keys(clickedImagePlane).length === 0) return;
    clickedImagePlane.imagePlane.opacity = e.target.value;
    setClickedImagePlane({ ...clickedImagePlane, opacity: e.target.value });
  };

  const [selectedImagePlane, setSelectedImagePlane] = useState(null);
  const [imageVisible, setImageVisible] = useState(null);
  const handleDropDwon = (event) => {
    setSelectedImagePlane(event.target.value);
  };
  const handleImageVisible = async (id, index) => {
    // setImageVisible(!imageVisible);
    if (activeViews[index].imageVisible) {
      handleLoadingAction(true);
      activeViews[index].imagePlane.destroy();
      setActiveViews((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, imageVisible: false } : item
        )
      );
      handleLoadingAction(false);
      return;
    }
    handleLoadingAction(true);

    // Initialize default values
    let origin = [0, 0, 0];
    let upDirection = [0, 0, 1];
    let rightDirection = [-1, 0, 0];
    let dir = [0, -1, 0];
    let size = 290; // Default size
    // originalNames.map((item, index) => {
    //   if (item === selectedImagePlane) {
    //     url = fileNames[index];
    //   }
    // });
    const url = activeViews[index].fileName;
    const metadata = await getImageMetadata(imagePlaneUrl(url));
    // Parse metadata if available
    if (metadata && metadata.MinXYZStr && metadata.MaxXYZStr) {
      if (typeof metadata.MinXYZStr === "string") {
        // Parse coordinates
        const coords = metadata.CenterPoint.split(",").map(Number);
        const minCoords = metadata.MinXYZStr.split(",").map(Number);
        const maxCoords = metadata.MaxXYZStr.split(",").map(Number);
        // Calculate dimensions
        const width = Math.abs(maxCoords[0] - minCoords[0]);
        const height = Math.abs(maxCoords[2] - minCoords[2]);
        // Calculate scale factor based on the actual dimensions
        const baseSize = 100;
        const scaleFactor = Math.max(width, height) / baseSize;
        size = baseSize * scaleFactor;
        // Transform coordinates to match our coordinate system
        origin = [-coords[0], coords[2], coords[1]];
        console.log("corrds", coords);
        const upCoords = metadata.UpDirection.split(",").map(Number);
        const rightCoords = metadata.RightDirection.split(",").map(Number);
        upDirection = [upCoords[0], upCoords[1], upCoords[2]];
        rightDirection = [rightCoords[0], rightCoords[1], rightCoords[2]];
        const rawDir = crossProduct(rightDirection, upDirection);
        dir = [-rawDir[0], -rawDir[2], -rawDir[1]];
        console.log("dirup", upDirection);
        console.log("Calculated dimensions:", {
          width,
          height,
          scaleFactor,
          size,
          minCoords,
          maxCoords,
        });
      }
    }
    // setImageVisible(!imageVisible);
    const response = await axios.get(imagePlaneUrl(url), {
      responseType: "blob",
    });
    const srcUrl = URL.createObjectURL(response.data);
    const newPlane = new ImagePlane(props.viewer.scene, {
      // src: IMAGEFILE,
      id: `${id}`,
      src: srcUrl,
      visible: true,
      gridVisible: false,
      size: size,
      position: origin,
      up: upDirection,
      dir: dir,
      opacity: 0.5,
      collidable: true, // ImagePlane does not contribute to Scene boundary
      clippable: true, // ImagePlane can be clipped by SectionPlanes
      pickable: true,
    });
    const startPoint =
      upDirection[0] * origin[0] +
      upDirection[1] * origin[1] +
      upDirection[2] * origin[2];
    const originalPosition = origin;
    setActiveViews((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              imagePlane: newPlane,
              startPoint: startPoint,
              originalPosition: originalPosition,
              imageVisible: true,
            }
          : item
      )
    );
    handleLoadingAction(false);
  };

  const onAddImagePlane = () => {
    originalNames.map((originalName, index) => {
      if (originalName === selectedImagePlane) {
        const existView = activeViews.filter(
          (activeView) => activeView.originalName === selectedImagePlane
        );
        if (existView.length === 0)
          setActiveViews([
            ...activeViews,
            {
              originalName: selectedImagePlane,
              fileName: fileNames[index],
              id: imagePlaneIds[index],
              imagePlane: null,
              opacity: 0.5,
              offset: 0,
              startPoint: 0,
              originalPosition: 0,
              imageVisible: false,
            },
          ]);
      }
    });
  };

  const handleGridVisible = (girdId, gridData) => {
    let flag = false;
    activeGrids.forEach((activeGrid, index) => {
      if (activeGrid.id === girdId) {
        console.log("activeGrid", activeGrid);
        activeGrid.gridPlane.visible = !activeGrid.gridPlane.visible;
        activeGrid.label.setMarkerShown(!activeGrid.label._markerShown);
        activeGrid.label.setLabelShown(!activeGrid.label._labelShown);
        flag = true;
        return;
      }
    });
    if (flag) return;
    const end1 = gridData.End1.split(",").map(Number);
    const end2 = gridData.End2.split(",").map(Number);

    const point1 = [-end1[0], end1[2], end1[1]];
    const point2 = [-end2[0], end2[2], end2[1]];

    const baseVector = [
      point2[0] - point1[0],
      point2[1] - point1[1],
      point2[2] - point1[2],
    ];

    const length = Math.sqrt(
      baseVector[0] * baseVector[0] +
        baseVector[1] * baseVector[1] +
        baseVector[2] * baseVector[2]
    );

    const up = [0, 1, 0];
    const rightDirection = [
      baseVector[0] / length,
      baseVector[1] / length,
      baseVector[2] / length,
    ];

    const rawDir = crossProduct(rightDirection, up);
    const dir = [rawDir[0], rawDir[1], rawDir[2]];

    const position = [
      (point1[0] + point2[0]) / 2,
      (point1[1] + point2[1]) / 2,
      (point1[2] + point2[2]) / 2,
    ];

    const labelPosition = [
      (point1[0] + point2[0]) / 2,
      (point1[1] + point2[1]) / 2 + length / 2,
      (point1[2] + point2[2]) / 2,
    ];

    const gridPlane = new ImagePlane(props.viewer.scene, {
      id: `${girdId}`,
      src: Grid,
      visible: true,
      gridVisible: true,
      size: length,
      position: position,
      up: up,
      dir: dir,
      opacity: 1,
      color: [0.9, 0.9, 1.0],
    });
    console.log("normal", gridPlane);
    console.log("normal", up);

    const label = props.annotationPlugin.createAnnotation({
      id: `${girdId}`,
      worldPos: labelPosition,
      occludable: true,
      markerShown: true,
      labelShown: true,
      values: {
        glyph: `Grid`,
        title: "Grid",
        description: `This is my Grid${gridData.Name}.`,
      },
    });

    console.log("dir", dir);

    setActiveGrids([
      ...activeGrids,
      {
        id: girdId,
        gridPlane: gridPlane,
        opacity: 0.5,
        size: length,
        label: label,
      },
    ]);
  };

  const handleLevelVisible = (levelId, levelData) => {
    let flag = false;
    console.log("active", activeLevels, levelId);
    activeLevels.forEach((activeLevel, index) => {
      if (activeLevel.id === levelId) {
        activeLevel.levelPlane.visible = !activeLevel.levelPlane.visible;
        activeLevel.label.setMarkerShown(!activeLevel.label._markerShown);
        activeLevel.label.setLabelShown(!activeLevel.label._labelShown);
        flag = true;
        return;
      }
    });
    if (flag) return;
    // Parse min and max points
    const minPoint = levelData.Positionmin.split(",").map(Number);
    const maxPoint = levelData.Positionmax.split(",").map(Number);

    // Calculate center position
    const position = [
      (minPoint[0] + maxPoint[0]) / 2,
      (minPoint[2] + maxPoint[2]) / 2,
      (minPoint[1] + maxPoint[1]) / 2,
    ];

    // Fixed vectors for horizontal plane
    const up = [0, 0, 1];
    const dir = [0, 1, 0];

    // Calculate size based on min/max points
    const width = Math.abs(maxPoint[0] - minPoint[0]);
    const length = Math.abs(maxPoint[2] - minPoint[2]);
    const size = Math.max(width, length);

    console.log("Creating level plane with:", {
      position,
      size,
      minPoint,
      maxPoint,
    });

    const labelPosition = [
      (minPoint[0] + maxPoint[0]) / 2 + size / 2,
      (minPoint[2] + maxPoint[2]) / 2,
      (minPoint[1] + maxPoint[1]) / 2,
    ];
    console.log("labelPosition", (minPoint[2] + maxPoint[2]) / 2);
    const levelPlane = new ImagePlane(props.viewer.scene, {
      id: `${levelId}-1`,
      src: Grid,
      visible: true,
      gridVisible: true,
      size: size,
      position: position,
      up: up,
      dir: dir,
      opacity: 1,
      color: [0.9, 0.9, 1.0],
    });

    const label = props.annotationPlugin.createAnnotation({
      id: `${levelId}`,
      worldPos: labelPosition,
      occludable: true,
      markerShown: true,
      labelShown: true,
      values: {
        glyph: `level`,
        title: "Level",
        description: `This is my Level - ${levelData.Name}.`,
      },
    });

    setActiveLevels([
      ...activeLevels,
      {
        id: levelId,
        levelPlane: levelPlane,
        opacity: 0.5,
        originSize: size,
        label: label,
        size: 1,
      },
    ]);
  };

  const onChangeLevelOpacity = (e) => {
    console.log("clickedLevel", clickedLevelPlane);
    clickedLevelPlane.levelPlane.opacity = e.target.value;
    setClickedLevelPlane({ ...clickedLevelPlane, opacity: e.target.value });
  };

  const onChangeLevelSize = (e) => {
    console.log("clickeed", e.target.value);

    const temp = {
      id: clickedLevelPlane.levelPlane.id,
      src: clickedLevelPlane.levelPlane.src,
      visible: clickedLevelPlane.levelPlane.visible,
      gridVisible: clickedLevelPlane.levelPlane.gridVisible,
      size: clickedLevelPlane.levelPlane.size,
      position: clickedLevelPlane.levelPlane.position,
      up: clickedLevelPlane.levelPlane.up,
      dir: clickedLevelPlane.levelPlane.dir,
      opacity: clickedLevelPlane.levelPlane.opacity,
      color: clickedLevelPlane.levelPlane.color,
    };
    clickedLevelPlane.levelPlane.destroy();

    const levelPlane = new ImagePlane(props.viewer.scene, {
      id: temp.id,
      src: temp.src,
      visible: temp.visible,
      gridVisible: temp.gridVisible,
      size: clickedLevelPlane.originSize * e.target.value,
      position: temp.position,
      up: temp.up,
      dir: temp.dir,
      opacity: temp.opacity,
      color: temp.color,
    });

    setClickedLevelPlane({
      ...clickedLevelPlane,
      levelPlane: levelPlane,
      size: e.target.value,
    });

    activeLevels.map((activeLevel, index) => {
      if (activeLevel.id === temp.id) {
        activeLevel.imagePlane = levelPlane;
        activeLevel.opacity = temp.opacity;
        activeLevel.size = e.target.value;
      }
    });

    // const originSize = clickedLevelPlane.levelPlane.position;
    // clickedLevelPlane.levelPlane.position = originSize + 1;

    // const oldPosition = clickedLevelPlane.levelPlane.position;
    // const dir = clickedLevelPlane.levelPlane.dir;

    // clickedLevelPlane.levelPlane.position = [
    //   e.target.value > 0
    //     ? oldPosition[0] + 0.5 * dir[0]
    //     : oldPosition[0] - 0.5 * dir[0],
    //   e.target.value > 0
    //     ? oldPosition[1] + 0.5 * dir[1]
    //     : oldPosition[1] - 0.5 * dir[1],
    //   e.target.value > 0
    //     ? oldPosition[2] + 0.5 * dir[2]
    //     : oldPosition[2] - 0.5 * dir[2],
    // ];

    // clickedLevelPlane.levelPlane.size = 500;
    // setClickedLevelPlane({ ...clickedLevelPlane, size: e.target.value });
  };

  const onDeleteActiveView = (deleteIndex) => {
    if (activeViews[deleteIndex].imageVisible)
      activeViews[deleteIndex].imagePlane.destroy();
    setActiveViews(activeViews.filter((_, index) => index !== deleteIndex));
  };
  return (
    <LSideBar
      isToggle={props.imagePlaneTabStatus}
      handleToggle={closeTab}
      header={"2D Overlay"}
    >
      <div className="imagePlane-btn-container">
        <div className="imagePlane-btn-add">
          <Button buttonName={"Add 2D"} onClick={() => onAddImagePlane()} />
        </div>
        <div className="imagePlane-btn-dropdown">
          <Dropdown
            value={selectedImagePlane}
            optionArray={originalNames}
            onChange={(event) => handleDropDwon(event)}
            onClick={() => {}}
          />
        </div>
      </div>
      {/* {showList?.map((list, index) => (
        <button
          className="imagePlane-button "
          type="button"
          onClick={() => handleButton(list.filename)}
        >
          {list.originalname}
        </button>
      ))} */}

      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      />

      <div className="slider-container">
        <div className="slider-label">Opacity</div>
        <input
          className="slider"
          type="range"
          id="opacity"
          name="opacity"
          value={clickedImagePlane?.opacity || 0.5}
          onChange={(e) => onChangeOpacity(e)}
          min="0"
          max="1"
          step={0.1}
        />
        <div className="slider-value" id="sliderValue">
          {clickedImagePlane?.opacity}
        </div>
      </div>

      <div className="slider-container">
        <div className="slider-label">Offset</div>
        {/* <input
          className="slider"
          type="range"
          id="offset"
          name="offset"
          value={clickedImagePlane?.position}
          onChange={(e) => onChangeAxisZ(e)}
          min="-50"
          max="80"
          step={0.1}
        /> */}
        <input
          className="mycustom-button"
          value={offset}
          onChange={(e) => {
            setOffset(e.target.value);
          }}
          style={{ width: "60%" }}
          type="number"
          min="-50"
          max="50"
          placeholder="Please input between -50 and 50"
        />
        <div className="slider-value" id="sliderValue">
          {clickedImagePlane?.offset || 0}
        </div>
      </div>
      <div className="slider-container">
        <div className="imagePlane-apply-btn">
          <Button buttonName={"apply"} onClick={(e) => onChangeAxisZ(e)} />
        </div>
      </div>

      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      />

      <div className="imagePlane-activeView-container">
        <div>Active Views</div>
        {activeViews.map((activeView, index) => (
          <div className="imagePlane-activeView" key={index}>
            <div className="imagePlane-activeView-checkbox">
              <CheckBox
                checked={imageVisible}
                onChange={() => handleImageVisible(activeView.id, index)}
              />
            </div>
            <div className="imagePlane-activeView-input">
              <input
                className="mycustom-button"
                value={activeView.originalName}
                readOnly
              />
            </div>
            <div
              className="imagePlane-activeView-delete"
              onClick={() => onDeleteActiveView(index)}
            >
              X
            </div>
          </div>
        ))}
      </div>

      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      />
      <div className="imagePlane-activeView-container">
        <div>Grid Views</div>
        {gridEntries?.map(([gridId, gridData], index) => (
          <div className="imagePlane-activeView" key={index}>
            <div className="imagePlane-activeView-checkbox">
              <CheckBox
                checked={imageVisible}
                onChange={() => handleGridVisible(gridId, gridData)}
              />
            </div>
            <div className="imagePlane-activeView-input">
              <input
                className="mycustom-button"
                value={gridData.Name}
                readOnly
              />
            </div>
          </div>
        ))}
      </div>
      {/* 
      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      />

      <div className="slider-container">
        <div className="slider-label">Opacity</div>
        <input
          className="slider"
          type="range"
          id="opacity"
          name="opacity"
          value={clickedLevelPlane?.opacity || 0.5}
          onChange={(e) => onChangeLevelOpacity(e)}
          min="0"
          max="1"
          step={0.1}
        />
        <div className="slider-value" id="sliderValue">
          {clickedLevelPlane?.opacity}
        </div>
      </div>

      <div className="slider-container">
        <div className="slider-label">Size</div>
        <input
          className="slider"
          type="range"
          id="offset"
          name="offset"
          value={clickedLevelPlane?.size}
          onChange={(e) => onChangeLevelSize(e)}
          min="0.1"
          max="2"
          step={0.1}
        />
        <div className="slider-value" id="sliderValue">
          {clickedLevelPlane?.size || 1}
        </div>
      </div>

      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      /> */}

      <div
        className="seperate-line"
        style={{
          height: "5px",
          margin: "15px 0px 15px 0px",
          backgroundColor: "#444444",
        }}
      />

      <div className="imagePlane-activeView-container">
        <div>Level Views</div>
        {levelEntries?.map(([levelId, levelData], index) => (
          <div className="imagePlane-activeView" key={index}>
            <div className="imagePlane-activeView-checkbox">
              <CheckBox
                checked={imageVisible}
                onChange={() => handleLevelVisible(levelId, levelData)}
              />
            </div>
            <div className="imagePlane-activeView-input">
              <input
                className="mycustom-button"
                value={levelData.Name}
                readOnly
              />
            </div>
          </div>
        ))}
      </div>
    </LSideBar>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
    selectedEntityId: state.xeokitReducer.selectedEntityId,
    imagePlaneTabStatus: state.ImagePlaneReducer.imagePlaneTabStatus,
    projectId: state.ProjectReducer.projectId,
    metaData: state.ProjectReducer.metaData,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setImagePlaneStatusAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagePlaneTab);
