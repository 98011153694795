import { Constants } from "../constant/constants";

const initialState = {
  isSearchOpen: false,
  searchSaveList: [],
  searchSaveListFlag: false,
};
export function SearchSetsReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.HANDLE_SEARCH: {
      return { ...state, isSearchOpen: !state.isSearchOpen };
    }
    case Constants.SAVE_SEARCH_LIST: {
      let flag;
      if (state.searchSaveListFlag) {
        flag = false;
      } else flag = true;
      return {
        ...state,
        searchSaveList: action.payload,
        searchSaveListFlag: flag,
      };
    }

    default:
      return state;
  }
}
