// Calculate control points for resizing
export const calculateControlPoints = (annotation) => {
  const { width, height } = annotation;
  return [
    { x: 0, y: 0 }, // Top-left
    { x: width/2, y: 0 }, // Top-middle
    { x: width, y: 0 }, // Top-right
    { x: 0, y: height/2 }, // Middle-left
    { x: width, y: height/2 }, // Middle-right
    { x: 0, y: height }, // Bottom-left
    { x: width/2, y: height }, // Bottom-middle
    { x: width, y: height }, // Bottom-right
  ];
};

// Find nearest control point for callout arrow connection
export const findNearestControlPoint = (annotation, point) => {
  const controlPoints = calculateControlPoints(annotation);
  let nearestIndex = 0;
  let minDistance = Number.MAX_VALUE;

  controlPoints.forEach((cp, index) => {
    const dx = (annotation.x + cp.x) - point.x;
    const dy = (annotation.y + cp.y) - point.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    if (distance < minDistance) {
      minDistance = distance;
      nearestIndex = index;
    }
  });

  return nearestIndex;
};

// Render textbox with text wrapping
export const renderTextBox = (context, annotation, scale = 2) => {
  const x = annotation.x * scale;
  const y = annotation.y * scale;
  const width = annotation.width * scale;
  const height = annotation.height * scale;

  // Draw the box
  context.save();
  context.fillStyle = 'white';
  context.strokeStyle = 'black';
  context.lineWidth = 2;
  context.fillRect(x, y, width, height);
  context.strokeRect(x, y, width, height);

  // Draw text
  context.fillStyle = 'black';
  context.font = `${14 * scale}px Arial`;
  context.textBaseline = 'middle';
  const textY = y + height/2;
  const maxWidth = width * 0.9;
  
  // Handle text wrapping
  const words = annotation.text.split(' ');
  let line = '';
  let posY = textY - 10 * scale;
  
  words.forEach(word => {
    const testLine = line + word + ' ';
    const metrics = context.measureText(testLine);
    if (metrics.width > maxWidth) {
      context.fillText(line, x + 5 * scale, posY);
      line = word + ' ';
      posY += 15 * scale;
    } else {
      line = testLine;
    }
  });
  context.fillText(line, x + 5 * scale, posY);
  context.restore();
};

// Render callout with arrow
export const renderCallout = (context, annotation, scale = 2) => {
  const x = annotation.x * scale;
  const y = annotation.y * scale;
  const width = annotation.width * scale;
  const height = annotation.height * scale;

  context.save();
  context.strokeStyle = 'black';
  context.lineWidth = 2;

  // Draw the arrow first
  const connectedPoint = calculateControlPoints(annotation)[annotation.connectedControlPoint];
  const connectionX = x + connectedPoint.x * scale;
  const connectionY = y + connectedPoint.y * scale;

  // Draw the main arrow line
  context.beginPath();
  context.moveTo(connectionX, connectionY);
  context.lineTo(annotation.arrowStart.x * scale, annotation.arrowStart.y * scale);
  context.lineTo(annotation.arrowEnd.x * scale, annotation.arrowEnd.y * scale);
  context.stroke();

  // Draw arrowhead
  const headLength = 15 * scale;
  const angle = Math.atan2(
    annotation.arrowEnd.y - annotation.arrowStart.y,
    annotation.arrowEnd.x - annotation.arrowStart.x
  );

  context.beginPath();
  context.moveTo(annotation.arrowEnd.x * scale, annotation.arrowEnd.y * scale);
  context.lineTo(
    annotation.arrowEnd.x * scale - headLength * Math.cos(angle - Math.PI / 6),
    annotation.arrowEnd.y * scale - headLength * Math.sin(angle - Math.PI / 6)
  );
  context.moveTo(annotation.arrowEnd.x * scale, annotation.arrowEnd.y * scale);
  context.lineTo(
    annotation.arrowEnd.x * scale - headLength * Math.cos(angle + Math.PI / 6),
    annotation.arrowEnd.y * scale - headLength * Math.sin(angle + Math.PI / 6)
  );
  context.stroke();

  // Draw the textbox on top of the arrow
  renderTextBox(context, annotation, scale);
  context.restore();
}; 