import { Constants } from "../constant/constants";

const initialState = {
  actionBuffer: [],
};
export function ActionBufferReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SAVE_ACTION_BUFFER: {
      // console.log("camera momento", action.payload);
      // const tempBuffer = state.actionBuffer;
      // console.log("tempBUffer", tempBuffer);
      // const temp = [
      //   ...tempBuffer,
      //   {
      //     actionName: "camera",
      //     actionValue: action.payload,
      //   },
      // ];
      // console.log("temp", temp);
      console.log("action", action.payload);
      return {
        ...state,
        actionBuffer: [
          ...state.actionBuffer,
          {
            actionName: "camera",
            actionValue: action.payload,
          },
        ],
      };
    }
    default:
      return state;
  }
}
