import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Modal from "../../components/Modal/Modal";
import Canvas from "../../components/Canvas/CustomCanvas";
import { Button, CustomDate } from "../../components/Button/Button";

import { markupUrl, BaseURL, apiHeader } from "../../config/apiUrl";
import { Put, Patch, Post } from "../../axios/axios";
import { CustomToast } from "../../utlity/toastify";
import "./DetailTab.css";

import {
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
  handleLoadingAction,
  handleIssueUpdate,
  setMarkupAnnotationId,
  handleMarkupUpdate,
} from "../../action/xeokitAction";

import Default from "../../asset/default.png";
import Edit from "../../asset/edit.svg";
import Box from "../../components/Box/Box";

const assigneeArray = [
  { name: "Alexander Salna", company: "Northrop Consulting Engineers" },
  { name: "Adrian Wilkins", company: "Dina Group" },
  { name: "Alex Moulis", company: "Moir Landscrap Architecture" },
  { name: "Alexandra Grech", company: "Moir Landscrap Architecture" },
  { name: "Alice Edwards", company: "Dina Group" },
  { name: "Andrew Parker", company: "Northrop Consulting Engineers" },
];

const DetailTab = ({
  detailTabData,
  saveUpdatedIssue,
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
  IssuesArray,
  token,
  handleIssueUpdate,
  updateDetailTabData,
  setMarkupAnnotationId,
  handleMarkupUpdate,
  viewer,
}) => {
  const [details, setDetails] = useState({});
  const [updateData, setUpdateData] = useState([]);
  const [updateAssignee, setUpdateAssignee] = useState([]);
  const [commandData, setCommandData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [activeEdit, setActiveEdit] = useState(null);

  const titleRef = useRef(null);
  const stampRef = useRef(null);
  const statusRef = useRef(null);
  const typeRef = useRef(null);
  const assigneeRef = useRef(null);

  const [markupImage, setMarkupImage] = useState(null);
  useEffect(() => {
    console.log("detailTabData", detailTabData);
    setDetails({ ...detailTabData });
  }, [detailTabData]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     Object.keys(editOptionRefs.current).forEach((key) => {
  //       if (
  //         activeEdit === key &&
  //         editOptionRefs.current[key] &&
  //         !editOptionRefs.current[key].contains(event.target)
  //       ) {
  //         setActiveEdit(null);
  //       }
  //     });
  //   };
  //   if (activeEdit) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  //   return () => document.removeEventListener("mousedown", handleClickOutside);
  // }, [activeEdit]);

  const toggleModal = async () => {
    setIsModalOpen((prev) => !prev);
    const url = markupUrl(details.markUp);
    handleLoadingAction(true);
    const response = await axios.get(url, { responseType: "blob" });
    if (response !== undefined) {
      console.log("response", response);

      setMarkupImage(response.data);
    }
    handleLoadingAction(false);
  };

  // const saveIssue = (key, value) => {
  //   saveUpdatedIssue(tempDetailTabData.id, key, value);
  //   setTempDetailTabData((prev) => ({ ...prev, [key]: value }));
  //   setActiveEdit(null);
  // };

  const [titleInput, setTitleInput] = useState(null);

  // const [details, setDetails] = useState({
  //   markUp: null,
  //   title: null,
  //   stamp: null,
  //   status: null,
  //   type: null,
  //   assignee: null,
  //   dueDate: "2022-01-01",
  //   startDate: "2022-01-01",
  // });

  const [titleBox, setTitleBox] = useState({
    isOpen: false,
    top: 0,
    right: 0,
    width: "70%",
    height: 100,
    offset: 35,
  });

  const [stampBox, setStampBox] = useState({
    isOpen: false,
    top: 0,
    right: 0,
    rowData: null,
    width: "70%",
    height: 100,
    offset: 40,
    options: ["OME", "1AR"],
  });

  const [statusBox, setStatusBox] = useState({
    isOpen: false,
    top: 0,
    right: 0,
    rowData: null,
    width: "70%",
    height: 130,
    offset: 40,
    options: ["Solved", "Closed", "InProgress", "Open"],
  });

  const [typeBox, setTypeBox] = useState({
    isOpen: false,
    top: 0,
    right: 0,
    rowData: null,
    width: "70%",
    height: 100,
    offset: 40,
    options: ["Standard Issue", "Other Issue"],
  });

  const [assigneeBox, setAssigneeBox] = useState({
    isOpen: false,
    top: 0,
    right: 0,
    rowData: null,
    width: "70%",
    height: 150,
    offset: 40,
    options: assigneeArray,
  });

  // Handler to update the state when the date changes
  const handleDateChange = (event, type) => {
    type === "startDate"
      ? setUpdateData([
          ...updateData,
          {
            field: "startDate",
            oldValue: details.startDate,
            newValue: event.target.value,
          },
        ])
      : setUpdateData([
          ...updateData,
          {
            field: "dueDate",
            oldValue: details.dueDate,
            newValue: event.target.value,
          },
        ]);

    type === "startDate"
      ? setDetails({ ...details, startDate: event.target.value })
      : setDetails({ ...details, dueDate: event.target.value });
  };

  const handleClickOutSide = (event) => {
    if (titleRef.current && !titleRef.current.contains(event.target)) {
      setTitleBox({ ...titleBox, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutSide);
    }

    if (stampRef.current && !stampRef.current.contains(event.target)) {
      setStampBox({ ...stampBox, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutSide);
    }

    if (statusRef.current && !statusRef.current.contains(event.target)) {
      setStatusBox({ ...statusBox, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutSide);
    }

    if (typeRef.current && !typeRef.current.contains(event.target)) {
      setTypeBox({ ...typeBox, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutSide);
    }

    if (assigneeRef.current && !assigneeRef.current.contains(event.target)) {
      setAssigneeBox({ ...assigneeBox, isOpen: false });
      document.removeEventListener("mousedown", handleClickOutSide);
    }
  };

  const handleBox = (event, boxRef, boxData, setBoxData) => {
    console.log(event.clientX, event.clientY);
    const parentRect = event.currentTarget.getBoundingClientRect();
    const boxHeight = boxRef.current
      ? boxRef.current.offsetHeight
      : boxData.height;
    const viewportHeight = window.innerHeight;
    console.log("parenttop", parentRect.top);
    console.log("boxHeight", boxHeight);
    const adjustedTop =
      parentRect.top - boxData.offset + boxHeight > viewportHeight
        ? 0 - boxData.offset - boxHeight
        : 0 + boxData.offset;
    console.log("adjuect", adjustedTop);
    setBoxData({
      ...boxData,
      isOpen: true,
      top: adjustedTop,
      left: 0,
    });
    document.addEventListener("mousedown", handleClickOutSide);
  };

  const handleTitle = () => {
    setUpdateData([
      ...updateData,
      { field: "title", oldValue: details.title, newValue: titleInput },
    ]);
    setDetails({ ...details, title: titleInput });
  };

  const handleStamp = (option) => {
    setUpdateData([
      ...updateData,
      { field: "stamp", oldValue: details.stamp, newValue: option },
    ]);
    setDetails({ ...details, stamp: option });
  };

  const handleStatus = (option) => {
    setUpdateData([
      ...updateData,
      { field: "status", oldValue: details.status, newValue: option },
    ]);
    setDetails({ ...details, status: option });
  };

  const handleType = (option) => {
    setUpdateData([
      ...updateData,
      { field: "type", oldValue: details.type, newValue: option },
    ]);
    setDetails({ ...details, type: option });
  };

  const handleAssignee = (option) => {
    // setUpdateData([
    //   ...updateData,
    //   { field: "assignee", oldValue: details.assignee, newValue: option },
    // ]);
    setUpdateAssignee([...updateAssignee, option]);
    setDetails({ ...details, assignee: [...details.assignee, option] });
  };
  const handleCommandChange = (e) => setCommandData(e.target.value);

  // const handleCommandSave = () => saveIssue("comment", commandData);

  const handleEdit = (key) => setActiveEdit(key);

  // const renderEditOptions = (key, options) => (
  //   <div
  //     className={`edit-option ${
  //       activeEdit === key ? "edit-option-show" : "edit-option-hide"
  //     }`}
  //     ref={(el) => (editOptionRefs.current[key] = el)}
  //   >
  //     {options}
  //   </div>
  // );

  const onClickUpdateScreenBtn = () => {
    setIssueTrackStatusAction(false);
    setIsImageEditorOpen(false);
    handleMarkupUpdate(true);
    setMarkupAnnotationId(details.id);
    viewer.camera.eye = [
      details.camera.eye[0],
      details.camera.eye[1],
      details.camera.eye[2],
    ];
    viewer.camera.look = [
      details.camera.look[0],
      details.camera.look[1],
      details.camera.look[2],
    ];
    viewer.camera.up = [
      details.camera.up[0],
      details.camera.up[1],
      details.camera.up[2],
    ];
  };

  const onClickMarkUpBtn = () => {
    toggleModal();
    setIsImageEditorOpen(true);
    // setUpdateScreenIssueIdAction(tempDetailTabData.id - 1);
  };

  const handleApply = async () => {
    handleLoadingAction(true);
    const url = BaseURL(`annotations/${details.id}`);
    const params = {
      activityLog: {
        changedData: updateData,
        assignee: updateAssignee,
      },
      annotation: details,
    };
    console.log("paraa", params);
    const response = await Patch(url, params, apiHeader(token));
    if (response !== undefined) {
      CustomToast("Updated Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    updateDetailTabData(details, "others");
    handleIssueUpdate(true);
  };

  const updateMarkup = async (updatedMarkup) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    console.log("updateMarkup", updatedMarkup);
    // Convert the base64 image to a Blob
    const base64Data = updatedMarkup.replace(/^data:image\/png;base64,/, ""); // Remove base64 header
    const blob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      {
        type: "image/png",
      }
    );

    console.log("formatted", typeof formattedDate);
    // console.log("fileUploader", fileUploader);
    handleLoadingAction(true);
    const url = BaseURL("annotations/markup");

    let formData = new FormData();

    formData.append("markup", blob, `issue-${formattedDate}.png`);
    formData.append("annotation", details.id);

    console.log("formData", formData);
    const response = await Post(url, formData, apiHeader(token, true));
    if (response !== undefined) {
      console.log("markup response >>>>>>>>>>>>>>>>>", response);
      setDetails({ ...details, markUp: response.data.fileName });
      updateDetailTabData(response.data.fileName, "markUp");
      handleIssueUpdate(true);
      CustomToast("Updated Succeessfully!", "affirmative");
    }
    handleLoadingAction(false);
    setIsImageEditorOpen(false);
  };
  return (
    <div className="detail-tab">
      {details && (
        <>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">MarkUp:</div>
            <div className="detail-tab-list-content">
              <div className="detail-tab-list-markup">
                <div style={{ width: "80%", height: "auto" }}>
                  <img
                    src={details.markUp ? markupUrl(details.markUp) : Default}
                    width="80%"
                    height="80%"
                    alt="markup"
                    crossOrigin="anonymous"
                    className="detail-markup-img"
                  />
                </div>
                <div>
                  <Button buttonName={"View markup"} onClick={toggleModal} />
                </div>
              </div>
            </div>
          </div>

          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Title:</div>
            <div className="detail-tab-list-content">
              <div>{details.title}</div>
              <div
                onClick={(event) =>
                  handleBox(event, titleRef, titleBox, setTitleBox)
                }
              >
                <img src={Edit} alt="edit" className="detail-tab-list-edit" />
              </div>
            </div>
            <Box
              isToggle={titleBox.isOpen}
              style={{
                width: titleBox.width,
                height: titleBox.height,
                position: "absolute",
                top: titleBox.top,
                right: titleBox.right,
              }}
              ref={titleRef}
            >
              <div className="detail-tab-title">
                <div className="detail-tab-title-input">
                  <input
                    className="mycustom-button"
                    style={{ height: "20px" }}
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)}
                  />
                </div>
                <div>
                  <Button buttonName={"Apply"} onClick={() => handleTitle()} />
                </div>
              </div>
            </Box>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Stamp:</div>
            <div className="detail-tab-list-content">
              <div className={`issue-track-stamp-${details.stamp}`}>
                {details.stamp}
              </div>
              <div
                onClick={(event) =>
                  handleBox(event, stampRef, stampBox, setStampBox)
                }
              >
                <img src={Edit} alt="edit" className="detail-tab-list-edit" />
              </div>
            </div>
            <Box
              isToggle={stampBox.isOpen}
              style={{
                width: stampBox.width,
                height: stampBox.height,
                position: "absolute",
                top: stampBox.top,
                right: stampBox.right,
              }}
              ref={stampRef}
            >
              <div className="detail-tab-stamp">
                {stampBox.options.map((option, index) => (
                  <div
                    key={index}
                    className={`issue-track-stamp-${option}`}
                    onClick={() => handleStamp(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </Box>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Status:</div>
            <div className="detail-tab-list-content">
              <div className={`issue-track-status-${details.status}`}></div>
              <div className="issue-track-status-content">{details.status}</div>
              <div
                onClick={(event) =>
                  handleBox(event, statusRef, statusBox, setStatusBox)
                }
              >
                <img src={Edit} alt="edit" className="detail-tab-list-edit" />
              </div>
            </div>
            <Box
              isToggle={statusBox.isOpen}
              style={{
                width: statusBox.width,
                height: statusBox.height,
                position: "absolute",
                top: statusBox.top,
                right: statusBox.right,
              }}
              ref={statusRef}
            >
              <div className="detail-tab-status">
                {statusBox.options.map((option, index) => (
                  <div
                    className="issue-track-status-container"
                    onClick={() => handleStatus(option)}
                    key={index}
                  >
                    <div className={`issue-track-status-${option}`}></div>
                    <div className="issue-track-status-content">{option}</div>
                  </div>
                ))}
              </div>
            </Box>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Type:</div>
            <div className="detail-tab-list-content">
              <div>{details.type}</div>
              <div
                onClick={(event) =>
                  handleBox(event, typeRef, typeBox, setTypeBox)
                }
              >
                <img src={Edit} alt="edit" className="detail-tab-list-edit" />
              </div>
            </div>
            <Box
              isToggle={typeBox.isOpen}
              style={{
                width: typeBox.width,
                height: typeBox.height,
                position: "absolute",
                top: typeBox.top,
                right: typeBox.right,
              }}
              ref={typeRef}
            >
              <div className="detail-tab-type">
                {typeBox.options.map((option, index) => (
                  <div key={index} onClick={() => handleType(option)}>
                    {option}
                  </div>
                ))}
              </div>
            </Box>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Assigned to:</div>
            <div className="detail-tab-list-content">
              <div className="detail-tab-list-assignee-container">
                {details.assignee?.map((item, index) => (
                  <div className="detail-tab-list-assignee" key={index}>
                    <div>{item.name}</div>
                    <div>
                      <i>-{item.company}</i>
                    </div>
                  </div>
                ))}
              </div>
              <div
                onClick={(event) =>
                  handleBox(event, assigneeRef, assigneeBox, setAssigneeBox)
                }
              >
                <img src={Edit} alt="edit" className="detail-tab-list-edit" />
              </div>
            </div>
            <Box
              isToggle={assigneeBox.isOpen}
              style={{
                width: assigneeBox.width,
                height: assigneeBox.height,
                position: "absolute",
                top: assigneeBox.top,
                right: assigneeBox.right,
              }}
              ref={assigneeRef}
            >
              <div className="detail-tab-assignee">
                <div className="detail-tab-assign-container">
                  {assigneeBox.options.map((option, index) => (
                    <div
                      className="detail-tab-assign-group"
                      key={index}
                      onClick={() => handleAssignee(option)}
                    >
                      <div>{option.name}</div>
                      <div>
                        -<i>{option.company}</i>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Due Date</div>
            <div className="detail-tab-list-content">
              <CustomDate
                date={details.dueDate}
                handleDateChange={(event) => handleDateChange(event, "dueDate")}
              />
            </div>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-list-name">Start Date</div>
            <div className="detail-tab-list-content">
              <CustomDate
                date={details.startDate}
                handleDateChange={(event) =>
                  handleDateChange(event, "startDate")
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="detail-tab-list">
            <div className="detail-tab-btn">
              <Button buttonName={"Apply"} onClick={() => handleApply()} />
            </div>
          </div>
          {/* 
          <DetailRow
            label="Title"
            content={tempdetails.title}
            onEdit={() => handleEdit("title")}
            editOptions={renderEditOptions(
              "title",
              <>
                <input
                  className="title-input"
                  value={tempdetails.title || ""}
                  onChange={(e) => saveIssue("title", e.target.value)}
                />
                <ActionButtons
                  onApply={() => saveIssue("title", tempdetails.title)}
                  onCancel={() => setActiveEdit(null)}
                />
              </>
            )}
          />

          <DetailRow
            label="Status"
            content={tempdetails.status}
            onEdit={() => handleEdit("status")}
            editOptions={renderEditOptions(
              "status",
              ["open", "in progress", "solved", "closed"].map((status) => (
                <Badge
                  key={status}
                  status={status}
                  onClick={() => saveIssue("status", status)}
                />
              ))
            )}
          />

          <DetailRow
            label="Description"
            content={tempdetails.description}
            onEdit={() => handleEdit("description")}
          />

          <DetailRow
            label="Assigned to"
            content={tempdetails.assignee}
            onEdit={() => handleEdit("assignee")}
            editOptions={renderEditOptions(
              "assignee",
              assigneeArray.map(({ name, company }) => (
                <div
                  key={name}
                  className="assignee-option"
                  onClick={() => saveIssue("assignee", name)}
                >
                  <div className="assignee-option-name">{name}</div>
                  <div className="assignee-option-company">{company}</div>
                </div>
              ))
            )}
          /> */}

          {/* <div className="detail-tab-row">
            <div className="detail-tab-row-title">
              <div>Comments</div>
            </div>
            <div className="detail-tab-row-comment-content">
              <textarea
                className="detail-tab-row-comment-text"
                value={commandData}
                onChange={handleCommandChange}
              ></textarea>
              <div>
                <button className="custom-button" onClick={handleCommandSave}>
                  Leave Comment
                </button>
              </div>
            </div>
          </div> */}
        </>
      )}

      {/* Modal */}
      <Modal
        isToggle={isModalOpen}
        handleToggle={toggleModal}
        header={"Mark Up"}
        height="calc(80vh)"
        width="calc(80vw)"
      >
        <div className="detail-markup-container">
          <div className="detail-markup-modal">
            <div className="detail-markup-modal-header">
              <Button
                buttonName={"Update ScreenShot"}
                className="custom-button"
                onClick={() => onClickUpdateScreenBtn()}
              />

              <Button
                buttonName={"Mark Up"}
                onClick={() => onClickMarkUpBtn()}
              />
            </div>
            <div className="issue-tracker-detail-modal-body">
              <img
                src={markupUrl(details.markUp)}
                alt="Mark Up"
                width="80%"
                height="100%"
                crossOrigin="anonymous"
                style={{
                  border: "2px solid #FF00BA",
                  backgroundColor: "lightblue",
                }}
              ></img>
            </div>
          </div>
        </div>
        {/* <div className="issue-tracker-detail-modal">
          <div className="issue-tracker-detail-modal-header">
            <label>Mark Up</label>
            <ActionButtons
              onApply={() => saveIssue("markUp", tempdetails.markUp)}
              onCancel={toggleModal}
            />
          </div>
          <img
            src={tempdetails.markUp}
            alt="Mark Up"
            className="modal-image"
          />
        </div> */}
      </Modal>

      {/* Image Editor */}
      {isImageEditorOpen && (
        <div className="image-editor">
          <Canvas
            backgroundImage={markupImage}
            cancel={setIsImageEditorOpen}
            updateMarkup={updateMarkup}
            type={"url"}
          />
        </div>
      )}
    </div>
  );
};

const DetailRow = ({ label, content, onEdit, editOptions }) => (
  <div className="detail-tab-row">
    <div className="detail-tab-row-title">{label}</div>
    <div className="detail-tab-row-content">
      <div>{content}</div>
      {onEdit && <img className="edit-icon" alt="Edit" onClick={onEdit} />}
    </div>
    {editOptions}
  </div>
);

const Badge = ({ status, onClick }) => (
  <div className={`annotation-badge ${status}`} onClick={onClick}>
    {status}
  </div>
);

const ActionButtons = ({ onApply, onCancel }) => (
  <div className="action-buttons">
    <button onClick={onApply}>Apply</button>
    <button onClick={onCancel}>Cancel</button>
  </div>
);

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    IssuesArray: state.IssueTrackReducer.IssuesArray,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
  handleLoadingAction,
  handleIssueUpdate,
  setMarkupAnnotationId,
  handleMarkupUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTab);
