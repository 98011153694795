import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Dropdown from "../../components/DropDown/Dropdown";
import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";

import "./EditSearchTab.css";

import {
  setEditSearchTabStatusAction,
  searchElementAction,
  saveSearchList,
} from "../../action/xeokitAction";

import PNG_SAVE from "../../asset/save.png";

const EditSearchTab = ({
  setEditSearchTabStatus,
  setEditSearchTabStatusAction,
  setEditSearchData,
  searchSaveList,
  saveSearchList,
  properties,
}) => {
  const [searchSetName, setSearchSetName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modelProperties, setModelProperties] = useState([]);
  const [modelProperty, setModelProperty] = useState(null);

  const [modelPropertyValues, setModelPropertyValues] = useState([]);
  const [modelPropertyValue, setModelPropertyValue] = useState();

  useEffect(() => {
    if (!setEditSearchData) return;

    const { modelProperty, modelPropertyValue } = setEditSearchData;
    setModelProperty(modelProperty);
    setModelPropertyValue(modelPropertyValue);

    let tempkey = [];
    let tempValue = [];
    Object.entries(properties).forEach(([key, value]) => {
      tempkey.push(key);
      tempValue.push(value);
    });
    setModelProperties(tempkey);
    setModelPropertyValues(tempValue);
  }, [setEditSearchData]);

  const handleModelPropertyChange = (e) => {
    const selectedProperty = e.target.value;
    setModelProperty(selectedProperty);

    Object.entries(properties).forEach(([key, value]) => {
      if (e.target.value === key) setModelPropertyValue(value);
    });
  };

  const handleSaveSearchSet = () => {
    const updatedSearchList = searchSaveList.map((item) =>
      item.searchSetName === setEditSearchData.searchSetName
        ? {
            ...item,
            searchSetName,
            modelProperty,
            modelPropertyValue,
            modelColor: null,
            modelTransparency: null,
          }
        : item
    );

    saveSearchList(updatedSearchList);
    setIsOpen(false);
  };

  const closeTab = () => setEditSearchTabStatusAction(false);

  return (
    <div className="edit-search-tab">
      <Tab
        isOpen={setEditSearchTabStatus}
        onClose={closeTab}
        tabName={"Edit Search Sets"}
      >
        <div className="searchtab-toolbar">
          <div>
            <img
              className="save-btn"
              src={PNG_SAVE}
              alt="Save"
              onClick={() => setIsOpen(true)}
            />
          </div>
          <Modal isOpen={isOpen}>
            <div>
              <div className="modal-header">
                <label>New Search Set</label>
                <span className="modal-close" onClick={() => setIsOpen(false)}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <div>
                  <h4>SearchSet Name</h4>
                </div>
                <div>
                  <input
                    value={searchSetName}
                    onChange={(e) => setSearchSetName(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="custom-button"
                    type="button"
                    onClick={handleSaveSearchSet}
                  >
                    save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="searchtab-body">
          <label>ModelProperty</label>
          <Dropdown
            optionArray={modelProperties}
            onChangeOption={handleModelPropertyChange}
            defaultValue={modelProperty}
          />
          <label>ModelPropertyValue</label>
          <Dropdown
            optionArray={modelPropertyValues}
            onChangeOption={(e) => setModelPropertyValue(e.target.value)}
            defaultValue={modelPropertyValue}
          />
        </div>
      </Tab>
    </div>
  );
};

const mapStateToProps = (state) => ({
  setEditSearchTabStatus: state.EditSearchReducer.setEditSearchTabStatus,
  setEditSearchData: state.EditSearchReducer.setEditSearchData,
  searchSaveList: state.SearchSetsReducer.searchSaveList,
  properties: state.PropertyReducer.properties,
});

const mapDispatchToProps = {
  setEditSearchTabStatusAction,
  searchElementAction,
  saveSearchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSearchTab);
