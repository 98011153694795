import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  XKTLoaderPlugin,
  Viewer,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";
import Box from "../../components/Box/Box";
import { Button } from "../../components/Button/Button";
import "./Thumbnail.css";
import { modelUrl } from "../../config/apiUrl";

const Thumbnail = (props) => {
  const [newViewer, setNewViewer] = useState(null);
  useEffect(() => {
    if (!props.isToggle) return;
    if (props.viewer === null) return;
    const viewer = new Viewer({
      canvasId: "thumbnail_canvas",
      transparent: true,
    });
    setNewViewer(viewer);
    console.log("Entity", viewer.scene.entity);
    viewer.camera.eye = props.viewer.camera.eye;
    viewer.camera.look = props.viewer.camera.look;
    viewer.camera.up = props.viewer.camera.up;
    // viewer.camera.perspective.fov = 60;
    // viewer.cameraControl.navMode = "firstPerson";
    viewer.cameraControl.followPointer = true;
    viewer.cameraControl.panRightClick = false; // Prevents right-click-drag panning interfering with ContextMenus

    //------------------------------------------------------------------------------------------------------------------
    // Customize CameraControl
    //------------------------------------------------------------------------------------------------------------------

    const cameraControl = viewer.cameraControl;

    cameraControl.navMode = "orbit";
    cameraControl.followPointer = true;

    const pivotElement = document
      .createRange()
      .createContextualFragment(
        "<div class='thumbnail-camera-pivot-marker'></div>"
      ).firstChild;
    document.body.appendChild(pivotElement);
    cameraControl.pivotElement = pivotElement;

    cameraControl.on("picked", (e) => {
      console.log("picked");
    });

    cameraControl.on("doublePicked", (e) => {
      console.log("doublePicked");
    });

    const xktLoader = new XKTLoaderPlugin(viewer);
    // const model = xktLoader.load({
    //   id: "duplex",
    //   src: duplex,
    //   edges: true,
    // });
    const model = xktLoader.load({
      id: "myModel",
      src: modelUrl(props.model),
      edges: true,
    });
  }, [props.isToggle]);

  useEffect(() => {
    if (!props.isToggle) return;
    if (props.viewer === null) return;
    newViewer.camera.eye = props.viewer.camera.eye;
    newViewer.camera.look = props.viewer.camera.look;
    newViewer.camera.up = props.viewer.camera.up;
  }, [props.viewer]);

  function setTransparency(entity, opacity) {
    // console.log("entity", entity);
    entity.opacity = opacity;
  }

  function isEntityIntersectedBySectionPlane(entity, sectionPlane) {
    // console.log("sectionPlane", sectionPlane);
    // console.log("planepos", planePos);
    // console.log("planedir", planeDir);
    const aabb = entity.aabb; // Get the entity's axis-aligned bounding box
    const planePos = sectionPlane.pos;
    const planeDir = sectionPlane.dir;

    // console.log("planepos", planePos);
    // console.log("planedir", planeDir);
    // Check if the AABB intersects with the section plane
    const [px, py, pz] = planePos;
    const [dx, dy, dz] = planeDir;

    // Simple check: If the center of the AABB is on the negative side of the plane
    const centerX = (aabb[0] + aabb[3]) / 2;
    const centerY = (aabb[1] + aabb[4]) / 2;
    const centerZ = (aabb[2] + aabb[5]) / 2;

    const dotProduct =
      (centerX - px) * dx + (centerY - py) * dy + (centerZ - pz) * dz;
    return dotProduct < 0;
  }

  const setOpacity = () => {
    const entities = Object.values(newViewer.scene.objects);

    entities.forEach((entity) => {
      entity.opacity = 1;
    });

    entities.forEach((entity) => {
      Object.values(props.sectionPlanePlugin.sectionPlanes).map(
        (element, i) => {
          if (isEntityIntersectedBySectionPlane(entity, element)) {
            setTransparency(entity, 0.2); // Set 50% transparency
          }
        }
      );
    });
  };
  return (
    <Box
      isToggle={props.isToggle}
      style={{
        width: 200,
        height: 250,
        position: "absolute",
        top: 90,
        right: 150,
        zIndex: 1,
      }}
    >
      <div className="thumbnail">
        <canvas
          id="thumbnail_canvas"
          style={{
            width: "100%",
            height: "100%",
            background: "lightblue",
            backgroundimage: "linear-gradient(lightblue, white)",
            border: "solid 1px black",
          }}
        ></canvas>
        <Button buttonName={"Set Opacity"} onClick={() => setOpacity()} />
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    model: state.ProjectReducer.model,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail);
