import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  apiHeader,
  BaseURL,
  modelUrl,
  recordsLimit,
  imageUrl,
} from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import {
  saveProject,
  saveMetaData,
  setDefaultViewAction,
} from "../../action/xeokitAction";

import "./project.css";

import DEFAULT_IMG from "../../asset/default.png";
import { AuthReducer } from "../../reducer/AuthReducer";

const Project = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `projects?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setProjects(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, []);

  const handleProject = async (project) => {
    const url = BaseURL(`models/latest/projects/${project._id}`);
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("responseModel", response);
      console.log("project", project);

      const jsonUrl = modelUrl(response.data?.json);
      const jsonResponse = await Get(jsonUrl, props.token);
      console.log(jsonResponse);

      if (jsonResponse !== undefined) {
        props.saveProject({
          projectId: project._id,
          projectName: project.name,
          model: response.data?.fileName,
          json: response.data?.json,
          metaData: jsonResponse.data,
        });
        const url = BaseURL(
          `viewers/projects/${project._id}?page=${1}&limit=${100}&search=`
        );

        const viewerResponse = await Get(url, props.token);
        if (viewerResponse !== undefined) {
          console.log("response", viewerResponse);
          viewerResponse.data.map((item) => {
            if (item.defaultView) {
              props.setDefaultViewAction(item.bcf);
            }
          });
          navigate("/");
        }
        // props.saveMetaData(jsonResponse.data);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="project-container">
      {projects.map((project, index) => (
        <div key={index}>
          <div className="project" onClick={(e) => handleProject(project)}>
            <div className="project-background">
              <img
                src={
                  project.thumbnail ? imageUrl(project.thumbnail) : DEFAULT_IMG
                }
                alt="project"
                width={200}
                height={200}
                crossOrigin="anonymous"
              />
            </div>
            <div className="project-name">
              <p>{project.name}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = { saveProject, saveMetaData, setDefaultViewAction };

export default connect(mapStateToProps, mapDispatchToProps)(Project);
