import React from 'react';
import { calculateControlPoints } from '../utils/annotationUtils';

const Callout = ({
  annotation,
  isSelected,
  isDragging,
  isEditing,
  text,
  onSelect,
  onDragStart,
  onControlPointDrag,
  onArrowControlDrag,
  onDoubleClick,
  onTextChange,
  onTextBlur,
  onTextKeyDown
}) => {
  const controlPoints = calculateControlPoints(annotation);
  const connectedPoint = controlPoints[annotation.connectedControlPoint];

  const getControlPointCursor = (cpIndex) => {
    switch(cpIndex) {
      case 0: // Top-left
      case 7: // Bottom-right
        return 'nw-resize';
      case 2: // Top-right
      case 5: // Bottom-left
        return 'ne-resize';
      case 1: // Top-middle
      case 6: // Bottom-middle
        return 'n-resize';
      case 3: // Middle-left
      case 4: // Middle-right
        return 'e-resize';
      default:
        return 'pointer';
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: annotation.x,
        top: annotation.y,
        transform: 'translate(0, 0)',
        pointerEvents: 'all',
        cursor: isSelected ? (isDragging ? 'grabbing' : 'grab') : 'pointer',
        padding: '20px',
        margin: '-20px',
        backgroundColor: 'transparent',
        userSelect: 'none'
      }}
      onClick={onSelect}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isSelected) {
          onDragStart(e);
        } else {
          onSelect(e);
        }
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onDoubleClick();
      }}
    >
      <div
        style={{
          padding: '8px',
          width: annotation.width,
          height: annotation.height,
          backgroundColor: isSelected ? '#e3f2fd' : '#fff',
          border: isSelected ? '2px solid #1976d2' : '1px solid #ddd',
          position: 'relative',
          boxSizing: 'border-box',
          userSelect: 'none',
          pointerEvents: 'all'
        }}
      >
        {isEditing ? (
          <textarea
            value={text}
            onChange={onTextChange}
            onBlur={onTextBlur}
            onKeyDown={onTextKeyDown}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              padding: '0',
              margin: '0',
              backgroundColor: 'transparent',
              resize: 'none',
              outline: 'none',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              overflow: 'hidden'
            }}
            autoFocus
          />
        ) : (
          annotation.text
        )}
        {isSelected && !isEditing && (
          <svg
            style={{
              position: 'absolute',
              left: -4,
              top: -4,
              width: annotation.width + 8,
              height: annotation.height + 8,
              pointerEvents: 'none',
              overflow: 'visible'
            }}
          >
            {controlPoints.map((point, cpIndex) => (
              <g key={cpIndex} style={{ pointerEvents: 'all' }}>
                <circle
                  cx={point.x + 4}
                  cy={point.y + 4}
                  r={4}
                  fill={cpIndex === annotation.connectedControlPoint ? "#1976d2" : "white"}
                  stroke="#1976d2"
                  strokeWidth={2}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    onControlPointDrag(e, cpIndex);
                  }}
                  style={{
                    cursor: getControlPointCursor(cpIndex),
                    pointerEvents: 'all'
                  }}
                />
              </g>
            ))}
          </svg>
        )}
      </div>
      <svg
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'visible',
          pointerEvents: 'none'
        }}
      >
        <defs>
          <marker
            id={`arrowhead-${annotation.id}`}
            markerWidth="10"
            markerHeight="7"
            refX="9"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" fill="black" />
          </marker>
        </defs>
        <g>
          {/* Invisible wider path for easier selection */}
          <path
            d={`M ${connectedPoint.x + 20} ${connectedPoint.y + 20} 
               L ${annotation.arrowStart.x - annotation.x + 20} ${annotation.arrowStart.y - annotation.y + 20}
               L ${annotation.arrowEnd.x - annotation.x + 20} ${annotation.arrowEnd.y - annotation.y + 20}`}
            stroke="transparent"
            strokeWidth="20"
            fill="none"
            style={{ cursor: 'pointer', pointerEvents: 'all' }}
          />
          {/* Visible arrow segments */}
          <path
            d={`M ${connectedPoint.x + 20} ${connectedPoint.y + 20} 
               L ${annotation.arrowStart.x - annotation.x + 20} ${annotation.arrowStart.y - annotation.y + 20}
               L ${annotation.arrowEnd.x - annotation.x + 20} ${annotation.arrowEnd.y - annotation.y + 20}`}
            stroke="black"
            strokeWidth="2"
            fill="none"
            markerEnd={`url(#arrowhead-${annotation.id})`}
            pointerEvents="none"
          />
        </g>
        {isSelected && !isEditing && (
          <>
            <circle
              cx={annotation.arrowStart.x - annotation.x + 20}
              cy={annotation.arrowStart.y - annotation.y + 20}
              r={6}
              fill="white"
              stroke="blue"
              strokeWidth={2}
              onMouseDown={(e) => {
                e.stopPropagation();
                onArrowControlDrag(e, true);
              }}
              style={{ cursor: 'move', pointerEvents: 'all' }}
            />
            <circle
              cx={annotation.arrowEnd.x - annotation.x + 20}
              cy={annotation.arrowEnd.y - annotation.y + 20}
              r={6}
              fill="white"
              stroke="blue"
              strokeWidth={2}
              onMouseDown={(e) => {
                e.stopPropagation();
                onArrowControlDrag(e, false);
              }}
              style={{ cursor: 'move', pointerEvents: 'all' }}
            />
          </>
        )}
      </svg>
    </div>
  );
};

export default Callout; 