// import React, { useRef, useState, useEffect } from "react";
// import { connect } from "react-redux";
// import "./CustomCanvas.css";

// import { Button } from "../Button/Button";
// import Dropdown from "../DropDown/Dropdown";

// import {
//   saveImageDataAction,
//   saveLogArrayAction,
// } from "../../action/xeokitAction";
// import { IconButton } from "../Button/Button";

// const Canvas = ({
//   backgroundImage,
//   saveImageDataAction,
//   saveLogArrayAction,
//   logsArray,
//   updateScreenIssueId,
//   cancel,
//   updateMarkup,
//   type,
// }) => {
//   const canvasRef = useRef(null);
//   const [isDrawing, setIsDrawing] = useState(false);
//   const [tool, setTool] = useState("pen");
//   const [startPoint, setStartPoint] = useState(null);
//   const [lineWidth, setLineWidth] = useState(5);
//   const [color, setColor] = useState("black");
//   const drawingContextRef = useRef(null);
//   const historyContextRef = useRef(null);
//   const backgroundRef = useRef(null);

//   // History state for undo/redo
//   const [history, setHistory] = useState([]);
//   const [historyIndex, setHistoryIndex] = useState(-1);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");
//     initializeCanvas(canvas, context);
//     loadBackgroundImage(backgroundImage, context, type);
//   }, [backgroundImage]);

//   const initializeCanvas = (canvas, context) => {
//     canvas.width = window.innerWidth * 2;
//     canvas.height = window.innerHeight * 2;
//     // canvas.style.width = `${window.innerWidth}px`;
//     // canvas.style.height = `${window.innerHeight}px`;
//     context.scale(2, 2);
//     context.lineCap = "round";

//     drawingContextRef.current = createOffscreenCanvas(canvas);
//     historyContextRef.current = createOffscreenCanvas(canvas);
//   };

//   const createOffscreenCanvas = (canvas) => {
//     const offscreenCanvas = document.createElement("canvas");
//     offscreenCanvas.width = canvas.width;
//     offscreenCanvas.height = canvas.height;
//     return offscreenCanvas.getContext("2d");
//   };

//   const loadBackgroundImage = (src, context, type) => {
//     const image = new Image();
//     if (type === "url") image.src = URL.createObjectURL(src);
//     else image.src = src;
//     image.onload = () => {
//       backgroundRef.current = image;
//       redrawMainCanvas(); // Call redraw to load the background and existing drawings
//     };
//   };

//   const getAdjustedCoordinates = (event) => {
//     const canvas = canvasRef.current;
//     const rect = canvas.getBoundingClientRect(); // Get canvas size and position
//     const scaleX = canvas.width / (rect.width * 2); // Adjust for scaling (context.scale(2, 2))
//     const scaleY = canvas.height / (rect.height * 2);

//     return {
//       x: (event.clientX - rect.left) * scaleX,
//       y: (event.clientY - rect.top) * scaleY,
//     };
//   };

//   const startDrawing = (event) => {
//     const { x, y } = getAdjustedCoordinates(event);
//     setStartPoint({ x, y });
//     setIsDrawing(true);
//     const drawCtx = drawingContextRef.current;
//     drawCtx.strokeStyle = color;
//     drawCtx.lineWidth = lineWidth;

//     if (tool === "pen") {
//       drawCtx.beginPath();
//       drawCtx.moveTo(x, y);
//     }
//   };

//   const finishDrawing = () => {
//     if (!isDrawing) return;
//     setIsDrawing(false);

//     const drawCtx = drawingContextRef.current;
//     const historyCtx = historyContextRef.current;

//     // Preserve the current drawing to the history context
//     historyCtx.drawImage(drawCtx.canvas, 0, 0);
//     drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);

//     // Save the current canvas state for undo/redo
//     const newHistory = history.slice(0, historyIndex + 1);
//     newHistory.push(
//       historyCtx.getImageData(
//         0,
//         0,
//         historyCtx.canvas.width,
//         historyCtx.canvas.height
//       )
//     );
//     setHistory(newHistory);
//     setHistoryIndex(newHistory.length - 1);

//     redrawMainCanvas();
//   };

//   const draw = (event) => {
//     if (!isDrawing) return;
//     const drawCtx = drawingContextRef.current;
//     const { x, y } = getAdjustedCoordinates(event);
//     if (tool === "pen") {
//       drawCtx.lineTo(x, y);
//       drawCtx.stroke();
//     } else {
//       drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);
//       renderFinalShape(drawCtx, tool, startPoint, { x, y });
//     }

//     redrawMainCanvas();
//   };

//   const renderFinalShape = (ctx, tool, start, end) => {
//     const { x, y } = start;
//     const width = end.x - x;
//     const height = end.y - y;

//     switch (tool) {
//       case "rectangle":
//         ctx.strokeRect(x, y, width, height);
//         break;
//       case "arrow":
//         drawArrow(ctx, x, y, end.x, end.y);
//         break;
//       case "line":
//         drawLine(ctx, x, y, end.x, end.y);
//         break;
//       case "circle":
//         const radius = Math.sqrt(width * width + height * height);
//         drawCircle(ctx, x + width / 2, y + height / 2, radius);
//         break;
//       default:
//         break;
//     }
//   };

//   const redrawMainCanvas = () => {
//     const context = canvasRef.current.getContext("2d");
//     context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
//     if (backgroundRef.current) {
//       context.drawImage(
//         backgroundRef.current,
//         0,
//         0,
//         canvasRef.current.width / 2,
//         canvasRef.current.height / 2
//       );
//     }
//     context.drawImage(historyContextRef.current.canvas, 0, 0);
//     context.drawImage(drawingContextRef.current.canvas, 0, 0);
//   };

//   const drawArrow = (ctx, fromX, fromY, toX, toY) => {
//     const headLength = 15;
//     const angle = Math.atan2(toY - fromY, toX - fromX);

//     ctx.beginPath();
//     ctx.moveTo(fromX, fromY);
//     ctx.lineTo(toX, toY);
//     ctx.lineTo(
//       toX - headLength * Math.cos(angle - Math.PI / 6),
//       toY - headLength * Math.sin(angle - Math.PI / 6)
//     );
//     ctx.moveTo(toX, toY);
//     ctx.lineTo(
//       toX - headLength * Math.cos(angle + Math.PI / 6),
//       toY - headLength * Math.sin(angle + Math.PI / 6)
//     );
//     ctx.stroke();
//   };

//   const drawLine = (ctx, fromX, fromY, toX, toY) => {
//     ctx.beginPath();
//     ctx.moveTo(fromX, fromY);
//     ctx.lineTo(toX, toY);
//     ctx.stroke();
//   };

//   const drawCircle = (ctx, x, y, radius) => {
//     ctx.beginPath();
//     ctx.arc(x, y, radius, 0, 2 * Math.PI);
//     ctx.stroke();
//   };

//   const undo = () => {
//     if (historyIndex <= 0) return;
//     setHistoryIndex(historyIndex - 1);
//     const historyCtx = historyContextRef.current;
//     historyCtx.putImageData(history[historyIndex - 1], 0, 0);
//     redrawMainCanvas();
//   };

//   const redo = () => {
//     if (historyIndex >= history.length - 1) return;
//     setHistoryIndex(historyIndex + 1);
//     const historyCtx = historyContextRef.current;
//     historyCtx.putImageData(history[historyIndex + 1], 0, 0);
//     redrawMainCanvas();
//   };

//   const getImageData = () => {
//     console.log("logsArray", logsArray);
//     const canvas = canvasRef.current;
//     updateMarkup(canvas.toDataURL("image/png"));
//     // saveImageDataAction(canvas.toDataURL("image/png"));

//     // const tempLogsArray = structuredClone(logsArray);

//     // tempLogsArray[updateScreenIssueId].push({
//     //   markUp: canvas.toDataURL("image/png"),
//     // });
//     // saveLogArrayAction(structuredClone(tempLogsArray));
//   };

//   return (
//     <div className="imageEditor-container">
//       <canvas
//         ref={canvasRef}
//         onMouseDown={startDrawing}
//         onMouseUp={finishDrawing}
//         onMouseMove={draw}
//         className="image-editor-canvas"
//       />
//       <div className="image-editor-menu">
//         <Button buttonName={"Pen"} onClick={() => setTool("pen")} />
//         <Button buttonName={"Rectangle"} onClick={() => setTool("rectangle")} />
//         <Button buttonName={"Arrow"} onClick={() => setTool("arrow")} />
//         <Button buttonName={"Line"} onClick={() => setTool("line")} />
//         <Button buttonName={"Circle"} onClick={() => setTool("circle")} />

//         <input
//           type="color"
//           value={color}
//           onChange={(e) => setColor(e.target.value)}
//         />
//         <Dropdown
//           optionArray={[1, 3, 5, 7, 10]}
//           onClick={() => {}}
//           onChange={(event) => setLineWidth(parseInt(event.target.value, 10))}
//         />
//         <Button buttonName={"Undo"} onClick={undo} />
//         <Button buttonName={"Redo"} onClick={redo} />
//         <Button buttonName={"Save"} onClick={getImageData} />
//       </div>
//       <span className="image-editor-cancel-btn" onClick={() => cancel(false)}>
//         X
//       </span>
//     </div>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     logsArray: state.IssueTrackReducer.logsArray,
//     updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
//   };
// };

// const mapDispatchToProps = {
//   saveImageDataAction,
//   saveLogArrayAction,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Canvas);

import React, { useRef, useState, useEffect } from "react";
import TextBox from "./components/TextBox";
import Callout from "./components/Callout";
import {
  findNearestControlPoint,
  calculateControlPoints,
  renderCallout,
  renderTextBox,
} from "./utils/annotationUtils";
import "./CustomCanvas.css";

// Custom Button component
const CustomButton = ({ buttonName, onClick, ...props }) => (
  <button className="button" onClick={onClick} {...props}>
    {buttonName}
  </button>
);

// Custom Dropdown component
const CustomDropdown = ({ optionArray, onChange }) => (
  <select
    className="dropdown"
    onChange={onChange}
    style={{ padding: "5px", margin: "5px" }}
  >
    {optionArray.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </select>
);

const Canvas = ({ backgroundImage, cancel, updateMarkup, type }) => {
  const [annotations, setAnnotations] = useState([]);
  const [currentText, setCurrentText] = useState("");
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [creationStep, setCreationStep] = useState(0);
  const [arrowPreview, setArrowPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedControlPoint, setDraggedControlPoint] = useState(null);
  const [tool, setTool] = useState("select");

  const dragStartPos = useRef({ x: 0, y: 0 });
  const dragStartAnnotation = useRef(null);
  const canvasRef = useRef(null);
  const drawingContextRef = useRef(null);
  const historyContextRef = useRef(null);
  const backgroundRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [lineWidth, setLineWidth] = useState(5);
  const [color, setColor] = useState("#000000");
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    initializeCanvas(canvas, context);
    loadBackgroundImage(backgroundImage, context, type);
  }, [backgroundImage, type]);

  const initializeCanvas = (canvas, context) => {
    // Set canvas size to match window
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    // Remove the scale transform as we'll handle scaling in coordinates
    context.lineCap = "round";
    context.canvas.setAttribute("willReadFrequently", "true");

    drawingContextRef.current = createOffscreenCanvas(canvas);
    historyContextRef.current = createOffscreenCanvas(canvas);
  };

  const createOffscreenCanvas = (canvas) => {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    offscreenCanvas.setAttribute("willReadFrequently", "true");
    return offscreenCanvas.getContext("2d");
  };

  const loadBackgroundImage = (src, context, type) => {
    const image = new Image();
    if (type === "url") image.src = URL.createObjectURL(src);
    else image.src = src;
    image.onload = () => {
      backgroundRef.current = image;
      redrawMainCanvas();
    };
  };

  const getAdjustedCoordinates = (event, forAnnotation = false) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
    return {
      x: (event.clientX - rect.left) * (forAnnotation ? scaleX / 2 : scaleX),
      y: (event.clientY - rect.top) * (forAnnotation ? scaleY / 2 : scaleY),
    };
  };

  const startDrawing = (event) => {
    if (tool === "textbox" || tool === "select") {
      return;
    }

    const { x, y } = getAdjustedCoordinates(event, false);
    setStartPoint({ x, y });
    setIsDrawing(true);
    const drawCtx = drawingContextRef.current;
    drawCtx.strokeStyle = color;
    drawCtx.lineWidth = lineWidth;

    if (tool === "pen") {
      drawCtx.beginPath();
      drawCtx.moveTo(x, y);
    }
  };

  const saveToHistory = (drawingData = null) => {
    const newHistoryEntry = {
      annotations: [...annotations],
      drawing:
        drawingData ||
        (historyContextRef.current
          ? historyContextRef.current.getImageData(
              0,
              0,
              historyContextRef.current.canvas.width,
              historyContextRef.current.canvas.height
            )
          : null),
    };

    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(newHistoryEntry);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const finishDrawing = () => {
    if (!isDrawing) return;
    setIsDrawing(false);

    const drawCtx = drawingContextRef.current;
    const historyCtx = historyContextRef.current;

    historyCtx.drawImage(drawCtx.canvas, 0, 0);
    drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);

    saveToHistory();
    redrawMainCanvas();
  };

  const draw = (event) => {
    if (!isDrawing || tool === "textbox" || tool === "select") return;

    const drawCtx = drawingContextRef.current;
    const { x, y } = getAdjustedCoordinates(event, false);
    if (tool === "pen") {
      drawCtx.lineTo(x, y);
      drawCtx.stroke();
    } else {
      drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);
      renderFinalShape(drawCtx, tool, startPoint, { x, y });
    }

    redrawMainCanvas();
  };

  const renderFinalShape = (ctx, tool, start, end) => {
    const { x, y } = start;
    const width = end.x - x;
    const height = end.y - y;

    switch (tool) {
      case "rectangle":
        ctx.strokeRect(x, y, width, height);
        break;
      case "arrow":
        drawArrow(ctx, x, y, end.x, end.y);
        break;
      case "line":
        drawLine(ctx, x, y, end.x, end.y);
        break;
      case "circle":
        const radius = Math.sqrt(width * width + height * height);
        drawCircle(ctx, x + width / 2, y + height / 2, radius);
        break;
      default:
        break;
    }
  };

  const redrawMainCanvas = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Save the current transform
    context.save();

    if (backgroundRef.current) {
      // Draw background at full canvas size
      context.drawImage(
        backgroundRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }

    // Draw the history and current drawing
    context.drawImage(historyContextRef.current.canvas, 0, 0);
    context.drawImage(drawingContextRef.current.canvas, 0, 0);

    // Restore the transform
    context.restore();
  };

  const drawArrow = (ctx, fromX, fromY, toX, toY) => {
    const headLength = 15;
    const angle = Math.atan2(toY - fromY, toX - fromX);

    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle - Math.PI / 6),
      toY - headLength * Math.sin(angle - Math.PI / 6)
    );
    ctx.moveTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle + Math.PI / 6),
      toY - headLength * Math.sin(angle + Math.PI / 6)
    );
    ctx.stroke();
  };

  const drawLine = (ctx, fromX, fromY, toX, toY) => {
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.stroke();
  };

  const drawCircle = (ctx, x, y, radius) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.stroke();
  };

  const undo = () => {
    if (historyIndex <= 0) return;
    setHistoryIndex(historyIndex - 1);
    const historyEntry = history[historyIndex - 1];

    // Restore annotations
    setAnnotations(historyEntry.annotations);

    // Restore drawing
    if (historyEntry.drawing) {
      const historyCtx = historyContextRef.current;
      historyCtx.putImageData(historyEntry.drawing, 0, 0);
      redrawMainCanvas();
    }
  };

  const redo = () => {
    if (historyIndex >= history.length - 1) return;
    setHistoryIndex(historyIndex + 1);
    const historyEntry = history[historyIndex + 1];

    // Restore annotations
    setAnnotations(historyEntry.annotations);

    // Restore drawing
    if (historyEntry.drawing) {
      const historyCtx = historyContextRef.current;
      historyCtx.putImageData(historyEntry.drawing, 0, 0);
      redrawMainCanvas();
    }
  };

  const getImageData = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Save current canvas state
    const savedCanvas = document.createElement("canvas");
    savedCanvas.width = canvas.width;
    savedCanvas.height = canvas.height;
    const savedContext = savedCanvas.getContext("2d");
    savedContext.drawImage(canvas, 0, 0);

    // Render all annotations onto the canvas
    annotations.forEach((annotation) => {
      if (annotation.type === "callout") {
        renderCallout(context, annotation);
      } else {
        renderTextBox(context, annotation);
      }
    });

    // Get the image data with annotations
    const dataUrl = canvas.toDataURL("image/png");

    // Restore the original canvas state
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(savedCanvas, 0, 0);

    setPreviewImage(dataUrl);
    setShowPreview(true);
    updateMarkup(dataUrl);
  };

  const handleTextChange = (e) => {
    setCurrentText(e.target.value);
    if (selectedAnnotation !== null) {
      const updatedAnnotations = annotations.map((ann, index) =>
        index === selectedAnnotation ? { ...ann, text: e.target.value } : ann
      );
      setAnnotations(updatedAnnotations);
      // Don't save every keystroke to history
    }
  };

  const handleTextBlur = () => {
    setIsEditing(false);
    if (selectedAnnotation !== null) {
      saveToHistory();
    }
  };

  const handleTextKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setIsEditing(false);
    }
  };

  const handleCanvasClick = (e) => {
    if (tool !== "textbox") {
      setSelectedAnnotation(null);
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const { x, y } = getAdjustedCoordinates(e, true);

    if (creationStep === 1) {
      setArrowPreview({ end: { x, y }, start: { x, y } });
      setCreationStep(2);
    } else if (creationStep === 2) {
      const TEXTBOX_WIDTH = 150;
      const TEXTBOX_HEIGHT = 40;

      let newAnnotation;
      if (arrowPreview) {
        // Creating a Callout
        const TEXTBOX_OFFSET = 40;
        newAnnotation = {
          id: annotations.length,
          text: currentText || "Type here...",
          x: x - TEXTBOX_WIDTH / 2,
          y: y, // Remove the offset
          width: TEXTBOX_WIDTH,
          height: TEXTBOX_HEIGHT,
          connectedControlPoint: 1,
          arrowStart: { x, y },
          arrowEnd: arrowPreview.end,
          type: "callout",
        };
      } else {
        // Creating a plain TextBox
        newAnnotation = {
          id: annotations.length,
          text: currentText || "Type here...",
          x: x - TEXTBOX_WIDTH / 2,
          y: y - TEXTBOX_HEIGHT / 2,
          width: TEXTBOX_WIDTH,
          height: TEXTBOX_HEIGHT,
          type: "textbox",
        };
      }

      const newAnnotations = [...annotations, newAnnotation];
      setAnnotations(newAnnotations);
      saveToHistory();

      setCreationStep(0);
      setArrowPreview(null);
      setCurrentText("");
      setSelectedAnnotation(annotations.length);
      setTool("select");
    }
  };

  const handleAnnotationClick = (e, index) => {
    e.stopPropagation();
    setSelectedAnnotation(index);
    setCurrentText(annotations[index].text);
  };

  const handleDragStart = (e, index) => {
    e.stopPropagation();
    setIsDragging(true);
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleControlPointDrag = (e, index, controlPointIndex) => {
    e.stopPropagation();
    setSelectedAnnotation(index);
    setDraggedControlPoint({
      type: "resize",
      annotationIndex: index,
      controlPointIndex,
    });
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleArrowControlDrag = (e, index, isStart) => {
    e.stopPropagation();
    setSelectedAnnotation(index);
    setDraggedControlPoint({
      type: isStart ? "arrowStart" : "arrowEnd",
      annotationIndex: index,
    });
    dragStartPos.current = getAdjustedCoordinates(e, true);
    dragStartAnnotation.current = { ...annotations[index] };
  };

  const handleDoubleClick = (index) => {
    setSelectedAnnotation(index);
    setIsEditing(true);
    setCurrentText(annotations[index].text);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (creationStep === 2 && tool === "textbox" && arrowPreview) {
        const { x, y } = getAdjustedCoordinates(e, true);
        setArrowPreview((prev) => ({ ...prev, start: { x, y } }));
        return;
      }

      if (!draggedControlPoint && !isDragging) return;

      const { x, y } = getAdjustedCoordinates(e, true);
      const deltaX = (x - dragStartPos.current.x) * 0.5; // Slow down movement
      const deltaY = (y - dragStartPos.current.y) * 0.5;

      if (draggedControlPoint && dragStartAnnotation.current) {
        const updatedAnnotations = annotations.map((ann, index) => {
          if (index === draggedControlPoint.annotationIndex) {
            const startAnn = dragStartAnnotation.current;
            let newAnnotation = { ...ann };

            if (draggedControlPoint.type === "resize") {
              // Handle resize based on control point
              const cpIndex = draggedControlPoint.controlPointIndex;

              // Calculate new dimensions
              let newWidth = ann.width;
              let newHeight = ann.height;
              let newX = ann.x;
              let newY = ann.y;

              // Handle horizontal resize
              if (cpIndex === 0 || cpIndex === 3 || cpIndex === 5) {
                // Left side
                newWidth = Math.max(50, startAnn.width - deltaX);
                newX = startAnn.x + (startAnn.width - newWidth);
              } else if (cpIndex === 2 || cpIndex === 4 || cpIndex === 7) {
                // Right side
                newWidth = Math.max(50, startAnn.width + deltaX);
              }

              // Handle vertical resize
              if (cpIndex === 0 || cpIndex === 1 || cpIndex === 2) {
                // Top side
                newHeight = Math.max(30, startAnn.height - deltaY);
                newY = startAnn.y + (startAnn.height - newHeight);
              } else if (cpIndex === 5 || cpIndex === 6 || cpIndex === 7) {
                // Bottom side
                newHeight = Math.max(30, startAnn.height + deltaY);
              }

              newAnnotation = {
                ...newAnnotation,
                x: newX,
                y: newY,
                width: newWidth,
                height: newHeight,
              };

              // For callouts, only update the arrow connection point to the box
              if (ann.type === "callout") {
                // Keep the original arrow points
                newAnnotation.arrowStart = { ...startAnn.arrowStart };
                newAnnotation.arrowEnd = { ...startAnn.arrowEnd };

                // Only update the connection point if it's on the edge being resized
                const connectedPoint =
                  calculateControlPoints(newAnnotation)[
                    newAnnotation.connectedControlPoint
                  ];
                const absoluteConnectedPoint = {
                  x: newAnnotation.x + connectedPoint.x,
                  y: newAnnotation.y + connectedPoint.y,
                };

                // Update the arrow start point to maintain relative position to the connected point
                if (cpIndex === newAnnotation.connectedControlPoint) {
                  const dx =
                    absoluteConnectedPoint.x -
                    (startAnn.x +
                      calculateControlPoints(startAnn)[
                        startAnn.connectedControlPoint
                      ].x);
                  const dy =
                    absoluteConnectedPoint.y -
                    (startAnn.y +
                      calculateControlPoints(startAnn)[
                        startAnn.connectedControlPoint
                      ].y);
                  newAnnotation.arrowStart.x += dx;
                  newAnnotation.arrowStart.y += dy;
                }
              }
            } else if (draggedControlPoint.type === "arrowStart") {
              newAnnotation.arrowStart = {
                x: startAnn.arrowStart.x + deltaX,
                y: startAnn.arrowStart.y + deltaY,
              };
              newAnnotation.connectedControlPoint = findNearestControlPoint(
                newAnnotation,
                newAnnotation.arrowStart
              );
            } else if (draggedControlPoint.type === "arrowEnd") {
              newAnnotation.arrowEnd = {
                x: startAnn.arrowEnd.x + deltaX,
                y: startAnn.arrowEnd.y + deltaY,
              };
            }
            return newAnnotation;
          }
          return ann;
        });
        setAnnotations(updatedAnnotations);
      } else if (
        isDragging &&
        selectedAnnotation !== null &&
        dragStartAnnotation.current
      ) {
        const updatedAnnotations = annotations.map((ann, index) => {
          if (index === selectedAnnotation) {
            const startAnn = dragStartAnnotation.current;
            const baseUpdate = {
              ...ann,
              x: startAnn.x + deltaX,
              y: startAnn.y + deltaY,
            };

            if (ann.type === "callout") {
              return {
                ...baseUpdate,
                arrowStart: {
                  x: startAnn.arrowStart.x + deltaX,
                  y: startAnn.arrowStart.y + deltaY,
                },
                arrowEnd: {
                  x: startAnn.arrowEnd.x + deltaX,
                  y: startAnn.arrowEnd.y + deltaY,
                },
              };
            }

            return baseUpdate;
          }
          return ann;
        });
        setAnnotations(updatedAnnotations);
      }
    };

    const handleMouseUp = () => {
      if ((isDragging || draggedControlPoint) && dragStartAnnotation.current) {
        saveToHistory();
      }
      setIsDragging(false);
      setDraggedControlPoint(null);
      dragStartAnnotation.current = null;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    annotations,
    draggedControlPoint,
    isDragging,
    selectedAnnotation,
    creationStep,
    tool,
    arrowPreview,
  ]);

  return (
    <div className="imageEditor-container">
      <canvas
        ref={canvasRef}
        onMouseDown={tool === "textbox" ? undefined : startDrawing}
        onMouseUp={tool === "textbox" ? undefined : finishDrawing}
        onMouseMove={tool === "textbox" ? undefined : draw}
        onClick={handleCanvasClick}
        className="image-editor-canvas"
      />
      <div className="image-editor-menu">
        <CustomButton
          buttonName={"Select"}
          onClick={() => setTool("select")}
          aria-pressed={tool === "select"}
        />
        <CustomButton
          buttonName={"TextBox"}
          onClick={() => {
            setTool("textbox");
            setCreationStep(2); // Skip arrow creation steps
            setSelectedAnnotation(null);
            setCurrentText("");
          }}
          aria-pressed={tool === "textbox" && creationStep === 2}
        />
        <CustomButton
          buttonName={"Callout"}
          onClick={() => {
            setTool("textbox");
            setCreationStep(1); // Start with arrow end point
            setSelectedAnnotation(null);
            setCurrentText("");
          }}
          aria-pressed={tool === "textbox" && creationStep === 1}
        />
        <input
          type="text"
          value={currentText}
          onChange={handleTextChange}
          placeholder="Type annotation text..."
          style={{ padding: "5px", margin: "5px" }}
        />
        <CustomButton
          buttonName={"Pen"}
          onClick={() => setTool("pen")}
          aria-pressed={tool === "pen"}
        />
        <CustomButton
          buttonName={"Rectangle"}
          onClick={() => setTool("rectangle")}
          aria-pressed={tool === "rectangle"}
        />
        <CustomButton
          buttonName={"Arrow"}
          onClick={() => setTool("arrow")}
          aria-pressed={tool === "arrow"}
        />
        <CustomButton
          buttonName={"Line"}
          onClick={() => setTool("line")}
          aria-pressed={tool === "line"}
        />
        <CustomButton
          buttonName={"Circle"}
          onClick={() => setTool("circle")}
          aria-pressed={tool === "circle"}
        />
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          className="color"
        />
        <CustomDropdown
          optionArray={[1, 3, 5, 7, 10]}
          onChange={(event) => setLineWidth(parseInt(event.target.value, 10))}
        />
        <CustomButton buttonName={"Undo"} onClick={undo} />
        <CustomButton buttonName={"Redo"} onClick={redo} />
        <CustomButton buttonName={"Save"} onClick={getImageData} />
      </div>
      <span className="image-editor-cancel-btn" onClick={() => cancel(false)}>
        X
      </span>

      {/* Preview arrow during callout creation */}
      {tool === "textbox" && creationStep === 2 && arrowPreview && (
        <svg
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 1,
          }}
        >
          <defs>
            <marker
              id="preview-arrowhead"
              markerWidth="10"
              markerHeight="7"
              refX="9"
              refY="3.5"
              orient="auto"
            >
              <polygon points="0 0, 10 3.5, 0 7" fill="black" />
            </marker>
          </defs>
          <path
            d={`M ${arrowPreview.start.x} ${arrowPreview.start.y} L ${arrowPreview.end.x} ${arrowPreview.end.y}`}
            stroke="black"
            strokeWidth="2"
            fill="none"
            markerEnd="url(#preview-arrowhead)"
          />
        </svg>
      )}

      {/* Image Preview Modal */}
      {showPreview && (
        <div
          className="preview-modal"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="preview-content"
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "90%",
              maxHeight: "90%",
              overflow: "auto",
              position: "relative",
            }}
          >
            <button
              onClick={() => setShowPreview(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "5px 10px",
                border: "none",
                background: "#ff4444",
                color: "white",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
            <img
              src={previewImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "calc(90vh - 60px)",
                display: "block",
                marginTop: "20px",
              }}
            />
          </div>
        </div>
      )}

      {/* Render annotations */}
      {annotations.map((annotation, index) =>
        annotation.type === "callout" ? (
          <Callout
            key={annotation.id}
            annotation={annotation}
            isSelected={selectedAnnotation === index}
            isDragging={isDragging}
            isEditing={isEditing && selectedAnnotation === index}
            text={currentText}
            onSelect={(e) => handleAnnotationClick(e, index)}
            onDragStart={(e) => handleDragStart(e, index)}
            onControlPointDrag={(e, cpIndex) =>
              handleControlPointDrag(e, index, cpIndex)
            }
            onArrowControlDrag={(e, isStart) =>
              handleArrowControlDrag(e, index, isStart)
            }
            onDoubleClick={() => handleDoubleClick(index)}
            onTextChange={handleTextChange}
            onTextBlur={handleTextBlur}
            onTextKeyDown={handleTextKeyDown}
          />
        ) : (
          <TextBox
            key={annotation.id}
            annotation={annotation}
            isSelected={selectedAnnotation === index}
            isDragging={isDragging}
            isEditing={isEditing && selectedAnnotation === index}
            text={currentText}
            onSelect={(e) => handleAnnotationClick(e, index)}
            onDragStart={(e) => handleDragStart(e, index)}
            onControlPointDrag={(e, cpIndex) =>
              handleControlPointDrag(e, index, cpIndex)
            }
            onDoubleClick={() => handleDoubleClick(index)}
            onTextChange={handleTextChange}
            onTextBlur={handleTextBlur}
            onTextKeyDown={handleTextKeyDown}
          />
        )
      )}
    </div>
  );
};

export default Canvas;
