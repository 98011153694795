import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import {
  AnnotationsPlugin,
  math,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";
import Tab2 from "../../components/Tab/Tab2";
import LSideBar from "../../components/LSideBar/LSideBar";
import { Button, CheckBox } from "../../components/Button/Button";
import Detail from "../../asset/detail.svg";

import "./ClashDetectionTab.css";

import {
  setClashDetectionTabStatusAction,
  detectCollisionAction,
  clashDetectionListAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  handleSearchAction,
  setAnnotationOptionTabAction,
  setClashTestTabStatusAction,
  handleLoadingAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";
import Dropdown from "../../components/DropDown/Dropdown";

import PNG_BINOCULAR from "../../asset/binocular.png";
import PNG_SAVE from "../../asset/save.png";

const ClashDetectionTab = (props) => {
  const issueArray = [
    {
      issueType: "issue-type1",
      issueTypeName: "0AR",
      issueName: "Architecture-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0CV",
      issueName: "Civil-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0EL",
      issueName: "Electrical-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0FR",
      issueName: "Fire-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0LA",
      issueName: "Landscape-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0ME",
      issueName: "Mechanical-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0SE",
      issueName: "Security-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0SI",
      issueName: "Signage-Client Raised Issue",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0ST",
      issueName: "Structure",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1AR",
      issueName: "Arch_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1CV",
      issueName: "Civil_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1EL",
      issueName: "Elec_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1FR",
      issueName: "Fire_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1HI",
      issueName: "Hydr_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1LA",
      issueName: "Lands_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1ME",
      issueName: "Mech_Coord-(add description here)",
    },
  ];

  const TYPES = ["Clash", "Clearance", "Double ups"];
  const GROUPING = ["No Grouping", "Group By Level", "Group by Grid"];
  const VIEWISOLATION = ["Hide other models", "Xray other models"];
  const [open, setOpen] = useState(false);

  const [clashList, setClashList] = useState([]);

  const [isTestOpen, setIsTestOpen] = useState(false);
  const [openedTestId, setOpenedTestId] = useState(null);
  const [openedTest, setOpenedTest] = useState([]);
  const spanRefs = useRef({});

  const [clashName, setClashName] = useState("");
  const [clashType, setClashType] = useState(TYPES[0]);
  const [tolerance, setTolerance] = useState("");
  const [clashGroup, setClashGroup] = useState("");
  const [clashViewIsolation, setClashViewIsolation] = useState(
    VIEWISOLATION[0]
  );

  const [isClashIssueOpen, setIsClashIssueOpen] = useState(false);
  const [isStampListOpen, setIsStampListOpen] = useState(false);

  const [showList, setShowList] = useState([]);

  const [searchSet1, setSearchSet1] = useState({});
  const [searchSet2, setSearchSet2] = useState({});

  const [searchSet1Flag, setSearchSet1Flag] = useState();
  const [searchSet2Flag, setSearchSet2Flag] = useState();

  const [clashDetectionName, setClashDetectionName] = useState(null);
  const [clashIssueComment, setClashIssueComment] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedIssue, setSelectedIssue] = useState({
    issueType: null,
    issueTypeName: null,
    issueName: null,
  });

  const [showClashDetectionList, setShowClashDetectionList] = useState([]);

  const [showClashDetectionArray1, setShowClashDetectionArray1] = useState([]);
  const [showClashDetectionArray2, setShowClashDetectionArray2] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllSearches = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };

  const getAllClashTests = async (pg = page) => {
    const url = BaseURL(
      `clashes/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    console.log("url", url);
    handleLoadingAction(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setClashList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    handleLoadingAction(false);
  };
  useEffect(() => {
    if (props.setClashDetectionTabStatus) getAllClashTests();
  }, [props.setClashDetectionTabStatus]);

  useEffect(() => {
    if (open) getAllSearches();
  }, [open]);

  const defaultIssue = {
    id: 595,
    stamp: "1AR",
    title: "Arch_Coord - add decription here",
    type: "Standard issue",
    status: "open",
    assignee: "ben stokoe",
    priority: "none",
    deadline: "none",
    color: "red",
    created: "13/Aug/2024",
    reporter: "Nathan Lenarduzzi",
    wachers: [
      "Adrian Wilkins",
      "Alexander Salna",
      "Calhum Craig",
      "Nathan Lenarduzzi",
    ],
    tags: "coordination issue",
    defaultSheet: "---",
    room: "UE4B-LIVERBLE 2901",
    level: "LEVEL 29",
    area: "GFA RESIDENTIAL FAST",
  };
  const [isAnnotationOptionTabOpen, setIsAnnotationOptionTabOpen] =
    useState(false);

  const closeAnnotationOptionTab = () => {
    setIsAnnotationOptionTabOpen(false);
  };

  const getScreenShot = async () => {
    const img = new Image();
    console.log("CLASH DETECTION");
    // const nextFrame = () => {
    // This method will get a snapshot image, composed of
    // an image of the viewer canvas, overlaid with an image
    // of the HTML container element belonging to each installed
    // Viewer plugin.

    const retVal = await props.viewer.getSnapshotWithPlugins({
      format: "png",
      width: img.width * 3, // Upscale snapshot resolution 2x
      height: img.height * 3,
    });
    // .then((imageData) => {
    //   setImageData(imageData);

    //   // img.src = imageData;

    //   // const canvasImg = new Image();
    //   // canvasImg.src = imageData;

    //   props.viewer.camera.orbitYaw(5);
    //   // setTimeout(nextFrame, 200);
    // });
    // };

    // nextFrame();
    // setIsImageEditorOpen(true);
    // console.log(retVal);
    return retVal;
  };

  const updateScreenShot = async () => {
    console.log(props.updateScreenIssueId);
    console.log(props.IssuesArray);
    const retVal = await getScreenShot();
    props.IssuesArray[props.updateScreenIssueId].markUp = retVal;
  };

  const onClickIssueTrack = async () => {
    const reVal = await getScreenShot();
    // console.log(reVal);
    props.setIssueTrackStatusAction(true);
    const temp = structuredClone(props.IssuesArray);
    defaultIssue.id = temp.length + 1;
    defaultIssue.markUp = reVal;
    temp.push(defaultIssue);
    props.saveIssueArrayAction(structuredClone(temp));

    const tempLogsArray = structuredClone(props.logsArray);
    tempLogsArray.push([{ markUp: reVal }]);
    props.saveLogArrayAction(structuredClone(tempLogsArray));
  };
  // const [tempElement, setTempElement] = useState();
  useEffect(() => {
    // setShowList(props.searchSaveList);
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.searchSaveListFlag]);

  useEffect(() => {
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.clashDetectionListFlag]);

  useEffect(() => {
    console.log("clashdetection", props.clashDetectionArray1);
    const temp1 = [];
    props.clashDetectionArray1.map((element, i) => {
      temp1.push({ id: i, ...element });
    });
    const temp2 = [];
    props.clashDetectionArray2.map((element, i) => {
      temp2.push({ id: i, ...element });
    });
    setShowClashDetectionArray1(temp1);
    setShowClashDetectionArray2(temp2);
  }, [props]);

  useEffect(() => {
    if (props.viewer === null) return;

    showClashDetectionArray1.map((element, i) => {
      const entity = props.viewer.scene.objects[element.id];
      if (entity !== undefined) entity.selected = false;
    });

    selectedRows.map((element, i) => {
      const entity = props.viewer.scene.objects[element];
      if (entity !== undefined) entity.selected = true;
    });
  }, [selectedRows]);

  useEffect(() => {
    let flag = false;
    showClashDetectionArray1.map((element, i) => {
      if (element.id === props.addToClashDetectionFromView) flag = true;
    });
    if (flag) handleCheckboxChange(props.addToClashDetectionFromView);
  }, [props.selectContextMenuBtnStatus]);

  useEffect(() => {
    console.log("????");
    setSelectedRows([props.addToClashDetectionNormalClick]);

    showClashDetectionArray1.map((element, i) => {
      if (element.id === props.addToClashDetectionNormalClick) {
        const id = showClashDetectionArray2[i].id;
        const entity = props.viewer.scene.objects[id];
        if (entity !== undefined) entity.selected = true;
      }
    });
  }, [props.addToClashDetectionNormalClick]);

  const handleClose = () => {
    setOpen(false);
    setIsClashIssueOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getSearchSet1 = (e) => {
    console.log(e.target.textContent);
    console.log(props.searchSaveList);
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        console.log(element);
        setSearchSet1({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet1Flag(element.name);
      }
    });
  };
  const getSearchSet2 = (e) => {
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        setSearchSet2({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet2Flag(element.name);
      }
    });
  };

  const onChangeInputValue = (e) => {
    setClashDetectionName(e.target.value);
  };

  const onChangeClashIssueCommentValue = (e) => {
    setClashIssueComment(e.target.value);
  };

  const createClashTest = async () => {
    const url = BaseURL(`clashes`);

    const params = {
      name: clashName,
      type: clashType,
      tolerance: tolerance,
      clashGroup: clashGroup,
      clashViewIsolation: clashViewIsolation,
      compare1: searchSet1,
      compare2: searchSet2,
      projects: [`${props.projectName}`],
    };

    console.log("params", params);
    handleLoadingAction(true);

    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      toast.success("Clash added successfully");
    }

    handleLoadingAction(false);
    getAllClashTests();
    setOpen(false);
    // console.log("??", searchSet1);
    // if (
    //   clashDetectionName === null ||
    //   searchSet1 === undefined ||
    //   searchSet2 === undefined
    // ) {
    //   alert("Please select the SearchSetA and the SearchSetB");
    //   return;
    // }
    // props.detectCollisionAction({
    //   clashDetectionName: clashDetectionName,
    //   searchSet1: searchSet1,
    //   searchSet2: searchSet2,
    // });
    // showClashDetectionList.push({
    //   clashDetectionName: clashDetectionName,
    //   searchSet1: searchSet1,
    //   searchSet2: searchSet2,
    // });

    // const url = BaseURL(`clashes`);
    // const params = {
    //   name: clashDetectionName,
    //   compare1: searchSet1.name,
    //   compare2: searchSet2.name,
    //   projects: [`${props.projectName}`],
    // };

    // setIsLoading(true);

    // const response = await Post(url, params, apiHeader(props.token));
    // if (response !== undefined) {
    //   console.log("response", response);
    //   toast.success("Clash added successfully");
    // }

    // setIsLoading(false);

    // props.clashDetectionListAction(showClashDetectionList);
    // props.setClashTestTabStatusAction(true);
  };

  const onClickListItem = (e) => {
    console.log(e.target.textContent);
    showClashDetectionList.map((element, i) => {
      if (element.clashDetectionName === e.target.textContent) {
        console.log("MMMM", element.clashDetectionName);
        setClashDetectionName(e.target.textContent);
        setSearchSet1(element.searchSet1);
        setSearchSet2(element.searchSet2);
        props.detectCollisionAction({
          clashDetectionName: e.target.textContent,
          searchSet1: element.searchSet1,
          searchSet2: element.searchSet2,
        });
      }
    });
  };

  const closeTab = () => {
    props.setClashDetectionTabStatusAction(false);
  };

  const viewEntity = (e, element) => {
    console.log("...", element);
    const dd = props.viewer.scene.objects[element.id];
    console.log(dd);
    const viewer = props.viewer;
    const scene = viewer.scene;
    viewer.cameraFlight.flyTo(
      {
        aabb: dd.aabb,
        duration: 0.5,
      },
      () => {
        setTimeout(function () {
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
        }, 500);
      }
    );
  };

  const onClickIssueBtn = (e, element) => {
    // setTempElement(element);
    if (!selectedRows.includes(element.id)) {
      handleCheckboxChange(element.id);
    }
    setIsClashIssueOpen(true);
  };

  const onClickSelectBtn = () => {
    if (isStampListOpen) setIsStampListOpen(false);
    else setIsStampListOpen(true);
  };

  const onClickStampItem = (e, element) => {
    setIsStampListOpen(false);
    console.log(element.issueName);
    setSelectedIssue(element);
  };

  const onClickCreateIssueBtn = () => {
    props.handleSearchAction(false);
    // setIsAnnotationOptionTabOpen(true);
    props.setAnnotationOptionTabAction(true);
    setIsClashIssueOpen(false);

    console.log("selectedRows", selectedRows);

    const annotations = new AnnotationsPlugin(props.viewer, {
      container: document.getElementById("annotationsContainer"),
      // Default HTML template for marker position
      markerHTML:
        "<div class='annotation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
      // Default HTML template for label
      // labelHTML:
      //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
      //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
      // Default values to insert into the marker and label templates
      values: {
        markerBGColor: "white",
        labelBGColor: "red",
        glyph: "X",
        //   title: "Untitled",
        //   description: "No description",
      },
    });
    annotations.on("markerClicked", (annotation) => {
      annotation.labelShown = !annotation.labelShown;
    });

    let i = 1;

    selectedRows.map((element, i) => {
      console.log("i", i);
      const tempElement = props.viewer.scene.objects[element];
      const aabb = tempElement.aabb;
      const center = [
        (aabb[0] + aabb[3]) / 2,
        (aabb[1] + aabb[4]) / 2,
        (aabb[2] + aabb[5]) / 2,
      ];
      const canvasPos = worldToCanvas(props.viewer, center);
      // const canvasPos = [613.0423424, 536.830349324];
      console.log("canvasPos", canvasPos);
      const hit = props.viewer.scene.pick({
        canvasPos,
      });
      console.log("hit.", hit);
      const annotationStyle = selectedIssue.issueType;
      annotations.createAnnotation({
        id: 1,
        worldPos: center, // <<------- initializes worldPos and entity from PickResult
        // PickResult: hit,
        occludable: true, // Optional, default is true
        markerShown: true, // Optional, default is true
        labelShown: true, // Optional, default is true
        values: {
          // HTML template values
          glyph: selectedIssue.issueTypeName,
          fontColor:
            annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
          borderColor:
            annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
          //   title: "My annotation " + i,
          //   description: "My description " + i,
        },
      });
    });

    // const annotations = new AnnotationsPlugin(props.viewer, {
    //   // Default HTML template for marker position
    //   markerHTML: `<div class='clash-detection-annotation-marker' style='background-color: {{markerBGColor}}; color: {{fontColor}}; border-color: {{borderColor}}'>{{glyph}}</div>`,
    //   // Default HTML template for label
    //   // labelHTML:
    //   //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
    //   //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
    //   // Default values to insert into the marker and label templates
    //   values: {
    //     markerBGColor: "white",
    //     labelBGColor: "red",
    //     glyph: "X",
    //     //   title: "Untitled",
    //     //   description: "No description",
    //   },
    // });
    // annotations.on("markerClicked", (annotation) => {
    //   annotation.labelShown = !annotation.labelShown;
    // });
    // selectedRows.map((element, i) => {
    //   console.log("i", i);
    //   const tempElement = props.viewer.scene.objects[element];
    //   const aabb = tempElement.aabb;
    //   const center = [
    //     (aabb[0] + aabb[3]) / 2,
    //     (aabb[1] + aabb[4]) / 2,
    //     (aabb[2] + aabb[5]) / 2,
    //   ];
    //   const canvasPos = worldToCanvas(props.viewer, center);
    //   // const canvasPos = [613.0423424, 536.830349324];
    //   console.log("canvasPos", canvasPos);
    //   const hit = props.viewer.scene.pick({
    //     canvasPos,
    //   });
    //   console.log("hit.", hit);
    //   const annotationStyle = selectedIssue.issueType;
    //   annotations.createAnnotation({
    //     id: 1,
    //     worldPos: center, // <<------- initializes worldPos and entity from PickResult
    //     // PickResult: hit,
    //     occludable: true, // Optional, default is true
    //     markerShown: true, // Optional, default is true
    //     labelShown: true, // Optional, default is true
    //     values: {
    //       // HTML template values
    //       glyph: selectedIssue.issueTypeName,
    //       fontColor:
    //         annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
    //       borderColor:
    //         annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
    //       //   title: "My annotation " + i,
    //       //   description: "My description " + i,
    //     },
    //   });
    // });

    // viewEntity("e", tempElement);
  };

  // Function to convert worldPos to canvasPos
  function worldToCanvas(viewer, worldPos) {
    const scene = viewer.scene;
    const camera = viewer.camera;

    // Create the combined view-projection matrix
    const viewMatrix = camera.viewMatrix;
    const projMatrix = camera.projMatrix;
    const viewProjMatrix = math.mulMat4(projMatrix, viewMatrix, []);

    // Convert the world position to homogeneous clip coordinates
    const clipPos = math.transformPoint4(viewProjMatrix, [...worldPos, 1.0]);

    // Perspective division to get normalized device coordinates (NDC)
    const ndcPos = [
      clipPos[0] / clipPos[3],
      clipPos[1] / clipPos[3],
      clipPos[2] / clipPos[3],
    ];

    // Convert NDC to canvas position
    const canvas = scene.canvas.canvas;
    const canvasPos = [
      (ndcPos[0] * 0.5 + 0.5) * canvas.width,
      (1.0 - (ndcPos[1] * 0.5 + 0.5)) * canvas.height,
    ];

    console.log("canvaPos ==== ", canvasPos);
    return canvasPos;
  }

  const handleCheckboxChange = (id) => {
    console.log("id", id);
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );

    showClashDetectionArray1.map((element, i) => {
      if (element.id === id) {
        const id = showClashDetectionArray2[i].id;
        const entity = props.viewer.scene.objects[id];
        if (entity !== undefined) entity.selected = true;
      }
    });
  };

  const getModelPropertiesFromJson = (compareValue1, compareValue2) => {
    if (!props.viewer || !props.metaData) return;

    const metaData = props.metaData;

    console.log("compare1", compareValue1);
    console.log("compareValue2", compareValue2);
    const keyNumber = Object.entries(metaData.Legend).find(
      ([, value]) => value.Name === compareValue1
    )?.[0];

    console.log("keyNumber", keyNumber);
    if (!keyNumber) return;

    const resultObject = Object.entries(metaData.Properties)
      .filter(([, items]) => items[keyNumber] === compareValue2)
      .map(([property]) => property);

    return resultObject;
  };

  const getProperties = (propertyName, propertyValue) => {
    const returnVal = [];

    const resultObject = getModelPropertiesFromJson(
      propertyName,
      propertyValue
    );

    console.log("resultOb", resultObject);
    if (!resultObject) return;
    const { objects } = props.viewer.scene;

    Object.entries(objects).forEach(([key, object]) => {
      const idMatch = key.match(/\[([\d-]+)\]/)?.[1];
      if (resultObject.includes(idMatch)) {
        returnVal.push(object);
      }
    });

    return returnVal;
  };

  function intersect(aabb1, aabb2) {
    return (
      aabb1[0] <= aabb2[3] &&
      aabb1[3] >= aabb2[0] && // Check X axis
      aabb1[1] <= aabb2[4] &&
      aabb1[4] >= aabb2[1] && // Check Y axis
      aabb1[2] <= aabb2[5] &&
      aabb1[5] >= aabb2[2]
    );
  }

  function isClearance(aabb1, aabb2, clearance) {
    // const dx = Math.max(aabb2[0] - aabb1[3], aabb1[0] - aabb2[3], 0); // Distance along X
    // const dy = Math.max(aabb2[1] - aabb1[4], aabb1[1] - aabb2[4], 0); // Distance along Y
    // const dz = Math.max(aabb2[2] - aabb1[5], aabb1[2] - aabb2[5], 0); // Distance along Z

    // const distance = Math.sqrt(dx * dx + dy * dy + dz * dz); // Euclidean distance between objects
    const closestPointA = [
      Math.max(aabb1[0], Math.min(aabb2[0], aabb1[3])),
      Math.max(aabb1[1], Math.min(aabb2[1], aabb1[4])),
      Math.max(aabb1[2], Math.min(aabb2[2], aabb1[5])),
    ];
    const closestPointB = [
      Math.max(aabb2[0], Math.min(aabb1[0], aabb2[3])),
      Math.max(aabb2[1], Math.min(aabb1[1], aabb2[4])),
      Math.max(aabb2[2], Math.min(aabb1[2], aabb2[5])),
    ];

    const distance = Math.sqrt(
      Math.pow(closestPointB[0] - closestPointA[0], 2) +
        Math.pow(closestPointB[1] - closestPointA[1], 2) +
        Math.pow(closestPointB[2] - closestPointA[2], 2)
    );
    console.log("distance", distance);
    console.log("clearance", clearance);
    console.log("clearance", clearance / 0.3);
    return distance < clearance / 0.3;
  }

  function isDoubleUp(aabb1, aabb2) {
    // Check if aabb1 is inside aabb2
    const isAABB1InsideAABB2 =
      aabb1[0] >= aabb2[0] &&
      aabb1[3] <= aabb2[3] && // X-axis
      aabb1[1] >= aabb2[1] &&
      aabb1[4] <= aabb2[4] && // Y-axis
      aabb1[2] >= aabb2[2] &&
      aabb1[5] <= aabb2[5]; // Z-axis

    // Check if aabb2 is inside aabb1
    const isAABB2InsideAABB1 =
      aabb2[0] >= aabb1[0] &&
      aabb2[3] <= aabb1[3] && // X-axis
      aabb2[1] >= aabb1[1] &&
      aabb2[4] <= aabb1[4] && // Y-axis
      aabb2[2] >= aabb1[2] &&
      aabb2[5] <= aabb1[5]; // Z-axis

    // Return true if either is fully inside the other
    return isAABB1InsideAABB2 || isAABB2InsideAABB1;
  }
  const onOpenClashTest = (id) => {
    setIsTestOpen(true);
    setOpenedTestId(id);
    setOpenedTest([]);
  };

  const onClickRun = () => {
    const id = openedTestId;
    const searchSet1 = clashList[id].compare1;
    const searchSet2 = clashList[id].compare2;
    const clashEntities1 = [];
    const clashEntities2 = [];
    const tempXray = [];

    console.log("searchSet1", searchSet1);
    console.log("searchSet2", searchSet2);
    const value1 = getProperties(searchSet1.name, searchSet1.value);
    const value2 = getProperties(searchSet2.name, searchSet2.value);

    console.log("value1", value1);
    value1.forEach((element1) => {
      value2.forEach((element2) => {
        if (clashList[id].type === "Clash") {
          if (intersect(element1.aabb, element2.aabb)) {
            element1.colorize = [1.0, 0.0, 0.0];
            element2.colorize = [0.0, 0.0, 1.0];
            clashEntities1.push(element1);
            clashEntities2.push(element2);
            tempXray.push(element1, element2);
          }
        } else if (clashList[id].type === "Double ups") {
          if (isDoubleUp(element1.aabb, element2.aabb)) {
            element1.colorize = [1.0, 0.0, 0.0];
            element2.colorize = [0.0, 0.0, 1.0];
            clashEntities1.push(element1);
            clashEntities2.push(element2);
            tempXray.push(element1, element2);
          }
        } else if (clashList[id].type === "Clearance") {
          if (
            isClearance(element1.aabb, element2.aabb, clashList[id].tolerance)
          ) {
            element1.colorize = [1.0, 0.0, 0.0];
            element2.colorize = [0.0, 0.0, 1.0];
            clashEntities1.push(element1);
            clashEntities2.push(element2);
            tempXray.push(element1, element2);
          }
        }
      });
    });

    if (clashEntities1.length === 0 && clashEntities2.length === 0) {
      alert("There is no collision");
      return;
    }

    const { scene } = props.viewer;
    scene.setObjectsVisible(scene.objectIds, false);

    tempXray.forEach((entity) => {
      entity.visible = true;
    });

    const MetaData = props.metaData;
    const entity1Property = [];
    const entity2Property = [];

    clashEntities1.forEach((element, index) => {
      const idMatch = element.id.match(/\[([\d-]+)\]/);
      const propertyId = idMatch ? idMatch[1] : null;

      if (propertyId && MetaData.Properties[propertyId]) {
        const propertyData = MetaData.Properties[propertyId];

        const legend = MetaData.Legend;
        // console.log("PropertYData", propertyData);
        Object.entries(legend).forEach(([key, value]) => {
          if (value.Name === "Family") {
            const family = propertyData[key];
            entity1Property.push(family);
          }
        });
      }
    });

    clashEntities2.forEach((element, index) => {
      const idMatch = element.id.match(/\[([\d-]+)\]/);
      const propertyId = idMatch ? idMatch[1] : null;

      if (propertyId && MetaData.Properties[propertyId]) {
        const propertyData = MetaData.Properties[propertyId];

        const legend = MetaData.Legend;
        // console.log("PropertYData", propertyData);
        Object.entries(legend).forEach(([key, value]) => {
          if (value.Name === "Family") {
            const family = propertyData[key];
            entity2Property.push(family);
          }
        });
      }
    });

    const temp = [];
    if (clashList[id].clashGroup === "No Grouping") {
      entity1Property.forEach((element, index) => {
        temp.push({
          entity1: element,
          entity2: entity2Property[index],
          entity1Id: clashEntities1[index],
          entity2Id: clashEntities2[index],
        });
      });
    } else if (clashList[id].clashGroup === "Group By Level") {
      const levels = MetaData.ProjectInfo.Main.Levels;
      // Sort levels by elevation
      const sortedLevelEntries = Object.entries(levels).sort(
        ([idA, dataA], [idB, dataB]) => {
          // Sort by elevation value
          const elevationA = parseFloat(dataA.Elevation || 0);
          const elevationB = parseFloat(dataB.Elevation || 0);
          return elevationA - elevationB; // Sort from lowest to highest
        }
      );
      const levelPoints = [];
      sortedLevelEntries.forEach(([key, level]) => {
        const minPoint = level.Positionmin.split(",").map(Number);
        const maxPoint = level.Positionmax.split(",").map(Number);
        levelPoints.push((minPoint[2] + maxPoint[2]) / 2);
        temp.push({ levelName: level.Name });
      });

      console.log("levelPoins", levelPoints);
      clashEntities1.forEach((element, i) => {
        const aabb = element.aabb;
        console.log("aabb", aabb);
        const position = (aabb[1] + aabb[4]) / 2;
        console.log("position", position);
        levelPoints.forEach((levelPoint, index) => {
          if (position < levelPoint && position > levelPoints[index - 1]) {
            const childData = {
              entity1: entity1Property[i],
              entity2: entity2Property[i],
              entity1Id: element,
              entity2Id: clashEntities2[i],
            };

            if (temp[index].children) {
              temp[index].children.push(childData);
            } else {
              temp[index].children = [childData];
            }
          }
        });
      });
    } else if (clashList[id].clashGroup === "Group By Grid intersection1") {
    }

    console.log("?????????????????????????????????????????????", temp);
    setOpenedTest(temp);
  };

  const onClickOpenedTestRow = (id) => {
    const viewer = props.viewer;
    const scene = viewer.scene;

    const entity = id;
    viewer.cameraFlight.flyTo(
      {
        aabb: entity.aabb,
        duration: 0.5,
      },
      () => {
        setTimeout(function () {
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
        }, 500);
      }
    );
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpand = (index) => {
    if (expandedRows.includes(index)) {
      // Collapse if already expanded
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      // Expand if not already expanded
      setExpandedRows([...expandedRows, index]);
    }
  };
  return (
    <>
      <LSideBar
        isToggle={props.setClashDetectionTabStatus}
        handleToggle={closeTab}
        header={"Clash Detection"}
      >
        {!isTestOpen && (
          <>
            {" "}
            <div className="clash-detection-tab-toolbar">
              <div>
                <Button buttonName={"Create"} onClick={handleOpen} />
              </div>
              <div>
                <Button buttonName={"Update"} onClick={handleOpen} />
              </div>
              <div>
                <Button buttonName={"Setting"} onClick={handleOpen} />
              </div>
            </div>
            <table className="custom-table">
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>
                    <CheckBox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows(
                            showClashDetectionArray1.map((row) => row.id)
                          );
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                      checked={
                        selectedRows.length === showClashDetectionArray1.length
                      }
                    />
                  </th>
                  <th style={{ width: "35%" }}>Name</th>
                  <th style={{ width: "10%" }}>Up to date</th>
                  <th style={{ width: "20%" }}>Last run by</th>
                  <th style={{ width: "10%" }}>New clashes</th>
                  <th style={{ width: "10%" }}>Old clashes</th>
                  <th style={{ width: "10%" }}>Setting</th>
                </tr>
                {clashList.map((element, i) => {
                  return (
                    <tr
                      key={i}
                      // className={
                      //   selectedRows.includes(element.id) ? "selected" : ""
                      // }
                      className="table-row"
                    >
                      <td className="clash-detection-table-col1">
                        <CheckBox
                          onChange={() => handleCheckboxChange(element.id)}
                          checked={selectedRows.includes(element.id)}
                        />
                      </td>
                      <td>{element.name}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <Button
                          buttonName={"Open"}
                          onClick={() => onOpenClashTest(i)}
                        />
                      </td>
                      {/* <td
                    className="clash-detection-table-col2"
                    onClick={(e) => viewEntity(e, element)}
                  >
                    {element.id}
                  </td>
                  <td
                    className="clash-detection-table-col3"
                    onClick={(e) => viewEntity(e, showClashDetectionArray2[i])}
                  >
                    {showClashDetectionArray2[i].id}
                  </td>
                  <td className="clash-detection-table-col4">
                    <div>
                      <img
                        src={Detail}
                        onClick={(e) => onClickIssueBtn(e, element)}
                        width={15}
                        height={11}
                        alt="detail"
                      />
                    </div>
                  </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {isTestOpen && (
          <>
            <div className="clash-detection-tab-toolbar">
              <div>
                <Button
                  buttonName={"Back"}
                  onClick={() => setIsTestOpen(false)}
                />
              </div>
              <div>
                <Button buttonName={"Run Test"} onClick={onClickRun} />
              </div>
            </div>

            {clashList[openedTestId].clashGroup === "No Grouping" && (
              <table className="custom-table">
                <tbody>
                  <tr>
                    <th style={{ width: "5%" }}>
                      <CheckBox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedRows(
                              showClashDetectionArray1.map((row) => row.id)
                            );
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                        checked={
                          selectedRows.length ===
                          showClashDetectionArray1.length
                        }
                      />
                    </th>
                    <th style={{ width: "35%" }}>Name</th>
                    <th style={{ width: "10%" }}>#</th>
                    <th style={{ width: "10%" }}>Count</th>
                    <th style={{ width: "10%" }}>Active</th>
                    <th style={{ width: "10%" }}>New</th>
                    <th style={{ width: "20%" }}>Status</th>
                  </tr>
                  {openedTest?.map((element, i) => {
                    return (
                      <tr
                        key={i}
                        // className={
                        //   selectedRows.includes(element.id) ? "selected" : ""
                        // }
                        className="table-row"
                        onClick={() => onClickOpenedTestRow(element.entity1Id)}
                      >
                        <td className="clash-detection-table-col1">
                          <CheckBox
                            onChange={() => handleCheckboxChange(element.id)}
                            checked={selectedRows.includes(element.id)}
                          />
                        </td>
                        <td>{`${element.entity1} vs ${element.entity2}`}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <Button
                            buttonName={"Not Reviewed"}
                            onClick={() => {}}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {
              clashList[openedTestId].clashGroup !== "No Grouping" && (
                <table className="custom-table">
                  <tbody>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <CheckBox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedRows(
                                showClashDetectionArray1.map((row) => row.id)
                              );
                            } else {
                              setSelectedRows([]);
                            }
                          }}
                          checked={
                            selectedRows.length ===
                            showClashDetectionArray1.length
                          }
                        />
                      </th>
                      <th style={{ width: "35%" }}>Name</th>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "10%" }}>Count</th>
                      <th style={{ width: "10%" }}>Active</th>
                      <th style={{ width: "10%" }}>New</th>
                      <th style={{ width: "20%" }}>Status</th>
                    </tr>
                    {openedTest?.map((element, index) => {
                      return (
                        <React.Fragment key={index}>
                          {/* Parent Row */}
                          <tr
                            className="table-row"
                            onClick={() => toggleExpand(index)} // Expand/Collapse logic
                          >
                            <td className="clash-detection-table-col1">
                              <CheckBox
                                onChange={() =>
                                  handleCheckboxChange(element.id)
                                }
                                checked={selectedRows.includes(element.id)}
                              />
                            </td>
                            <td>
                              <div>📂</div>
                              <div>{element.levelName}</div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <Button
                                buttonName={"Not Reviewed"}
                                onClick={() => {}}
                              />
                            </td>
                          </tr>

                          {/* Child Rows - Displayed only if expanded */}
                          {expandedRows.includes(index) &&
                            element.children?.map((child, childIndex) => (
                              <tr
                                key={`${index}-${childIndex}`}
                                className="table-row-child"
                                onClick={() =>
                                  onClickOpenedTestRow(child.entity1Id)
                                }
                              >
                                <td className="clash-detection-table-col1">
                                  {/* Child-specific checkbox */}
                                  <CheckBox
                                    onChange={() =>
                                      handleCheckboxChange(child.id)
                                    }
                                    checked={selectedRows.includes(child.id)}
                                  />
                                </td>
                                <td>
                                  <div style={{ paddingLeft: "20px" }}>
                                    🔹 {`${child.entity1} VS ${child.entity2}`}
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <Button
                                    buttonName={"Not Reviewed"}
                                    onClick={() => {}}
                                  />
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              )
              // openedTest?.map((element, index) => {
              //   return (
              //     <li key={index}>
              //       <label className="list-item">
              //       <CheckBox
              //               checked={true}
              //               // onChange={(e) =>
              //               //   handleCheckboxChange(currentKey, e.target.checked)
              //               // }
              //             />
              //         {!element.children ? (
              //           <details className="details-item">
              //             <summary>
              //               <div>📂</div>
              //               <div> {index}</div>
              //             </summary>
              //             <ul>{element.levelName}</ul>
              //           </details>
              //         ) : (
              //           <div
              //             style={{
              //               marginTop: "10px",
              //               marginBottom: "10px",
              //               display: "flex",
              //               justifyContent: "start",
              //             }}
              //           >
              //             {" "}
              //             <CheckBox
              //               checked={true}
              //               // onChange={(e) =>
              //               //   handleCheckboxChange(currentKey, e.target.checked)
              //               // }
              //             />
              //             <span ref={(ele) => (spanRefs.current[index] = ele)}>
              //               {element.children.map((child, i) => {
              //                 return (
              //                   <div>{`${child.entity1} VS ${child.entity2}`}</div>
              //                 );
              //               })}
              //             </span>
              //           </div>
              //         )}
              //       </label>
              //     </li>
              //   );
              // })}
            }
          </>
        )}
        {/* </div> */}
        {/* <ul className="clash-detection-tab-ul">
          {showClashDetectionList.map((_, i) => (
            <li
              className="clash-detection-tab-li-item"
              key={i}
              value={_.searchSetName}
              onClick={(e) => onClickListItem(e)}
            >
              <div></div>
              <div>{_.clashDetectionName}</div>
            </li>
          ))}
        </ul> */}
      </LSideBar>
      <Modal
        isToggle={open}
        handleToggle={() => setOpen(false)}
        header={"Clash Test"}
        height={`calc(70vh)`}
      >
        <div className="clash-detection-modal-container">
          <div className="clash-detection-modal">
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Name</span>
              </div>
              <div className="clash-detection-test-btn">
                <input
                  className="mycustom-button"
                  value={clashName}
                  onChange={(e) => {
                    setClashName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Type</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashType}
                  optionArray={TYPES}
                  onChange={(e) => {
                    setClashType(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Tolerance</span>
              </div>
              <div className="clash-detection-test-btn">
                <input
                  className="mycustom-button"
                  value={tolerance}
                  onChange={(e) => {
                    setTolerance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>Grouping</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashGroup}
                  optionArray={GROUPING}
                  onChange={(e) => {
                    setClashGroup(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="clash-detection-test-item">
              <div className="clash-detection-test-label">
                <span>View Isolation</span>
              </div>
              <div className="clash-detection-test-btn">
                <Dropdown
                  value={clashViewIsolation}
                  optionArray={VIEWISOLATION}
                  onChange={(e) => {
                    setClashViewIsolation(e.target.value);
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div className="modal-body-list">
              <div className="modal-body-list-colum">
                <h5>SearchSet A</h5>
                <ul className="modal-ul">
                  {showList?.map((_, i) => (
                    <li
                      onClick={(e) => getSearchSet1(e)}
                      className={
                        searchSet1Flag === _.name
                          ? "modal-li-clicked"
                          : "modal-li"
                      }
                      key={i}
                      value={_.name}
                    >
                      <div>{_.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-body-list-colum">
                <h5>SearchSet B</h5>
                <ul className="modal-ul">
                  {showList?.map((_, i) => (
                    <li
                      onClick={(e) => getSearchSet2(e)}
                      className={
                        searchSet2Flag === _.name
                          ? "modal-li-clicked"
                          : "modal-li"
                      }
                      key={i}
                      value={_.name}
                    >
                      <div>{_.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <Button buttonName={"Create"} onClick={() => createClashTest()} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isClashIssueOpen}>
        <div className="clash-detection-tab-modal">
          <div className="modal-header">
            <label>Clash Test</label>
            <span className="modal-close" onClick={handleClose}>
              X
            </span>
          </div>
          <div className="modal-body">
            <div className="issue-option">
              <div className="issue-option-item">
                <label>Stamp: - </label>
                <div className={selectedIssue.issueType}>
                  {selectedIssue.issueTypeName}
                </div>
                <div>{selectedIssue.issueName}</div>
                <div className="issue-option-item-colum2">
                  <button
                    className="clashdetection-issue-button stamp-btn-width"
                    onClick={onClickSelectBtn}
                  >
                    select
                  </button>
                  <div
                    className={
                      isStampListOpen
                        ? "stamp-list visible-show"
                        : "visible-hide"
                    }
                  >
                    {issueArray.map((element, i) => {
                      return (
                        <div
                          className="stamp-list-item"
                          onClick={(e) => onClickStampItem(e, element)}
                          key={i}
                        >
                          <div className={element.issueType}>
                            {element.issueTypeName}
                          </div>
                          <div>{element.issueName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="issue-option-item">
                <label>Priority: - </label>
                <div className="issue-option-item-colum2">
                  <button className="clashdetection-issue-button stamp-btn-width">
                    select
                  </button>
                </div>
              </div>
              <div className="issue-option-item">
                <label>Type: - </label>
                <div className="issue-option-item-colum2">
                  <button className="clashdetection-issue-button stamp-btn-width">
                    select
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label>Comment</label>
              <input
                className="issue-comment-input"
                onChange={(e) => onChangeClashIssueCommentValue(e)}
                placeholder="Clash Issue Comment"
              />
            </div>
            {/* <ul className="modal-ul">
                      {showList.map((_, i) => (
                        <li
                          onClick={(e) => getSearchSet1(e)}
                          className={
                            searchSet1Flag === _.searchSetName
                              ? "modal-li-clicked"
                              : "modal-li"
                          }
                          key={i}
                          value={_.searchSetName}
                        >
                          <div>
                            <img src={PNG_BINOCULAR} />
                          </div>
                          <div>{_.searchSetName}</div>
                        </li>
                      ))}
                    </ul> */}
          </div>
          <div>
            <button className="custom-button" onClick={onClickCreateIssueBtn}>
              Create Issue
            </button>
          </div>
        </div>
      </Modal>
      <div className="annotation-option-tab">
        <Tab2
          isOpen={isAnnotationOptionTabOpen}
          onClose={closeAnnotationOptionTab}
          tabName={"AnnotationOption Tab"}
        >
          <button className="custom-button" onClick={() => updateScreenShot()}>
            Update scrrenshot
          </button>
          <button className="custom-button" onClick={() => onClickIssueTrack()}>
            Issue Track
          </button>
        </Tab2>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    setClashDetectionTabStatus:
      state.ClashDetectionReducer.setClashDetectionTabStatus,
    clashDetectionList: state.ClashDetectionReducer.clashDetectionList,
    clashDetectionListFlag: state.ClashDetectionReducer.clashDetectionListFlag,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    searchSaveListFlag: state.SearchSetsReducer.searchSaveListFlag,
    clashDetectionArray1: state.ClashDetectionReducer.clashDetectionArray1,
    clashDetectionArray2: state.ClashDetectionReducer.clashDetectionArray2,
    addToClashDetectionFromView:
      state.xeokitReducer.addToClashDetectionFromView,
    addToClashDetectionNormalClick:
      state.xeokitReducer.addToClashDetectionNormalClick,
    selectContextMenuBtnStatus: state.xeokitReducer.selectContextMenuBtnStatus,
    viewer: state.xeokitReducer.viewer,
    metaData: state.ProjectReducer.metaData,

    IssuesArray: state.IssueTrackReducer.IssuesArray,
    logsArray: state.IssueTrackReducer.logsArray,
    updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setClashDetectionTabStatusAction,
  detectCollisionAction,
  clashDetectionListAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  handleSearchAction,
  setAnnotationOptionTabAction,
  setClashTestTabStatusAction,
  handleLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClashDetectionTab);
