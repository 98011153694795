import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import { userLogin } from "../../action/xeokitAction";

import "./login.css";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const preventRefresh = async (e) => {
    e.preventDefault();
    const url = BaseURL("auth/login");
    const params = {
      email: email,
      password: password,
    };

    if (params["password"]?.length < 8) {
      return toast.error("Password must contain min 8 characters");
    }
    for (let key in params) {
      if (params[key] === "") {
        return toast.error("Please fill all the required fields!");
      }
    }

    setIsLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      console.log("response", response);
      toast.success("Logged In");
    }

    setIsLoading(false);

    props.userLogin({
      user: response?.data?.user,
      token: response?.data?.tokens?.access?.token,
    });
  };
  return (
    <div className="login-wrapper signIn">
      <div className="login-form">
        <div className="login-heading">LOGIN</div>
        <form>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" onClick={preventRefresh}>
            Submit
          </button>
        </form>
        <p>Don't have an account ? Sign In</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    properties: state.PropertyReducer.properties,
    selectedData: state.PropertyReducer.selectedData,
  };
};

const mapDispatchToProps = { userLogin };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
