import { toast } from "react-toastify";
import Info from "../asset/info.svg";
import Affirmative from "../asset/affirmative.svg";
import Negative from "../asset/negative.svg";
import Warning from "../asset/warning.svg";

export const CustomToast = (message, type) => {
  const CloseButton = () => {
    return <div className="sidebar-header-cancel-btn">X</div>;
  };
  switch (type) {
    case "info":
      return toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: { Info },
        style: {
          border: "1px solid #FF00BA",
          borderRadius: "8px",
          width: "267px",
          height: "138px",
          backgroundColor: "#232323",
          color: "#BFBFBF",
        },
      });
    case "affirmative":
      return toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: <CloseButton />,
        pauseOnHover: true,
        draggable: true,
        icon: { Affirmative },
        style: {
          border: "1px solid #FF00BA",
          borderRadius: "8px",
          width: "267px",
          height: "60px",
          backgroundColor: "#232323",
          color: "#BFBFBF",
        },
      });
    case "negative":
      return toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: { Negative },
        style: {
          border: "1px solid #FF00BA",
          borderRadius: "8px",
          width: "267px",
          height: "99px",
          backgroundColor: "#232323",
          color: "#BFBFBF",
        },
      });
    case "warning":
      return toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: <img src={Warning} alt="warning" width={20} height={18} />,
        style: {
          border: "1px solid #FF00BA",
          borderRadius: "8px",
          width: "267px",
          height: "84px",
          backgroundColor: "#232323",
          color: "#BFBFBF",
        },
      });
    default:
      break;
  }
};
