import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, switchStatus, onChange }) => {
  const [isChecked, setIsChecked] = useState(switchStatus);
  const handleToggle = (event) => {
    console.log("checked", event.target.checked);
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };
  return (
    <div className="toggle-container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          id={label}
          checked={switchStatus}
          onChange={handleToggle}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
