// Modal.js
import React, { forwardRef } from "react";

import "./Box.css";

const Box = forwardRef(({ isToggle, style, children }, ref) => {
  return (
    isToggle && (
      <div className="box-container" style={style} ref={ref}>
        {children}
      </div>
    )
  );
});

export default Box;
