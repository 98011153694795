import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "./Toolbar.css";

import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";

import {
  setSectionCutBtnStatusAction,
  setMeasurementBtnStatusAction,
  setResetSectionPlaneBtnStatusAction,
  saveCurrentViewerAction,
  saveViewerAction,
} from "../../action/xeokitAction";
import {
  BCFViewpointsPlugin,
  SectionPlanesPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import IMG_MEASUREMENT from "../../asset/ruler.png";
import IMG_SECTIONCUT from "../../asset/sectioncut.png";
import IMG_RESET from "../../asset/reset.png";
import IMG_SAVE from "../../asset/save.png";

const Toolbar = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [viewerName, setViewerName] = useState("");
  const [showList, setShowList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    console.log("ToolBar");
    const url = BaseURL(
      `viewers/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, []);

  useEffect(() => {
    getAllListing();
  }, [isTabOpen]);

  async function addListing() {
    const viewPoints = props.bcfViewPoint.getViewpoint({ snapshot: false });
    const JsonViewPoints = JSON.stringify(viewPoints);
    const annotations = props.viewer?._plugins
      ?.map((plugin, index) =>
        plugin.id === "Annotations"
          ? Object.entries(plugin.annotations).length !== 0
            ? Object.entries(plugin.annotations).map((annotation, i) =>
                annotation !== undefined
                  ? {
                      id: annotation[1].id,
                      entity: annotation[1].entity.id,
                      occludable: annotation[1]._occludable,
                      markerShown: annotation[1]._markerShown,
                      labelShown: annotation[1]._labelShown,
                      _values: annotation[1]._values,
                      _worldPos: annotation[1]._worldPos,
                      _viewPos: annotation[1]._viewPos,
                      _origin: annotation[1]._origin,
                      _rtcPos: annotation[1]._rtcPos,
                      _canvasPos: annotation[1]._canvasPos,
                    }
                  : null
              )
            : null
          : null
      )
      .filter(Boolean);

    const measurements = props.viewer?._plugins
      ?.map((plugin, index) =>
        plugin.id === "DistanceMeasurements"
          ? Object.entries(plugin._measurements).length !== 0
            ? Object.entries(plugin._measurements).map((measurement, i) => ({
                id: measurement[1].id,
                origin: {
                  entity: measurement[1]._originDot._entity.id,
                  worldPos: measurement[1]._originDot._worldPos,
                },
                target: {
                  entity: measurement[1]._targetDot._entity.id,
                  worldPos: measurement[1]._targetDot._worldPos,
                },
              }))
            : null
          : null
      )
      .filter(Boolean);

    console.log(annotations);
    const temp = annotations.map((annotation) => ({
      id: annotation[0].id,
      entity: annotation[0].entity,
      occludable: annotation[0].occludable,
      markerShown: annotation[0].markerShown,
      labelShown: annotation[0].labelShown,
      values: annotation[0]._values,
      worldPos: annotation[0]._worldPos,
      viewPos: annotation[0]._viewPos,
      origin: annotation[0]._origin,
      rtcPos: annotation[0]._rtcPos,
      canvasPos: annotation[0]._canvasPos,
    }));
    console.log(temp);
    const viewpoint = JSON.stringify({
      viewPoints: JsonViewPoints,
      annotations: temp,
    });

    const blob = new Blob([viewpoint], { type: "text/plain" });

    const fileUploader = new File([blob], viewerName, { type: "text/plain" });

    console.log("fileUploader", fileUploader);

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${viewerName}.json`;
    link.click();
    window.URL.revokeObjectURL(url);

    // const url = BaseURL(`viewers`);
    // const params = {
    //   name: viewerName,
    //   bcf: {
    //     viewPoints: JsonViewPoints,
    //     annotations: annotations,
    //     measurements: measurements,
    //   },
    //   projects: [`${props.projectName}`],
    // };

    // console.log("PARAMS", params);

    // setIsLoading(true);

    // const response = await Post(url, params, apiHeader(props.token));
    // if (response !== undefined) {
    //   console.log("response", response);
    //   toast.success("SearchSets added successfully");
    // }

    // setIsLoading(false);
    // getAllListing();
    // handleClose();
  }

  const handleBtnClick = (btnType) => {
    if (btnType === "MeasureBtn") {
      if (props.measureBtn === null || props.measureBtn === false) {
        props.setMeasurementBtnStatusAction(true);
      } else props.setMeasurementBtnStatusAction(false);
    } else if (btnType === "SectionCutBtn") {
      if (props.sectionCutBtn === null || props.sectionCutBtn === false)
        props.setSectionCutBtnStatusAction(true);
      else props.setSectionCutBtnStatusAction(false);
    } else if (btnType === "ResetBtn") {
      if (
        props.resetSectionPlaneBtn === null ||
        props.resetSectionPlaneBtn === false
      )
        props.setResetSectionPlaneBtnStatusAction(true);
      else props.setResetSectionPlaneBtnStatusAction(false);

      props.viewer.scene.setObjectsColorized(
        props.viewer.scene.colorizedObjectIds,
        false
      );
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
    setIsTabOpen(true);
  };
  const handleClose = () => setIsModalOpen(false);
  const saveViewer = () => {
    // Create a Blob object with the file content and set the MIME type to text/plain
    // const blob = new Blob([jsonValue], { type: "text/plain" });

    // const fileUploader = new File([blob], viewerName, { type: "text/plain" });

    // console.log("fileUploader", fileUploader);

    // // Create a URL for the Blob object
    // const url = window.URL.createObjectURL(blob);

    // // Create an anchor element and trigger the download
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = `${viewerName}.txt`;
    // link.click();
    // window.URL.revokeObjectURL(url);

    handleClose();
  };

  const loadViewer = (e, viewer) => {
    props.annotationPlugin.clear();
    props.measurementPlugin.clear();

    const sectionPlanes = new SectionPlanesPlugin(props.viewer, {
      overviewCanvasID: props.saveSectionPlanceCanvas,
      overviewVisible: true,
    });

    const bcfViewPoints = JSON.parse(viewer.bcf.viewPoints);
    props.bcfViewPoint.setViewpoint(bcfViewPoints);

    console.log("VIeweer", bcfViewPoints);
    console.log("sectionPlanePlugin", props.sectionPlanePlugin);

    viewer?.bcf?.annotations?.map((annotation, i) =>
      props.annotationPlugin.createAnnotation({
        id: annotation[0]?.id,
        entity: props.viewer.scene.objects[annotation[0]?.entity],
        worldPos: [
          annotation[0]?._worldPos[0],
          annotation[0]?._worldPos[1],
          annotation[0]?._worldPos[2],
        ],
        occludable: annotation[0]?.occludable,
        markerShown: annotation[0]?.markerShown,
        labelShown: annotation[0]?.labelShown,

        values: annotation[0]?._values,
      })
    );

    viewer?.bcf?.measurements?.map((measurement, i) =>
      measurement.map((ele, j) =>
        props.measurementPlugin.createMeasurement({
          id: ele?.id,
          origin: {
            entity: props.viewer.scene.objects[ele?.origin.entity],
            worldPos: [
              ele?.origin.worldPos[0],
              ele?.origin.worldPos[1],
              ele?.origin.worldPos[2],
            ],
          },
          target: {
            entity: props.viewer.scene.objects[ele?.target.entity],
            worldPos: [
              ele?.target.worldPos[0],
              ele?.target.worldPos[1],
              ele?.target.worldPos[2],
            ],
          },
        })
      )
    );

    // let sectionPlane = [];
    // props.saveViewerArray.map((element, i) => {
    //   if (element.viewerName === e.target.textContent) {
    //     const bcfViewpoints = new BCFViewpointsPlugin(props.viewer);
    //     bcfViewpoints.setViewpoint(element.viewer);
    //     console.log(props.viewer);
    //     // element.viewer.restoreObjects(props.viewer.scene);
    //     // sectionPlane = element.sectionPlane;
    //   }
    // });

    // console.log("sectionPlanes", sectionPlane);

    // sectionPlane.map((element, i) => {
    //   Object.values(props.sectionPlanePlugin.sectionPlanes).map((item, i) => {
    //     console.log("item", item);
    //     if (element.id === item.id) {
    //       console.log("????", item);
    //       item.dir = element.dir;
    //       item.pos = element.pos;
    //     }
    //   });
    // });
  };

  const closeTab = () => {
    setIsTabOpen(false);
  };
  return (
    <div className="toolbar">
      <div>
        <img
          className={
            props.sectionCutBtn || props.selectEntityBtn
              ? "measurebtn-hide"
              : "measurebtn-show"
          }
          src={IMG_MEASUREMENT}
          alt="Measure"
          onClick={() => handleBtnClick("MeasureBtn")}
        />
      </div>
      <div>
        <img
          className={
            props.measureBtn || props.selectEntityBtn
              ? "sectioncutbtn-hide"
              : "sectioncutbtn-show"
          }
          src={IMG_SECTIONCUT}
          alt="SectionCut"
          onClick={() => handleBtnClick("SectionCutBtn")}
        />
      </div>
      <div>
        <img
          className="resetSection"
          src={IMG_RESET}
          alt="Reset"
          onClick={() => handleBtnClick("ResetBtn")}
        />
      </div>
      <div>
        <img
          className="saveView"
          src={IMG_SAVE}
          alt="Save"
          onClick={openModal}
        />
      </div>
      <div className="save-viewer-tab">
        <Tab isOpen={isTabOpen} onClose={() => closeTab()} tabName="Viewer Tab">
          <ul className="search-sets-tab-ul">
            {showList.map((_, i) => (
              <li
                className="search-sets-tab-li-item"
                key={i}
                onClick={(e) => loadViewer(e, _)}
              >
                <div>{_.name}</div>
              </li>
            ))}
          </ul>
        </Tab>
        <Modal isOpen={isModalOpen}>
          <div>
            <div className="modal-header">
              <label>New Viewer</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <h4>Viewer Name</h4>
              <input
                value={viewerName}
                onChange={(e) => setViewerName(e.target.value)}
              />
              <button className="custom-button" onClick={addListing}>
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionCutBtn: state.xeokitReducer.sectionCutBtn,
    measureBtn: state.xeokitReducer.measureBtn,
    selectEntityBtn: state.xeokitReducer.selectEntityBtn,
    resetSectionPlaneBtn: state.xeokitReducer.resetSectionPlaneBtn,
    viewer: state.xeokitReducer.viewer,
    saveViewerArray: state.xeokitReducer.saveViewerArray,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    bcfViewPoint: state.xeokitReducer.bcfViewPoint,
    annotationPlugin: state.xeokitReducer.annotationPlugin,
    measurementPlugin: state.xeokitReducer.measurementPlugin,
    saveSectionPlanceCanvas: state.xeokitReducer.saveSectionPlanceCanvas,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setSectionCutBtnStatusAction,
  setMeasurementBtnStatusAction,
  setResetSectionPlaneBtnStatusAction,
  saveCurrentViewerAction,
  saveViewerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
