// import React from "react";
// import "./Spinner.css"; // Import the CSS for the animation

// const RotatingLines = ({ size = 100, color = "##ff00ba", duration = 0.5 }) => {
//   return (
//     <svg width={size} height={size} viewBox="0 0 100 100">
//       {[...Array(8)].map((_, i) => (
//         <line
//           key={i}
//           x1="50"
//           y1="20"
//           x2="50"
//           y2="20"
//           stroke={"#ff00ba"}
//           strokeWidth="8"
//           strokeLinecap="round"
//           transform={`rotate(${i * 45} 50 50)`}
//           style={{
//             animation: `rotateLine ${duration}s linear infinite`,
//             animationDelay: `${(i * duration) / 8}s`,
//           }}
//         />
//       ))}
//     </svg>
//   );
// };

// export default RotatingLines;

// import React from "react";
// import "./Spinner.css";

// const RotatingDots = ({
//   width = 50,
//   strokeColor = "blue",
//   strokeWidth = 5,
//   animationDuration = 2,
//   visible = true,
// }) => {
//   if (!visible) return null;

//   return (
//     <div
//       className="rotating-container"
//       style={{ width: `${width}px`, height: `${width}px` }}
//     >
//       {[...Array(8)].map((_, index) => (
//         <div
//           key={index}
//           className="dot"
//           style={{
//             backgroundColor: strokeColor,
//             width: `${strokeWidth}px`,
//             height: `${strokeWidth}px`,
//             animationDuration: `${animationDuration}s`,
//           }}
//         />
//       ))}
//     </div>
//   );
// };

// export default RotatingDots;

import React from "react";
import { connect } from "react-redux";
import "./Spinner.css"; // Import the CSS file for the spinner styles

const MySpinner = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="spinner-container">
        <div className="customSpinnerElement">
          <div className="spinner-fading-circle">
            {[...Array(12)].map((_, i) => (
              <div
                key={i}
                className={`spinner-circle spinner-circle${i + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.SpinnerReducer.isLoading,
  };
};
export default connect(mapStateToProps, null)(MySpinner);
