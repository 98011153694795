import { Constants } from "../constant/constants";

const initialState = {
  isLoading: false,
};
export function SpinnerReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    default:
      return state;
  }
}
